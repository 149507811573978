import {StaticContent} from "../../../../common/components/staticContentPage/StaticContent";

export const PaymentInfo = () => {
    return (
        <StaticContent title="ОПЛАТА ПОСЛУГ">
            <p>Шановні покупці! Звертаємо вашу увагу на те, що ми пропонуємо зручний спосіб оплати для вашого замовлення. Ми гарантуємо безпеку та конфіденційність вашої інформації.</p>

            <p>Процес оплати: Після вибору відповідної послуги та переходу до розділу оплати, оберіть опцію &quot;Оплата карткою Visa/Mastercard&quot;. Введіть дані вашої карти, такі як номер, термін дії та CVV/CVC код, та підтвердьте оплату.</p>

            <p>Підтвердження оплати: Після успішної оплати ви отримаєте підтвердження на ваш електронний адрес.</p>

            <p>Валюта оплати: Зверніть увагу, що всі платежі здійснюються в місцевій валюті.</p>

            <p>Підтримка: Якщо у вас виникли питання або проблеми з оплатою, наша служба підтримки готова допомогти. Зв&#39;яжіться з нами за телефоном або електронною поштою.</p>

            <p>Сподіваємося, що цей простий процес оплати допоможе зробити ваші покупки в нашому магазині приємними та безпечними.</p>

        </StaticContent>
    );
}