import {FC} from "react";
import {useCatalog} from "../../../commands/catalog/useCatalog";
import {createSearchParams, Link} from "react-router-dom";
import {Pages} from "../../../../routing/Pages";
import {STATIC_MENU_ITEMS} from "../../header/headerMenu/MenuBuilder";
import {useCurrentUser} from "../../../commands/user/useCurrentUser";
import {AdTypes} from "../../../staticData/StaticData";
import {MenuItemType} from "antd/lib/menu/hooks/useItems";
import "./styles/footerMenu.scss";

export const FooterMenu: FC = () => {
    const [catalog] = useCatalog();
    const [currentUser] = useCurrentUser();
    let catalogItems: string[] = [];
    if (!currentUser || currentUser?.isClient) {
        catalogItems = [...catalog?.topLevelCategories() ?? [], ...catalogItems];
    }
    return (
        <div className="app-footer-menu">
            {catalogItems.map((item: string) => {
                return (
                    <Link className="app-footer-menu__link"
                          to={`${Pages.SEARCH}?${createSearchParams({type: AdTypes.All, category: item})}`} key={item}>
                        <span className="app-footer-menu__link-text">{item}</span>
                    </Link>
                )
            })}
            {(STATIC_MENU_ITEMS as MenuItemType[]).map((item: MenuItemType) => {
                return (
                    <Link className="app-footer-menu__link" to={`/app/${item.key}`} key={item.key}>
                        <span className="app-footer-menu__link-text">{item.label}</span>
                    </Link>
                )
            })}
        </div>
    )
}
