import {FC, SyntheticEvent, useEffect, useState} from "react";
import FilterUsersCommand from "./commands/FilterUsersCommand";
import {Checkbox, Col, Input, Row, Segmented, Select, Spin} from "antd";
import {StaticDataItem} from "../../../../../common/staticData/StaticData";
import {CurrentUserInfoDecorator, UserRole} from "../../../../../common/commands/user/types/CurrentUserInfoDecorator";
import {ReactComponent as Search} from "./resources/Search.svg";
import {SegmentedValue} from "rc-segmented";
import {debounce, isEmpty} from "lodash-es";
import {UsersList} from "./usersList/UsersList";
import FindUsersCommand from "./commands/FindUsersCommand";
import {UserStatus} from "../../../../../common/commands/user/types/CurrentUserInfo";
import {EmptyText} from "../../../../../common/components/emptyText/EmptyText";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import "./styles/users.scss";
import {EMAIL_REG} from "../../../../../common/utils/RegExpUtils";

enum SearchType {
    ACCOUNT = "ACCOUNT",
    OTHER_ROLES = "OTHER_ROLES",
    BLOCKED = "BLOCKED"
}

enum SortingTypes {
    NAME_ASC = "NAME_ASC",
    NAME_DESC = "NAME_DESC",
    STATUS_ASC = "STATUS_ASC",
    STATUS_DESC = "STATUS_DESC",
    ROLES_ASC = "ROLES_ASC",
    ROLES_DESC = "ROLES_DESC"
}

const searchTypes: StaticDataItem[] = [
    {
        value: SearchType.ACCOUNT,
        label: "Пошук Акаунту"
    },
    {
        value: SearchType.OTHER_ROLES,
        label: "Магазини / Модератори / Адміністратори"
    },
    {
        value: SearchType.BLOCKED,
        label: "Всі Заблоковані"
    },
];

export const userRoles: StaticDataItem[] = [
    {
        value: UserRole.ADMIN,
        label: "Адміністратор"
    },
    {
        value: UserRole.MODERATOR,
        label: "Модератор"
    },
    {
        value: UserRole.SHOP,
        label: "Магазин"
    },
];

const sortingTypes: StaticDataItem[] = [
    {
        value: SortingTypes.NAME_ASC,
        label: "Сортувати за іменами (А - Я)"
    },
    {
        value: SortingTypes.NAME_DESC,
        label: "Сортувати за іменами (Я - А)"
    },
    {
        value: SortingTypes.STATUS_ASC,
        label: "Сортувати за статусом (А - Я)"
    },
    {
        value: SortingTypes.STATUS_DESC,
        label: "Сортувати за статусом (Я - А)"
    },
    {
        value: SortingTypes.ROLES_ASC,
        label: "Сортувати за ролями (А - Я)"
    },
    {
        value: SortingTypes.ROLES_DESC,
        label: "Сортувати за ролями (Я - А)"
    },
];

const PHONE_REG: RegExp = /^\+[0-9]{12}$/;

const initialRoles: UserRole[] = [UserRole.SHOP, UserRole.ADMIN, UserRole.MODERATOR];

const CheckboxGroup = Checkbox.Group;

export const Users: FC = () => {
    const [searchType, setSearchType] = useState<SearchType>(SearchType.OTHER_ROLES);
    const [sort, setSort] = useState<SortingTypes>(SortingTypes.NAME_ASC);
    const [results, setResults] = useState<CurrentUserInfoDecorator[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);
    const [roles, setRoles] = useState<UserRole[]>(initialRoles);

    const reset = () => {
        setSort(SortingTypes.NAME_ASC);
        setResults([]);
        setErrorMessage("");
    };

    const sortedResults = () => {
        return results.sort((userA: CurrentUserInfoDecorator, userB: CurrentUserInfoDecorator) => {
            switch (sort) {
                case SortingTypes.NAME_ASC:
                    return userA.displayName.localeCompare(userB.displayName);
                case SortingTypes.NAME_DESC:
                    return userB.displayName.localeCompare(userA.displayName);
                case SortingTypes.STATUS_ASC:
                    return userA.status.localeCompare(userB.status);
                case SortingTypes.STATUS_DESC:
                    return userB.status.localeCompare(userA.status);
                case SortingTypes.ROLES_ASC:
                    return userA.displayRoles.localeCompare(userB.displayRoles);
                case SortingTypes.ROLES_DESC:
                    return userB.displayRoles.localeCompare(userA.displayRoles);
            }
            return 0;
        });
    };

    const doFilter = async () => {
        reset();
        if (searchType === SearchType.ACCOUNT) return;
        const command: FilterUsersCommand = new FilterUsersCommand();
        if (searchType === SearchType.OTHER_ROLES) {
            command.withRoles(roles);
        } else if (searchType === SearchType.BLOCKED) {
            command.withStatus(UserStatus.DISABLED);
        }

        try {
            setLoading(true);
            await command.execute().then(setResults)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchTypeChange = async (value: SegmentedValue) => {
        setSearchType(value as SearchType);
    };

    const handleSortChange = (value: string | string[]) => {
        setSort(value as SortingTypes);
    };

    const handleRolesChange = (roles: CheckboxValueType[]) => {
        setRoles(roles as UserRole[]);
    };

    const getEmptyErrorMessage = () => {
        if (searchType === SearchType.ACCOUNT) {
            return "Введіть номер телефону або Email для пошуку користувача"
        }

        return "Користувачів не знайдено"
    };

    const debounceSearch = debounce(async (event: SyntheticEvent<HTMLInputElement>) => {
        setLoading(true);
        setErrorMessage("");
        const searchTerm: string = (event.target as HTMLInputElement).value;
        const isValidMail: boolean = EMAIL_REG.test(searchTerm);
        const isPhoneValid: boolean = PHONE_REG.test(searchTerm);
        if (isValidMail || isPhoneValid) {
            await new FindUsersCommand().execute(searchTerm).then(setResults);
        } else {
            setErrorMessage("Введіть коректну електронну адресу або номер телефону");
        }
        setLoading(false);
    }, 700);

    const handleUserUpdate = (user: CurrentUserInfoDecorator) => {
        setResults(results.map((item: CurrentUserInfoDecorator) => {
            if (item.id === user.id) {
                return user
            }
            return item;
        }));
    };

    useEffect(() => {
        doFilter();
    }, [searchType, roles]);

    return (
        <Spin wrapperClassName="users-tab" spinning={isLoading}>
            <Row className="users-tab__types">
                <Col><Segmented options={searchTypes} value={searchType} onChange={handleSearchTypeChange}/></Col>
            </Row>
            <div className="users-tab__search">
                {
                    searchType === SearchType.ACCOUNT && (
                        <Input prefix={<Search/>} placeholder="Пошук по імені або email" onChange={debounceSearch}/>
                    )
                }
                {searchType === SearchType.OTHER_ROLES && (
                    <div className="users-tab__roles">
                        <span>Показати ролі:</span>
                        <CheckboxGroup options={userRoles} onChange={handleRolesChange} value={roles}/>
                    </div>
                )}
                {searchType !== SearchType.ACCOUNT && (
                    <Select className="users-tab__sorting" options={sortingTypes} placeholder="Сортувати за"
                            value={sort} onChange={handleSortChange}/>
                )}
            </div>
            {errorMessage && <EmptyText text={errorMessage}/>}
            {(isEmpty(results) && !errorMessage) ?
                <EmptyText text={getEmptyErrorMessage()}/> :
                <UsersList users={sortedResults()} onUserUpdate={handleUserUpdate}/>}
        </Spin>
    );
};