import {FC, RefObject, useRef, useState} from "react";
import {generatePath, Link, useLocation, useNavigate} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import {AdministrationTabs} from "../administrationPage/AdministrationTabs";
import {Breadcrumb, Card, Col, Form, Input, Row, Spin, Upload} from "antd";
import {BackButton} from "../../../common/components/backButton/BackButton";
import TextArea from "antd/es/input/TextArea";
import {PhoneNumberInput} from "../../../common/components/phoneNumberInput/PhoneNumberInput";
import {UploadedImages} from "../../../common/components/images/UploadedImages";
import {dummyRequest} from "../../../common/utils/UploadUtils";
import {ReactComponent as UploadLogo} from "../../newAdPage/resources/UploadLogo.svg";
import {ACCEPTED_IMAGE_TYPES} from "../../newAdPage/NewAdPage";
import {InternalNamePath, RuleObject, StoreValue, ValidateErrorEntity} from "rc-field-form/lib/interface";
import {isEmpty} from "lodash-es";
import {Button, ButtonType} from "../../../common/components/button/Button";
import {ReactComponent as Cross} from "./resources/Cross.svg";
import {ReactComponent as Tick} from "./resources/Tick.svg";
import {ReactComponent as Preview} from "./resources/Preview.svg";
import {PartnerPage} from "../../../types/integration/Partners";
import {ImageUploadCommand} from "../../newAdPage/commands/ImageUploadCommand";
import {UploadFile} from "antd/lib/upload/interface";
import {useCurrentUser} from "../../../common/commands/user/useCurrentUser";
import CreatePartnerCommand from "./commands/CreatePartnerCommand";
import UpdatePartnerCommand from "./commands/UpdatePartnerCommand";
import {CurrentUserInfoDecorator} from "../../../common/commands/user/types/CurrentUserInfoDecorator";
import "./styles/createPartnersPage.scss";

type FormValues = PartnerPage & { files: UploadFile[] };

export const createPartner = async (
    user: CurrentUserInfoDecorator,
    partner: PartnerPage,
    images: string[] = [],
    files?: UploadFile[]
) => {
    partner.images = [...(images || [])];
    const isExistingPartner: boolean = !!partner.id;
    if (files) {
        const uploadedImagesUrls: string[] = await new ImageUploadCommand(user!).execute(files);
        partner.images.push(...uploadedImagesUrls);
    }
    if (isExistingPartner) {
        await new UpdatePartnerCommand(partner).execute(partner.id)
    } else {
        await new CreatePartnerCommand().execute(partner);
    }
};

export const PREVIEW_PARTNER_ID: string = "PREVIEW_PARTNER";

export const CreatePartnerPage: FC = () => {
    const [user] = useCurrentUser();
    const navigate = useNavigate();
    const {state} = useLocation();
    const [uploadedImages, setUploadedImages] = useState<string[] | undefined>(state?.images);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const isExistingPartner: boolean = !!state?.createdOn;
    const fileUploadElement: RefObject<HTMLDivElement | undefined> = useRef<HTMLDivElement | undefined>();
    const goBack = () => {
        navigate(`${Pages.ADMINISTRATION}?tab=${AdministrationTabs.PARTNERS}`);
    };
    const getFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const filesValidator = (rule: RuleObject, value: StoreValue) => {
        if (isEmpty(value) && isEmpty(uploadedImages)) {
            return Promise.reject("Будь ласка, додайте фото");
        }
        return Promise.resolve(null);
    };
    const handlePreview = () => {
        const currentPartner = state || {};
        form.validateFields().then(() => {
            navigate(
                `${generatePath(Pages.PARTNER_DETAILS, {partnerId: PREVIEW_PARTNER_ID})}`,
                {
                    state: {
                        ...currentPartner || {},
                        ...form.getFieldsValue(),
                        images: uploadedImages
                    }
                }
            );
        });
    };
    const getInitialValues = () => {
        if (state) {
            return state;
        }
        return {};
    };
    const handleSubmit = async (values: FormValues) => {
        const {files, ...rest} = values;
        setLoading(true);
        const currentPartner = state || {};
        try {
            await createPartner(user!, {...rest, id: currentPartner.id}, uploadedImages, files);
            navigate(`${Pages.ADMINISTRATION}?tab=partners`);
        } finally {
            setLoading(false);
        }
    };
    const handleFinishFailed = (errorInfo: ValidateErrorEntity<FormValues>) => {
        const {errorFields} = errorInfo;
        const filedName: InternalNamePath = errorFields[0].name;
        if (filedName[0] === "files") {
            fileUploadElement.current?.scrollIntoView();
        } else {
            form.scrollToField(filedName);
        }
    };

    return (
        <Spin spinning={loading} wrapperClassName="create-partners-page">
            <BackButton onClick={goBack}/>
            <Breadcrumb separator=">">
                <Breadcrumb.Item><Link to={`${Pages.ADMINISTRATION}?tab=partners`}>Партнери</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{isExistingPartner ? "Редагування сторінки партнера" : "Сторінка нового партнера"}</Breadcrumb.Item>
            </Breadcrumb>
            <Form<FormValues>
                name="createPartner"
                layout="vertical"
                scrollToFirstError={true}
                form={form}
                initialValues={getInitialValues()}
                onFinish={handleSubmit}
                onFinishFailed={handleFinishFailed}
            >
                <h2>Додати Партнера</h2>
                <Card title="Інформація про Партнера" bordered={false}>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Назва"
                                name="name"
                                requiredMark="optional"
                                rules={[{required: true, message: "Будь ласка, додайте назву"}]}
                            >
                                <Input placeholder="Додайте назву"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Опис"
                                name="description"
                                requiredMark="optional"
                                rules={[{required: true, message: "Будь ласка, додайте опис"}]}
                            >
                                <TextArea
                                    autoSize={{minRows: 4}}
                                    showCount
                                    maxLength={400}
                                    placeholder="Додайте опис"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title="Фото" bordered={false} className="create-partners-page__photo"
                      ref={fileUploadElement as RefObject<HTMLDivElement>}>
                    {uploadedImages && (
                        <UploadedImages urls={uploadedImages} onChange={setUploadedImages}/>
                    )}
                    <Form.Item name="files" getValueFromEvent={getFile}
                               valuePropName="fileList"
                               rules={[{validator: filesValidator}]}>
                        <Upload listType="picture-card" action="/" customRequest={dummyRequest}
                                beforeUpload={() => false}
                                multiple={true}
                                showUploadList={{showPreviewIcon: false}}
                                accept={ACCEPTED_IMAGE_TYPES}>
                            <div className="new-add-page__upload-logo">
                                <UploadLogo/>
                                <p>Додати Фото</p>
                            </div>
                        </Upload>
                    </Form.Item>

                </Card>
                <Card title="Контакти" bordered={false}>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Веб-сайт"
                                name="webUrl"
                                requiredMark="optional"
                                rules={[{required: true, message: "Будь ласка, додайте Веб-сайт"}]}
                            >
                                <Input placeholder="Додайте Веб-сайт"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Email"
                                name="email"
                                requiredMark="optional"
                                rules={[{required: true, message: "Будь ласка, додайте Email", type: "email"}]}
                            >
                                <Input placeholder="Додайте Email"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="6">
                            <Form.Item
                                label="Телефон"
                                name="phone"
                                validateTrigger={["onSubmit", "onChange"]}
                            >
                                <PhoneNumberInput/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="6">
                            <Form.Item
                                label="Viber"
                                name="viber"
                                requiredMark="optional"
                                validateTrigger={["onSubmit", "onChange"]}
                            >
                                <PhoneNumberInput/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="6">
                            <Form.Item
                                label="Telegram"
                                name="telegram"
                                requiredMark="optional"
                                validateTrigger={["onSubmit", "onChange"]}
                            >
                                <PhoneNumberInput/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="6">
                            <Form.Item
                                label="Facebook"
                                name="facebook"
                                requiredMark="optional"
                            >
                                <Input placeholder="Facebook Link"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="6">
                            <Form.Item
                                label="Instagram"
                                name="instagram"
                                requiredMark="optional"
                            >
                                <Input placeholder="Instagram Link"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">
                            <Form.Item
                                label="Адреса"
                                name="address"
                                requiredMark="optional"
                                rules={[{required: true, message: "Будь ласка, додайте адресу"}]}
                            >
                                <TextArea
                                    autoSize={{minRows: 4}}
                                    maxLength={400}
                                    placeholder="Адреса"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <div className="create-partners-page__buttons">
                    <Button type={ButtonType.DANGER_OUTLINE} icon={<Cross/>} onClick={goBack}>Скасувати</Button>
                    <Button type={ButtonType.OUTLINE} icon={<Preview/>} onClick={handlePreview}>Попередній
                        Перегляд</Button>
                    <Button type={ButtonType.PRIMARY} buttonType="submit" icon={<Tick/>}>
                        {isExistingPartner ? "Зберегти" : "Опублікувати на Сайті"}
                    </Button>
                </div>
            </Form>
        </Spin>
    );
};