import FetchCommand from "../../../common/fetch/FetchCommand";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";
import {api} from "../../../common/fetch/api";
import {Advertisement} from "./types/Advertisement";

export default class GetAddInfoCommand extends FetchCommand<AdvertisementDecorator, string> {
    async execute(adId: string) {
        return api<Advertisement>(`/api/a/${adId}`).then((result: Advertisement) => {
            return new AdvertisementDecorator(result);
        });
    }
}