import {Col, Input, Modal, Row, Segmented, Select, Spin} from "antd";
import {FC, SyntheticEvent, useEffect, useState} from "react";
import {StaticDataItem} from "../../../../../common/staticData/StaticData";
import {SegmentedValue} from "rc-segmented";
import {Button, ButtonType} from "../../../../../common/components/button/Button";
import {ReactComponent as Union} from "./resources/Union.svg";
import {ReactComponent as Search} from "./resources/Search.svg";
import {useNavigate} from "react-router-dom";
import {PartnersList} from "./partnersList/PartnersList";
import "./styles/partners.scss";
import {Pages} from "../../../../../routing/Pages";
import {PartnerPage, PartnerStatus} from "../../../../../types/integration/Partners";
import GetPartnersCommand from "./commands/GetPartnersCommand";
import {isEmpty} from "lodash-es";
import {EmptyText} from "../../../../../common/components/emptyText/EmptyText";
import UpdatePartnerCommand from "../../../createPartnerPage/commands/UpdatePartnerCommand";
import moment from "moment";

const filterOptions: StaticDataItem[] = [
    {
        value: "ALL",
        label: "Всі"
    },
    {
        value: PartnerStatus.ACTIVE,
        label: "Активні"
    },
    {
        value: PartnerStatus.NOT_ACTIVE,
        label: "Неактивні"
    },
];

enum SortingOptions {
    NAME_ASC = "name_asc",
    NAME_DESC = "name_desc",
    CREATED_ASC = "created_asc",
    CREATED_DESC = "created_desc",
}

const sortingOptions: StaticDataItem[] = [
    {
        value: SortingOptions.NAME_ASC,
        label: "Сортувати за іменами (за збільшенням)"
    },
    {
        value: SortingOptions.NAME_DESC,
        label: "Сортувати за іменами (за зменшенням)"
    },
    {
        value: SortingOptions.CREATED_ASC,
        label: "Сортувати за датою створення (за збільшенням)"
    },
    {
        value: SortingOptions.CREATED_DESC,
        label: "Сортувати за датою створення (за зменшенням)"
    },
];

export const Partners: FC = () => {
    const [segmentedValue, setSegmentedValue] = useState<string>(PartnerStatus.ACTIVE);
    const [searchValue, setSearchValue] = useState("");
    const [sortingValue, setSortingValue] = useState(SortingOptions.NAME_ASC);
    const [loading, setLoading] = useState<boolean>(true);
    const [partners, setPartners] = useState<PartnerPage[]>([]);
    const navigate = useNavigate();
    const handleSegmentedChange = (value: SegmentedValue) => {
        setSegmentedValue(value as string);
    };
    const goToCreatePartnersPage = () => {
        navigate(Pages.CREATE_PARTNER);
    };
    const handleSearchValueChange = (event: SyntheticEvent<HTMLInputElement>) => {
        setSearchValue(event.currentTarget.value);
    };
    const handleSortingValueChange = (value: string) => {
        setSortingValue(value as SortingOptions);
    };
    const loadPartners = async () => {
        try {
            const result: PartnerPage[] = await new GetPartnersCommand().execute();
            setPartners(result)
        } finally {
            setLoading(false);
        }
    };
    const deletePartner = (partnerPage: PartnerPage) => {
        partnerPage.status = PartnerStatus.NOT_ACTIVE;
        Modal.confirm({
            title: "Ви дійсно бажаєте видалити сторінку партнера?",
            onOk: async () => {
                await new UpdatePartnerCommand(partnerPage).execute(partnerPage.id);
                await loadPartners();
            }
        });
    };
    const restorePartner = (partnerPage: PartnerPage) => {
        partnerPage.status = PartnerStatus.ACTIVE;
        Modal.confirm({
            title: "Ви дійсно бажаєте повернути сторінку партнера до показу?",
            onOk: async () => {
                await new UpdatePartnerCommand(partnerPage).execute(partnerPage.id);
                await loadPartners();
            }
        });
    };
    const statusFilter = (item: PartnerPage) => {
        if (segmentedValue === "ALL") {
            return true;
        }

        return item.status === segmentedValue
    };
    const nameFilter = (item: PartnerPage) => {
        if (isEmpty(searchValue)) {
            return true;
        }
        return (item.name || "").toLowerCase().includes(searchValue.toLowerCase());
    };
    const getPartners = () => {
        return partners.filter((item: PartnerPage) => {
            return statusFilter(item) && nameFilter(item);
        }).sort((itemA: PartnerPage, itemB: PartnerPage) => {
            if (sortingValue === SortingOptions.NAME_ASC) {
                return itemA.name?.localeCompare(itemB.name ?? "") || 0;
            }
            if (sortingValue === SortingOptions.NAME_DESC) {
                return itemB.name?.localeCompare(itemA.name ?? "") || 0;
            }

            if (sortingValue === SortingOptions.CREATED_ASC) {
                return new Date(itemA.createdOn).getTime() - new Date(itemB.createdOn).getTime();
            }

            if (sortingValue === SortingOptions.CREATED_DESC) {
                return new Date(itemB.createdOn).getTime() - new Date(itemA.createdOn).getTime();
            }

            return 0;
        });
    };
    useEffect(() => {
        loadPartners();
    }, []);
    return (
        <Spin spinning={loading}>
            <div className="partners">
                <Row justify="space-between">
                    <Col span="18">
                        <Segmented options={filterOptions} value={segmentedValue} onChange={handleSegmentedChange}
                                   disabled={isEmpty(partners)}/>
                    </Col>
                    <Col span="5">
                        <Button type={ButtonType.SECONDARY} icon={<Union/>} onClick={goToCreatePartnersPage}>Додати
                            Партнера</Button>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col span="18">
                        <Input value={searchValue} onChange={handleSearchValueChange} placeholder="Пошук"
                               prefix={<Search/>} disabled={isEmpty(partners)}/>
                    </Col>
                    <Col span="5">
                        <Select options={sortingOptions} value={sortingValue} onChange={handleSortingValueChange}
                                disabled={isEmpty(partners)}/>
                    </Col>
                </Row>
                {
                    isEmpty(getPartners())
                        ? (<EmptyText text="Сторінок партнерів не знайдено"/>)
                        : (<PartnersList partners={getPartners()} onDelete={deletePartner} onRestore={restorePartner}/>)
                }
            </div>
        </Spin>
    );
};