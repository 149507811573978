import FetchCommand from "../../../common/fetch/FetchCommand";
import {Shop} from "../../../types/integration/Shop";
import {api} from "../../../common/fetch/api";

export class CreateShopCommand extends FetchCommand<Shop, Shop> {
    async execute(shop: Shop) {
        return api<Shop>(`/api/shops`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(shop)
        });
    }
}