export const Screen = {
    xs: (screen: number) => {
        return screen < 576;
    },
    notXs: (screen: number) => {
        return screen >= 576;
    },
    sm: (screen: number) => {
        return (screen >= 576) && (screen < 768);
    },
    md: (screen: number) => {
        return (screen >= 768) && (screen < 992);
    },
    lg: (screen: number) => {
        return (screen >= 992) && (screen < 1200);
    },
    xl: (screen: number) => {
        return (screen >= 1200) && (screen < 1600);
    },
    xxl: (screen: number) => {
        return screen >= 1600;
    },
    notIn: (screen: number, screens: Array<(screen: number) => boolean>) => {
        return screens.every((checkFunction) => !checkFunction(screen));
    },
    in: (screen: number, screens: Array<(screen: number) => boolean>) => {
        return screens.some((checkFunction) => checkFunction(screen));
    }
}

export const isMobile = (width: number) => {
    return Screen.md(width) || Screen.sm(width) || Screen.xs(width);
};