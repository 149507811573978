import FetchCommand from "../../../../common/fetch/FetchCommand";
import {CurrentUserInfoDecorator, UserRole} from "../../../../common/commands/user/types/CurrentUserInfoDecorator";
import {api} from "../../../../common/fetch/api";
import {CurrentUserInfo} from "../../../../common/commands/user/types/CurrentUserInfo";

export default class SetCustomClaimsCommand extends FetchCommand<CurrentUserInfoDecorator, string> {
    roles: UserRole[];

    constructor(roles: UserRole[]) {
        super();
        this.roles = roles;
    }

    async execute(userId: string) {
        return api<CurrentUserInfo>(`/api/users/${userId}/customClaims`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({roles: this.roles})
            }
        ).then((result: CurrentUserInfo) => new CurrentUserInfoDecorator(undefined, result));
    }
}