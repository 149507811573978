import * as React from "react";
import {FC, useEffect, useState} from "react";
import AdvertisementDecorator from "../../types/decorators/AdvertisementDecorator";
import {ProductTile} from "../../common/components/productTile/ProductTile";
import {Col, Row} from "antd";
import SearchWithPriorityCommand from "../../common/commands/search/SearchWithPriorityCommand";
import {PartnersCarousel} from "../../common/components/partnersCarousel/PartnersCarousel";
import {useScreenWidth} from "../../common/hooks/useScreenWidth";
import {Screen} from "../../common/components/screen/Screen";
import "./style/landing.scss";

export const Landing: FC = () => {
    const [vipAds, setVIPAds] = useState<AdvertisementDecorator[]>([]);
    const width = useScreenWidth();
    const getSpan = () => {
        if (Screen.xs(width) || Screen.sm(width)) {
            return 12;
        }

        if (Screen.lg(width)) {
            return 8
        }

        return 6;
    };

    useEffect(() => {
        const userTags: Record<string, string[]>[] = [
            {shop: [""]},
        ]

        new SearchWithPriorityCommand().execute({tags: userTags, limit: 20})
            .then(({items}) => {
                setVIPAds(items);
            });
    }, []);

    return (
        <div className="landing">
            <Row>
                {vipAds.map((advertisement: AdvertisementDecorator) => (
                    <Col span={getSpan()}>
                        <ProductTile className="landing__product-tile" advertisement={advertisement}
                                     key={advertisement.id}/>
                    </Col>
                ))}
            </Row>
            <PartnersCarousel/>
        </div>
    );
}