import {isEmpty, pickBy} from "lodash-es";
import {createSearchParams} from "react-router-dom";
import {CatalogDecorator} from "../commands/catalog/CatalogDecorator";
import {AdTypes} from "../staticData/StaticData";

export function cleanedObject(value: Record<string, any>): Record<string, any> {
    return pickBy(value, value => value !== undefined && value !== null && !isEmpty(value))
}

export function searchParamsToObject(searchParams: URLSearchParams): Record<string, any> {
    const result: Record<string, any> = {};
    const singleValueFields: string[] = ["priceFrom", "priceTo", "type"];
    searchParams.forEach((value: string, key: string) => {
        if (!result[key]) {
            result[key] = singleValueFields.includes(key) ? value : [value];
        } else {
            const prevValue = Array.isArray(result[key]) ? result[key] : [result[key]];
            result[key] = [...prevValue, value];
        }
    })
    return result;
}

export function searchParamsToPayload(searchParams: URLSearchParams, catalog: CatalogDecorator | null): Record<string, any> {
    const result: Record<string, any> = {};
    const singleValueFields: string[] = ["priceFrom", "priceTo", "type"];
    const valuesToSkip: string[] = ["searchPhrase"];
    searchParams.forEach((value: string, key: string) => {
        if (!valuesToSkip.includes(key)) {
            if (key === "category" && catalog) {
                result["category"] = catalog.getLastCategoryChildren(value);
            } else if (key === "type") {
                if (value === AdTypes.PRIVATE) {
                    result["shop"] = [""];
                } else if (value === AdTypes.SHOP) {
                    result["shop"] = [AdTypes.SHOP];
                }
            } else if (!result[key]) {
                result[key] = singleValueFields.includes(key) ? value : [value];
            } else {
                const prevValue = Array.isArray(result[key]) ? result[key] : [result[key]];
                result[key] = [...prevValue, value];
            }
        }
    })
    if (result.shop === undefined) {
        result.shop = [AdTypes.SHOP, ""];
    }
    return result;
}

export function removeFromSearchParams(searchParams: URLSearchParams, key: string, value: string): URLSearchParams {
    const result: Record<string, any> = {};
    searchParams.forEach((paramsValue: string, paramsKey: string) => {
        if (!(paramsKey === key && paramsValue === value)) {
            if (!result[paramsKey]) {
                result[paramsKey] = paramsValue;
            } else {
                const prevValue = Array.isArray(result[paramsKey]) ? result[paramsKey] : [result[paramsKey]];
                result[paramsKey] = [...prevValue, paramsValue];
            }
        }
    })
    return createSearchParams(result);
}

export type KeyValue = {key: string, value: string};