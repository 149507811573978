import {FC, SyntheticEvent, useState} from "react";
import {ReactComponent as Envelope} from "./resources/Envelope.svg";
import {ReactComponent as Logo} from "./resources/Logo.svg";
import {ReactComponent as ArrowLeft} from "./resources/ArrowLeft.svg";
import {Button, ButtonType} from "../../../common/components/button/Button";
import {Input} from "antd";
import {EMAIL_REG} from "../../../common/utils/RegExpUtils";
import {sendPasswordResetEmail} from "firebase/auth";
import {auth} from "../../../firebase/config";
import {useNavigate} from "react-router-dom";
import {isEmpty} from "lodash-es";
import "./styles/resetPasswordRequest.scss";

interface Props {
    navigateBack: () => void;
    email?: string;
}

export const ResetPasswordRequest: FC<Props> = (props: Props) => {
    const {navigateBack} = props;
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>(props.email ?? "");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isEmailSent, setEmailSent] = useState<boolean>(false);
    const isValidEmail = () => EMAIL_REG.test(email);
    const isValid = () => isValidEmail() && isEmpty(errorMessage);

    const handleEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
        setErrorMessage("");
        setEmail(event.currentTarget.value);
    };

    const handleResetEmail = async () => {
        setErrorMessage("");
        try {
            await sendPasswordResetEmail(auth, email, {url: window.location.href});
            setEmailSent(true);
        } catch (e: any) {
            if (e.code === "auth/user-not-found") {
                setErrorMessage("Обліковий запис із цією електронною адресою не знайдено");
            }
        }
    };

    const goToLandingPage = () => {
        navigate("/");
    };

    return (
        <div className="reset-password-request">
            <div className="reset-password-request__logo">
                <Envelope/>
            </div>
            <div className="reset-password-request__logo">
                <Logo/>
            </div>
            {!isEmailSent ?
                (
                    <>
                        <p>
                            Вкажіть електронну адресу, за якою був зареєстрований акаунт. Туди буде надіслано посилання
                            для скидання пароля.
                            Перейдіть за посиланням у повідомленні та введіть новий пароль.
                        </p>
                        <div className="reset-password-request__email">
                            <div>Email</div>
                            <Input value={email} onChange={handleEmailChange} allowClear
                                   className={!isValid() ? "ant-input-status-error" : ""}/>
                            {!isValidEmail() && <span>Будь ласка, введіть свою електронну адресу!</span>}
                            {!isEmpty(errorMessage) && <span>{errorMessage}</span>}
                        </div>
                        <div className="reset-password-request__controls">
                            <Button type={ButtonType.PRIMARY} disabled={!isValidEmail()} onClick={handleResetEmail}>Скинути
                                пароль</Button>
                            <Button type={ButtonType.LINK} icon={<ArrowLeft/>} onClick={navigateBack}>Повернутись
                                назад</Button>
                        </div>
                    </>
                )
                : (
                    <>
                        <p>
                            На Вашу електронну адресу було надіслано посилання для скидання пароля. Перейдіть за
                            посиланням у
                            повідомленні та введіть новий пароль.
                        </p>
                        <div className="reset-password-request__controls">
                            <Button type={ButtonType.LINK} icon={<ArrowLeft/>} onClick={goToLandingPage}>Повернутись на
                                головну сторінку</Button>
                        </div>
                    </>
                )
            }
        </div>
    );
}