import React from "react";
import {StaticContent} from "../../../../common/components/staticContentPage/StaticContent";

export const CookiesPage = () => {
    return (
        <StaticContent title="ПОЛІТИКА ВИКОРИСТАННЯ COOKIES">
            Політика щодо файлів cookie та подібних технологій<br/><br/>
            1. Що таке cookie?<br/><br/>
            Файли cookie - це невеликі файли, що містять букви та цифри, які можуть бути розташовані на вашому пристрої
            під час відвідування веб-сайту. Ми використовуємо файли cookie для покращення вашого досвіду на нашій
            Платформі, запам&#39;ятовуючи ваші уподобання. Наприклад, на багатьох веб-сайтах електронної торгівлі, якщо
            ви додаєте товар до онлайн-кошику без завершення процедури оформлення замовлення, наступного разу відвідуючи
            той самий веб-сайт, ви все одно можете побачити той самий елемент у кошику. Це приклад того, як файли cookie
            використовуються, щоб запам&#39;ятати ваші уподобання. Без використання файлів cookie ваш досвід роботи на
            нашій Платформі буде менш зручним.<br/><br/>
            Для отримання додаткової інформації про файли cookie див. <a>https://allaboutcookies.org</a><br/><br/>
            2. Чому ми використовуємо cookie?<br/><br/>
            Файли cookie виконують різні функції, зокрема, полегшують навігацію та зберігають ваші уподобання, щоб
            показати відповідний вміст та розширити ваш загальний досвід користувача.<br/><br/>
            Ми використовуємо файли cookie:<br/><br/>
            Щоб керувати вашими уподобаннями та покращити певні функції на нашій Платформі. Може передбачатись збір
            інформації про дату та час вашого візиту, історію веб-перегляду та мовні налаштування.<br/><br/>
            Щоб підтримувати заходи безпеки та виявляти будь-які шахрайські дії.<br/><br/>
            Проаналізувати доступ до нашої Платформи, її використання та продуктивність. Ми використовуємо цю
            інформацію, щоб підтримувати, керувати та постійно вдосконалювати наші послуги.<br/><br/>
            Відправити вам релевантні рекламні повідомлення. Файли cookie допомагають нам керувати рекламними
            повідомленнями, які ви бачите, і оцінювати їх ефективність.<br/><br/>
            3. Які файли cookie та інші технології ми використовуємо?<br/><br/>
            Наша Платформа використовує такі категорії cookie та технологій:<br/><br/>
            Постійні файли cookie - ці файли cookie зберігаються у веб-переглядачі вашого пристрою та використовуються
            під час кожного відвідування нашої платформи. Ці файли cookie допомагають нам запам&#39;ятовувати ваші
            налаштування та уподобання, щоб зробити ваше наступне відвідування зручнішим. Наприклад, завдяки цим файлам
            cookie вам не потрібно повторно входити в систему. Вони також запам&#39;ятають мовні уподобання користувачів
            під час наступного відвідування.<br/><br/>
            Сеансові файли cookie - це тимчасові файли cookie, що залишаються в архіві вашого веб-переглядача, доки ви
            не залишите нашу Платформу. Інформація, яку ми отримуємо, використовуючи ці файли cookie, допомагає нам
            проаналізувати шаблони веб-трафіку, виявити та усунути проблеми, забезпечуючи кращу навігацію.<br/><br/>
            Рекламні файли cookie дозволяють виводити для вас відповідні рекламні оголошення для оцінки ефективності
            рекламної кампанії, аналізуючи вашу взаємодію з оголошеннями рекламодавця. Наприклад, це допомагає нам
            визначити, скільки разів користувачі натискали рекламу та відвідали веб-сайт рекламодавця.<br/><br/>
            Крім того, ми використовуємо веб-маяки, які є невеликими графічними зображеннями (також відомі
            як &quot;піксельні мітки&quot; або &#39;&#39;порожні GIF-файли&quot;) на нашій Платформі. Вони
            застосовуються разом з файлами cookie для ідентифікації наших користувачів та їхньої поведінки.<br/><br/>
            Наша Платформа може містити посилання на сторонні веб-сайти або додатки, у тому числі - наших партнерів.
            Зверніть увагу, що такі сторонні веб-сайти можуть також використовувати файли cookie. Ми не контролюємо ці
            сторонні веб-сайти та не несемо відповідальності за файли cookie, які вони встановлюють або надають доступ
            до них. Якщо ви клікаєте по одному з цих посилань або додатків, зверніть увагу, що кожен з них матиме власну
            політику щодо файлів cookie. Тому, будь ласка, ознайомтеся з політикою щодо файлів cookie інших веб-сайтів
            або додатків перед використанням.<br/><br/>
            4. Чи є на нашій Платформі сторонні файли cookie або плагіни?<br/><br/>
            Треті сторони, такі як рекламні мережі та постачальники зовнішніх служб, наприклад, для аналізу веб-трафіку,
            можуть використовувати файли cookie, веб-маяки, комплекти розробки програмного забезпечення (SDK) або
            подібні технології для збору інформації про відвідування вами нашої Платформи. Ця інформація
            використовується для надання вам більш відповідної реклами на нашій платформі або іншому Інтернет-ресурсі.
            Ми не маємо контролю над використанням сторонніх файлів cookie, які регулюються політикою
            конфіденційності/щодо використання файлів cookie третіх сторін.<br/><br/>
            Нижче наводимо перелік третіх сторін, яким дозволяємо встановлювати файли cookie або інші технології на
            вашому пристрої.<br/><br/>
            Партнери з аналітики[1] : Щоб краще зрозуміти, як ви використовуєте нашу Платформу, ми працюємо з різними
            партнерами з аналітики. Ми дозволяємо партнерам з аналітики, таким як аналітика Google та Facebook,
            використовувати файли cookie, SDK та інші пов&#39;язані технології. Для отримання додаткової інформації про
            те, як Google та Facebook використовують ваші дані,
            відвідайте <a>https://www.google.com/policies/technologies/partner-sites/</a>, <a>https://www.facebook.com/about/privacy/update</a>.<br/><br/>
            Рекламні партнери[2] : Наші рекламні партнери можуть використовувати таргетинг та рекламні файли cookie для
            показу оголошень, які, на їхню думку, є найбільш цікавими для вас, та оцінки ефективності таких оголошень на
            нашій Платформі. Нижче наведено роз&#39;яснення щодо того, як можна керувати персоналізацією та відстеженням
            оголошень. Якщо ви деактивуєте персоналізовані оголошення, на нашій Платформі вам все одно показуватимуться
            загальні оголошення.<br/><br/>
            Плагіни третіх сторін:<br/><br/>
            Наша Платформа також використовує плагіни третіх сторін у формі кнопки &#39;&#39;Поділитись&#39;&#39; . За
            допомогою кнопки &quot;Поділитись&quot; ви можете поділитися нашими рекламними оголошеннями на платформі
            третьої сторони за умови, що ви увійшли у ресурс цієї третьої сторони. Ці треті сторони зможуть
            пов&#39;язати вашу взаємодію з нашою платформою зі вашим обліковим записом на такому ресурсі. Тому, будь
            ласка, ознайомтеся з політикою конфіденційності третіх сторін, перш ніж натиснути на
            кнопку &laquo;Поділитись&raquo;.<br/><br/>
            5. Що ви можете зробити, щоб видалити, вимкнути або заблокувати файли cookie?<br/><br/>
            Ви можете повністю вимкнути використання файлів cookie. Ваш браузер, як правило, повідомляє, як можна
            відмовитись, видалити або заблокувати файли cookie. За наведеними далі посиланнями можна отримати цю
            інформацію щодо деяких веб-переглядачів, які найчастіше використовуються:<br/><br/>
            Google Chrome<br/><br/>
            Internet Explorer<br/><br/>
            Mozilla Firefox<br/><br/>
            Safari<br/><br/>
            Opera<br/><br/>
            Ви можете відмовитися від сторонніх файлів cookie від Google Analytics у рекламних налаштуваннях або за
            допомогою <a>https://adssettings.google.com</a><br/><br/>
            Ви можете відмовитись від таргетингових файлів cookie на основі інтересів, що налаштовують рекламні сервери,
            на наступних веб-сайтах, якими керує Digital Advertising Alliance:<br/><br/>
            <a>http://youradchoices.com</a><br/><br/>
            <a>https://www.youronlinechoices.com/</a><br/><br/>
            <a>http://optout.aboutads.info</a><br/><br/>
            Крім того, ви можете змінити налаштування вашого мобільного пристрою та контролювати виведення реклами на
            основі інтересів.<br/><br/>
            ПРИМІТКА: Якщо ви вирішите заблокувати cookie, які ми встановили на нашій Платформі, то, можливо, не
            матимете повного або часткового доступу до наших послуг, наприклад, ви не зможете зберігати власні
            налаштування, такі як інформація для входу.<br/><br/>
        </StaticContent>
    );
}