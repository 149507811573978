import React from "react";
import {StaticContent} from "../../../../common/components/staticContentPage/StaticContent";

export const PrivacyPage = () => {
    return (
        <StaticContent title="ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ">
            <p>1. ОБЛАСТЬ ЗАСТОСУВАННЯ ТА ЗГОДА<br/><br/>
                1.1. Персональні дані фізичних осіб - користувачів сервісу baron.ua обробляються ТОВ &laquo;БАРОН
                2005&raquo;, юридичною особою, зареєстрованою за законодавством України, з місцезнаходженням за
                адресою: просп. Червоної Калини, 66а, к. 168, м. Київ, 02064 (далі - Компанія). Компанія є власником
                Бази персональних даних користувачів baron.ua.<br/><br/>
                1.2. Розпорядником Бази персональних даних користувачів baron.ua є компанія ТОВ &laquo;БАРОН
                2005&raquo;, яка створена і діє за законодавством України і знаходиться за адресою: просп. Червоної
                Калини, 66а, к. 168, м. Київ, 02064.<br/><br/>
                1.3. Персональні дані користувачів baron.ua обробляються на захищених серверах, розташованих як у
                межах, так і поза межами Європейського економічного простору.<br/><br/>
                1.4. Ця Політика конфіденційності описує встановлений Компанією порядок обробки персональних даних,
                зібраних за допомогою Сайту www.baron.ua (далі - Сайт) і пов&#39;язаних з ним послуг і інструментів,
                що дозволяють користувачам реєструватися на Сайті, публікувати або переглядати в режимі реального
                часу вже опубліковані оголошення, використовувати інший, пов&#39;язаний з вищенаведеним сервіс
                baron.ua. У всіх зазначених випадках Компанія обробляє персональні дані користувачів виключно в
                рамках вимог Закону України &laquo;Про захист персональних даних&raquo; та Конвенції про захист осіб
                у зв&#39;язку з автоматизованою обробкою персональних даних. Ця Політика конфіденційності розроблена
                відповідно до положень цих документів.<br/><br/>
                1.5. Використовуючи Сайт та/або інші пов&#39;язані сервіси та інструменти baron.ua, Користувач надає
                свою згоду Компанії на обробку своїх персональних даних, таких як ім&#39;я користувача; регіон
                проживання, адреса електронної пошти, контактний телефон, інша контактна інформація за бажанням
                користувача; ip-адреси, інші комунікаційні дані користувачів; повідомлення, листи, заяви, що
                передаються користувачу іншими користувачами і навпаки, а також дає свою згоду на передачу своїх
                персональних даних третім особам, в тому числі на передачу персональних даних за кордон, в будь-яку
                третю країну, в відповідності з цією Політикою конфіденційності та Угодою з користувача сервісів
                baron.ua.<br/><br/>
                1.6. Користувачі повинні знати, що при переході на деякі посилання, розміщені на Сайті, вони можуть
                бути перенаправленні до сайтів (з додатками і т.д.) інших компаній за межі хостингового простору
                Компанії, де інформація про Користувачів збирається поза прямим контролем Компанії. В такому випадку
                Політики конфіденційності сайтів та/або додатків третіх осіб регулюватимуть порядок обробки
                інформації, отриманої від користувачів цими третіми особами.<br/><br/>
                2. ІНФОРМАЦІЯ, ЯКУ ЗБИРАЄ, ОТРИМУЄ І РОЗМІЩУЄ КОМПАНІЯ<br/><br/>
                2.1. Інформація про реєстрацію: При створенні Користувачем облікового запису на Сайті, Компанія може
                вимагати певну інформацію, таку як дійсну адресу електронної пошти та пароль. Обліковий запис
                включає в себе таку інформацію про них, як географічне розташування, ім&#39;я та прізвище, номер
                телефону і супутню інформацію, в тому числі фотографії, які вони можуть завантажувати у свій
                обліковий запис. Обліковий запис дозволяє користувачам зв&#39;язуватися один з одним, щоб висловити
                зацікавленість у своїх пропозиціях. Користувачі несуть відповідальність за всю інформацію, що
                розміщується ними в загальнодоступних облікових записах. Користувач повинен усвідомити всі ризики,
                пов&#39;язані з тим, що він оприлюднює адресу або інформацію про точне місце свого розташування.
                Якщо користувач вирішив увійти на Сайт, використовуючи службу автентифікації стороннього оператора,
                наприклад інформацію Google, Компанія може отримати додатковий профіль, або іншу інформацію, доступ
                до якої надано такою третьою особою.<br/><br/>
                2.2. Оголошення та Угоди: В рамках діяльності свого Сайту, Компанія може розміщувати інформацію, в
                тому числі особистого і контактного характеру, необхідну для здійснення угод між покупцем і
                продавцем, для надсилання повідомлень і спілкування користувачів між собою, та здійснення платежів.
                Вся інформація, необхідна для публікації оголошень, потрібна для створення облікового запису.
                Користувачі несуть відповідальність за всю інформацію, що розміщується ними на Сайті. Користувач
                повинен уважно усвідомити всі ризики, пов&#39;язані з тим, що він оприлюднює адресу, або інформацію
                особистого характеру.<br/><br/>
                2.3. Ігри, Реклама та Просування: В процесі роботи Сайту Компанія може збирати особисту інформацію,
                таку як ім&#39;я та контактна інформація, участь користувачів у іграх, вікторинах та інших
                маркетингових акціях, організованих на Сайті, або на сторонніх сайтах Компанією. В процесі роботи
                Сайту Компанія може також обробляти інформацію пов&#39;язану з ефективністю рекламних кампаній, в
                тому числі перегляди оголошень на Сайті, та на сайтах третіх осіб.<br/><br/>
                2.4. Обслуговування клієнтів: При зверненні користувачів до відділу обслуговування клієнтів,
                Компанія, при необхідності, може в процесі роботи свого Сайту збирати особисту інформацію, необхідну
                для виконання запиту Користувача і отримання зворотного зв&#39;язку. Компанія може також
                зв&#39;язатися з Користувачем, використовуючи існуючу контактну інформацію облікового запису, надану
                для цієї мети. Компанія може також збирати іншу інформацію про спілкування з Користувачами,
                наприклад, будь-які запити до служби підтримки, що подаються Користувачами, або будь-який зворотний
                зв&#39;язок, що надається ними, зокрема, у формі відгуків, залишених Користувачами. Ми можемо
                обробляємо відгуки в якості даних, що стосуються Користувача &ndash; автора відгуку, та Користувача,
                відносно якого було залишено відгук.<br/><br/>
                2.5. Веб-сайт та мобільні дані: Компанія може автоматично отримувати і реєструвати на своїх серверах
                інформацію з браузера користувача або будь-якого пристрою, включаючи IP-адресу, геолокацію
                Користувача програмне забезпечення і апаратні атрибути, сторінки, які запитує Користувач, дані, які
                містяться в базах даних браузера, включаючи SQL бази даних, мобільні ідентифікатори (включаючи
                ідентифікатори мобільних пристроїв, такі як Google Advertisement ID, IFA or IFV), інформацію про
                використання додатків, та/або інформацію про інші використовувані пристрої, або інформацію
                системного рівня. Це може відбуватися на Сайті або на сервісах третіх осіб. Додаткова інформація про
                те, як користувачі можуть контролювати та/або блокувати такий збір інформації знаходиться нижче в
                розділі 6 цієї політики.<br/><br/>
                2.6. Інформація, отримана в результаті опитувань: Компанія може збирати та зберігати інформацію, що
                отримана в результаті опитувань, що можуть проводитись Компанією, або залученими Компанією
                підрядниками, - третіми особами, а саме інформацію щодо статі, віку, сімейного стану, особистих
                вподобань та ін.<br/><br/>
                2.7. Інформація, що додається: Компанія може також додати інформацію, отриману на законних підставах
                від ділових партнерів або третіх сторін, до існуючих даних Компанії про своїх користувачів.<br/><br/>
                2.8. Інформація, що доступна з профайлів Користувача у багатокористувацьких Інтернет-платформах
                (соціальних мережах): Реєструючись на Сайті, або здійснюючи вхід на Сайт за допомогою служб
                автентифікації соціальних мереж, Користувач надає Компанії згоду на збір та обробку інформації, що
                доступна із відповідних профайлів в соціальних мережах, а також на публікацію у відповідних
                соціальних мережах відомостей про дії Користувача на Сайті.<br/><br/>
                2.9. Ми збираємо інформацію про рейтинги та відгуки, які ви надаєте іншим користувачам після вашої
                взаємодії з ними, а також про рейтинги та відгуки, які ви отримуєте від інших користувачів,
                відповідно до наших Умов використання. Ми робимо це для того, щоб виміряти задоволеність
                користувачів щодо досвіду транзакцій, забезпечення оптимального та ефективного рівня спілкування між
                користувачами, а також для перевірки та запобігання небажаній активності та поведінці в контексті
                діяльності, що здійснюється на наших Сервісах. Ця інформація дозволяє нам вживати необхідних та
                відповідних заходів для підтримання високих стандартів при наданні послуг.<br/><br/>
                Інформація, яку Компанія не збирає і не обробляє:<br/><br/>
                Компанія не збирає і не обробляє персональні дані про расове або етнічне походження, політичні,
                релігійні або світоглядні переконання, членство в політичних партіях та професійних спілках та
                подібну інформацію.<br/><br/>
                3. IP-АДРЕСА, КУКІС (COOKIES) ТА МОБІЛЬНІ ІДЕНТИФІКАТОРИ<br/><br/>
                3.1. В процесі роботи Сайту Компанія може збирати певну інформацію за допомогою таких технологій як
                кукіс (куки, cookies), пікселі (pixels) та локального сховища (як у Вашому браузері або мобільному
                пристрої).<br/><br/>
                3.2. КУКІС (COOKIES) - це невеликі текстові файли, які зберігають інформацію безпосередньо на
                комп&#39;ютері користувача, його мобільному телефоні або іншому пристрої.<br/><br/>
                3.3. Пікселі (pixels) - невеликі цифрові зображення, які є частиною коду на веб-сторінках, які
                дозволяють іншому серверу вимірювати переглядання веб-сторінки, і часто використовуються в поєднанні
                з кукіс (cookies). Код відстежує як, коли (і на якій сторінці) піксель завантажений, щоб вказати, що
                користувач взаємодіє зі сторінкою або частиною сторінки Сайту.<br/><br/>
                3.4. За допомогою кукіс (cookies), веб-сервер може зберегти, наприклад вподобання та налаштування на
                комп&#39;ютері Користувача, його мобільному телефоні або іншому пристрої(ях), яке потім автоматично
                відновлюється при наступному відвідуванні. Інакше кажучи, файли кукіс (cookies) призначені для того,
                щоб зробити використання Сайту більш зручним, наприклад, щоб Користувач не повторював процес входу в
                систему під час наступного візиту. Компанія використовує як постійні, так і сесійні куки. Постійні
                куки залишаються на комп&#39;ютері Користувача протягом більш тривалого періоду часу, а сесійні куки
                автоматично видаляться при закритті вікна браузера.<br/><br/>
                3.5. Компанія може дозволяти третім сторонам, таким як постачальники рекламних та/або аналітичних
                послуг, збирати інформацію, використовуючи ці типи технологій безпосередньо на веб-сторінці Сайту.
                Дані, які вони збирають підлягають захисту відповідно до чинної політики конфіденційності цих третіх
                сторін.<br/><br/>
                4. ВИКОРИСТАННЯ ПЕРСОНАЛЬНИХ ДАНИХ<br/><br/>
                4.1. Для надання своїх послуг Компанія може використовувати інформацію, яку Компанія збирає і
                розміщує для наступних цілей:<br/><br/>
                4.1.1. забезпечення обслуговування клієнтів, в тому числі для створення і управління обліковими
                записами користувачів, вирішення технічних труднощів і доступу до різних функцій;<br/><br/>
                4.1.2. адаптація пропозицій і досвіду, в тому числі реклами на своїх сервісах або сервісах третіх
                осіб;<br/><br/>
                4.1.3. контроль загальної та індивідуальної активності користувачів, такий як пошук за ключовими
                словами, активності за публікаціями оголошень та укладання угод, а також для управління трафіком на
                Сайті;<br/><br/>
                4.1.4. зв&#39;язок з користувачами, в тому числі з питань сервісу, обслуговування клієнтів або
                дозволених маркетингових комунікацій через будь-які доступні канали зв&#39;язку;<br/><br/>
                4.1.5. проведення науково-дослідницької та аналітичної активності з метою поліпшення нашого сервісу;
                та<br/><br/>
                4.1.6. забезпечення дотримання Угоди з користувачами сервісів baron.ua, враховуючи боротьбу з
                шахрайством і образами;<br/><br/>
                4.1.7. оцінка деяких факторів особистої інформації, зокрема, для аналізу та прогнозування особистих
                вподобань, інтересів, поведінки та місцезнаходження.<br/><br/>
                4.2. Компанія буде зберігати Ваші персональні дані настільки довго, наскільки це необхідно для
                досягнення цілей, заради яких вони були зібрані, в тому числі для виконання будь-яких юридичних,
                бухгалтерських вимог, або вимог щодо звітності.<br/><br/>
                Для визначення відповідного періоду зберігання Компанія враховує обсяг, характер і чутливість
                персональних даних, потенційний ризик заподіяння шкоди від несанкціонованого використання або
                розголошення Ваших персональних даних, мету, заради яких Компанія обробляє Ваші персональні дані, а
                також чи може Компанія досягти цих цілей за допомогою інших засобів, і відповідні юридичні
                вимоги.<br/><br/>
                Якщо у вашому обліковому записі не було активності більше 24 місяців, ми залишаємо за собою право
                видалити ваш обліковий запис, включаючи всі особисті дані, що зберігаються в обліковому записі, що
                означає, що ви більше не зможете отримати до нього доступ і використовувати його.<br/><br/>
                У разі виникнення питань щодо періоду зберігання даних, будь ласка, зв&#39;яжіться з нами через
                функцію зворотнього звязку.<br/><br/>
                5. ОБМІН ІНФОРМАЦІЄЮ<br/><br/>
                5.1. Компанія може ділитися інформацією, яку вона збирає, з афілійованими особами (компанії, що
                діють на основі спільної власності), розташованої в будь-якій третій країні. Ці компанії можуть
                тільки обробляти і використовувати отримані персональні дані в цілях, зазначених у п.4 цієї Політики
                Конфіденційності. При цьому передані дані залишаються предметом цієї Політики
                Конфіденційності.<br/><br/>
                5.2. Компанія не надає особисту інформацію користувачів не афілійованим особам, за винятком
                випадків, коли на те є відповідний дозвіл користувачів, або за наступних обставин:<br/><br/>
                5.3. Компанія може використовувати сторонніх постачальників послуг для надання деяких складових
                своїх послуг, в таких випадках постачальники не мають повноважень використовувати отримані за
                допомогою Сайту Компанії персональні дані, інакше ніж для надання послуг Компанії, а самі
                персональні дані є предметом цієї Політики Конфіденційності.<br/><br/>
                5.4. Компанія залишає за собою право відповідно до вимог законодавства обмінюватися інформацією з
                приватними особами та державними органами в наступних цілях:<br/><br/>
                боротьба з шахрайством та зловживаннями на Сайті;<br/><br/>
                розслідування передбачуваних порушень закону або боротьби з будь-якими іншими передбачуваними
                порушеннями Угоди з користувачами сервісів baron.ua Користувачами.<br/><br/>
                5.5. Компанія може надавати персональні дані користувачів на запити компетентних органів, оформлених
                відповідно до вимог чинного законодавства, в тому числі відповідно до ст. 93 Кримінального
                процесуального кодексу України.<br/><br/>
                5.6. Відповідно до Політики конфіденційності, Компанія зобов&#39;язується не передавати в оренду або
                продавати будь-які персональні дані Користувача. У разі якщо бізнес Компанії або частина цього
                бізнесу будуть продані або реорганізовані, та Компанія передає всі або практично всі свої активи
                новому власнику, то персональні дані користувачів можуть бути передані покупцеві, щоб забезпечити
                безперервність обслуговування Сайту.<br/><br/>
                5.7. Компанія може передавати певну знеособлену інформацію (дані, які не дозволяють ідентифікувати
                користувачів окремо) стороннім постачальникам послуг, довіреним партнерам або авторизованим
                дослідникам з метою кращого розуміння, яка реклама або послуги можуть зацікавити користувачів,
                поліпшення загальної якості та ефективності послуг на Сайті або сервісів, або для забезпечення свого
                внеску в наукові дослідження, які, на думку Компанії, можуть приносити велику соціальну
                користь.<br/><br/>
                5.8. Компанія може передавати інформацію, яку вона збирає третім особам, які надають послуги
                Компанії, з метою проведення досліджень, або надання сервісів Користувачам, при цьому дані, які
                передаються є предметом цієї Політики Конфіденційності, а залучені треті особи не мають право
                використовувати отриману інформацію інакше як для надання послуг Компанії.<br/><br/>
                5.9. У випадках передачі персональних даних, передбачених цим розділом 5 Політики конфіденційності,
                інформування Користувача про передачу його персональних даних залишається на розсуд Компанії.<br/><br/>
                6. КОРИСТУВАЦЬКИЙ КОНТРОЛЬ<br/><br/>
                6.1. Доступ, Виправлення і Вилучення: Користувачі, які створили обліковий запис, або розмістили
                оголошення на Сайті, можуть отримувати доступ, виправляти або видаляти інформацію, яку вони надають.
                Користувач несе відповідальність за точність наданих даних, або повідомлень на Сайті. Якщо обліковий
                запис Користувача був створений через провайдера ідентифікації (наприклад, Google), Користувач може
                також відключити, або змінити дані облікового запису через налаштування ідентифікації провайдера.
                Розміщена інформація може бути змінена або видалена в особистому кабінеті користувача &quot;Мій
                профіль&quot; на веб-сторінці Сайту. Компанія може припинити обробку персональних даних Користувача
                в разі отримання письмового повідомлення Користувача про відкликання згоди на обробку персональних
                даних.<br/><br/>
                Щоб видалити Ваші персональні дані, будь ласка, направте відповідний запит із повідомленням про
                відкликання згоди на обробку персональних даних адміністрації сервісу через чат-функцію на Сервісі,
                або направте запит на ім&rsquo;я ТОВ &laquo;БАРОН 2005&raquo; за адресою: просп. Червоної Калини,
                66а, к. 168, м. Київ, 02064. Ми видалимо Ваші дані не пізніше ніж за 10 (десять) календарних днів з
                дати отримання від Вас всієї необхідної інформації.<br/><br/>
                6.2. Вибір третіх осіб: деякі зовнішні суб&#39;єкти, що діють на сайті, наприклад, Google Adwords
                дозволяють користувачам відкликати свою згоду на збір і використання своїх даних для реклами на
                основі активності користувачів. Для отримання більш детальної інформації та можливості вибору, будь
                ласка, відвідайте http://www.networkadvertising.org.<br/><br/>
                6.3. Кукіс (Cookies): Більшість основних комп&#39;ютерних (десктопних) і мобільних веб-браузерів
                (наприклад, Safari, Firefox, Internet Explorer, Chrome, Opera) забезпечують елементи управління, які
                дозволяють користувачеві обмежити або заблокувати встановлення Кукіс (Cookies) на ваших системах.
                Зверніть увагу, що відключення Кукіс (Cookies) щодо доменів першої категорії (відвідуваних сайтів)
                та інших доменів (сайтів, пов&#39;язаних з відвідуваними) може привести в деяких випадках до
                обмеженої функціональності цих веб-сайтів.<br/><br/>
                6.4. Інші права Користувачів у зв&#39;язку з обробкою їх персональних даних Компанією:<br/><br/>
                6.4.1. знати про місцезнаходження бази персональних даних, яка містить персональні дані користувачів
                baron.ua, її призначення та найменування, місцезнаходження власника і розпорядників персональних
                даних, або дати відповідне доручення щодо отримання цієї інформації уповноваженими користувачами
                Сайту крім випадків, встановлених законом;<br/><br/>
                6.4.2. отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про
                третіх осіб, яким передаються персональні дані користувачів Сайту;<br/><br/>
                6.4.3. доступ до своїх персональних даних;<br/><br/>
                6.4.4. отримувати не пізніше як за тридцять календарних днів з дня надходження запиту, крім
                випадків, передбачених законом, відповідь про те, обробляються чи зберігаються його персональні
                дані, а також отримувати зміст таких персональних даних;<br/><br/>
                6.4.5. пред&#39;являти вмотивовану вимогу власнику персональних даних із запереченням проти обробки
                персональних даних;<br/><br/>
                6.4.6. пред&#39;являти вмотивовану вимогу щодо зміни або знищення персональних даних власником
                та/або розпорядником персональних даних, якщо ці дані обробляються незаконно чи є
                недостовірними;<br/><br/>
                6.4.7. на захист персональних даних від незаконної обробки та випадкової втрати, знищення,
                пошкодження у зв&#39;язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також
                на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову
                репутацію;<br/><br/>
                6.4.8. звертатися зі скаргами на обробку персональних даних до органів державної влади та посадових
                осіб, до повноважень яких належить забезпечення захисту персональних даних, або до суду;<br/><br/>
                6.4.9. застосовувати засоби правового захисту в разі порушення законодавства про захист персональних
                даних;<br/><br/>
                6.4.10. відкликати згоду на обробку персональних даних;<br/><br/>
                6.4.11. знати механізм автоматичної обробки персональних даних;<br/><br/>
                6.4.12. на захист від автоматизованого рішення, що має для користувачів Сайту правові
                наслідки.<br/><br/>
                7. БЕЗПЕКА<br/><br/>
                7.1. Вся інформація, яку ми збираємо в розумних межах захищена технічними засобами і процедурами
                забезпечення безпеки з метою запобігання несанкціонованому доступу або використання даних.
                Афілійовані з Компанією особи, надійні партнери і незалежні постачальники послуг зобов&#39;язуються
                використовувати отриману від Компанії інформацію відповідно до наших вимог до безпеки і цієї
                Політикою Конфіденційності.<br/><br/>
                8. ВНЕСЕННЯ ЗМІН ДО ЦІЄЇ ПОЛІТИКИ<br/><br/>
                8.1. Компанія може час від часу оновлювати цю політику конфіденційності, нова редакція Політики
                конфіденційності вступає в силу з моменту її розміщення в мережі Інтернет за вказаною в цьому пункті
                адресою, якщо інше не передбачено новою редакцією Політики конфіденційності.<br/><br/>
                8.2. У разі якщо Компанією були внесені будь-які зміни в Політику Конфіденційності, з якими
                Користувач не згоден, він зобов&#39;язаний припинити використання сервісів Сайту. Факт продовження
                використання Сайту є підтвердженням згоди і прийняття Користувачем відповідної редакції Політики
                Конфіденційності.</p>
        </StaticContent>
    )
}