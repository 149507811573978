import {Dispatch, useEffect, useState} from "react";

type CallBack = (value: string | null) => void;
const SESSION_STORAGE_CALLBACKS: Set<CallBack> = new Set();

export const useSessionStorage = (key: string): [string | null, Dispatch<string | null>] => {
    const [value, setValue] = useState<string | null>(sessionStorage.getItem(key));

    useEffect(() => {
        SESSION_STORAGE_CALLBACKS.add(setValue);
        return () => {
            SESSION_STORAGE_CALLBACKS.delete(setValue);
        }
    }, []);

    useEffect(() => {
        setToStorage(sessionStorage.getItem(key))
    }, []);

    const setToStorage = (value: string | null) => {
        if (value) {
            sessionStorage.setItem(key, value);
        } else {
            sessionStorage.removeItem(key);
        }
        SESSION_STORAGE_CALLBACKS.forEach((callback: CallBack) => {
            callback(value);
        })
    }

    return [value, setToStorage];
}