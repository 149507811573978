import {FC, useEffect, useState} from "react";
import {isEmpty} from "lodash-es";
import GetAdPublicInfoCommand from "../../../../common/commands/ad/GetAdPublicInfoCommand";
import AdvertisementDecorator from "../../../../types/decorators/AdvertisementDecorator";
import {MyAdCard} from "../myAdCard/MyAdCard";
import {Spin} from "antd";
import {useCurrentUser} from "../../../../common/commands/user/useCurrentUser";
import "./styles/favourites.scss";
import {generatePath, useNavigate} from "react-router-dom";
import {Pages} from "../../../../routing/Pages";
import {EmptyText} from "../../../../common/components/emptyText/EmptyText";

export const Favourites: FC = () => {
    const navigate = useNavigate();
    const [user] = useCurrentUser();
    const [advertisements, setAdvertisements] = useState<AdvertisementDecorator[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!isEmpty(user?.favouriteAds)) {
            new GetAdPublicInfoCommand().execute(user?.favouriteAds ?? [])
                .then(setAdvertisements)
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setAdvertisements([]);
            setLoading(false);
        }
    }, [user?.favouriteAds]);

    return (
        <Spin spinning={isLoading}>
            <div className="favourites">
                {isEmpty(advertisements)
                    ? (<EmptyText text={`Ще не додано жодного оголошення до "Улюблених"`}/>)
                    : (
                        <ul className="favourites__list">
                            {advertisements.map((item: AdvertisementDecorator) => {
                                const handleClick = () => {
                                    const adDetailsPath: string = generatePath(Pages.AD_PUBLIC_DETAILS, {adId: item.id});
                                    navigate(adDetailsPath);
                                };
                                return (
                                    <li className="favourites__list-item" key={item.id}>
                                        <MyAdCard advertisement={item} isFavourite onClick={handleClick}/>
                                    </li>
                                )
                            })}
                        </ul>
                    )
                }
            </div>
        </Spin>
    );
};
