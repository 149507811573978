import {BackButton} from "../backButton/BackButton";
import React, {ReactNode} from "react";
import "./styles/staticContent.scss";

interface Props {
    title: string;
    children: ReactNode;
}

export const StaticContent = (props: Props) => {
    const {title, children} = props;
    return (
        <div className="staticContent">
            <BackButton className="staticContent__back"/>
            <span className="staticContent__title">{title}</span>
            <div className="staticContent__details">
                {children}
            </div>
        </div>
    );
}