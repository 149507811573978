import {FC, ReactNode, SyntheticEvent, useState} from "react";
import {Button, ButtonType} from "../button/Button";
import {CurrentUserInfoDecorator} from "../../commands/user/types/CurrentUserInfoDecorator";
import {UserStatus} from "../../commands/user/types/CurrentUserInfo";
import {ReactComponent as Unblock} from "../../../content/administration/administrationPage/tabs/users/usersList/resources/Unblock.svg";
import {isEmpty} from "lodash-es";
import {ReactComponent as Block} from "./resources/Block.svg";
import {Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import SetUserStatusCommand from "./commands/SetUserStatusCommand";
import "./styles/setStateButton.scss";

interface Props {
    children?: ReactNode;
    user: CurrentUserInfoDecorator;
    onChanged: (user: CurrentUserInfoDecorator) => void;
}

enum ModalType {
    BLOCK = "BLOCK", UNBLOCK = "UNBLOCK"
}

export const SetStatusButton: FC<Props> = (props: Props) => {
    const {user, onChanged} = props;
    const [modal, setModal] = useState<ModalType | null>(null);
    const [reason, setReason] = useState<string>("");

    const updateUser = async (status?: UserStatus) => {
        if (!user) return;
        try {
            const updatedUser: CurrentUserInfoDecorator = await new SetUserStatusCommand(status).execute(user?.id ?? "");
            setModal(null);
            onChanged(updatedUser);
        } catch (e) {
            console.log(e);
        }
    };

    const blockUser = async () => {
        await updateUser(UserStatus.DISABLED);
    };

    const unblockUser = async () => {
        await updateUser();
    };

    const closeModal = () => {
        setModal(null);
    };

    const handleReasonChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        setReason(event.currentTarget.value);
    };

    const modalTitle = modal === ModalType.UNBLOCK ? "Розблокувати Користувача" : "Заблокувати Користувача";
    const modalFooter = (
        <div className="set-state-button__modal-footer">
            <Button type={ButtonType.OUTLINE} onClick={() => setModal(null)}>Назад</Button>
            {modal === ModalType.UNBLOCK
                ? (<Button
                        type={ButtonType.SUCCESS}
                        icon={<Unblock/>}
                        onClick={unblockUser}
                        disabled={isEmpty(reason)}
                    >
                        Розблокувати
                        Користувача
                    </Button>
                )
                : (<Button
                        type={ButtonType.DANGER}
                        onClick={blockUser}
                        disabled={isEmpty(reason)}
                        icon={<Block/>}
                    >
                        Заблокувати
                        Користувача
                    </Button>
                )
            }
        </div>
    );

    return (
        <>
            {user.isDisabled
                ? (
                    <Button
                        type={ButtonType.SUCCESS}
                        icon={<Unblock/>}
                        onClick={() => setModal(ModalType.UNBLOCK)}>Розблокувати</Button>
                )
                : (
                    <Button
                        type={ButtonType.DANGER}
                        icon={<Block/>}
                        onClick={() => setModal(ModalType.BLOCK)}
                    >
                        Заблокувати
                    </Button>
                )}
            <Modal
                open={!isEmpty(user) && !isEmpty(modal)}
                title={modalTitle}
                onCancel={closeModal}
                className="set-state-button__modal"
                footer={modalFooter}
            >
                <p>Повідомлення про блокування</p>
                <TextArea maxLength={5000} showCount value={reason} onChange={handleReasonChange}/>
            </Modal>
        </>
    );
}