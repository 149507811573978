import "./styles/aboutPage.scss";
import {BackButton} from "../../common/components/backButton/BackButton";
import React from "react";

export const AboutPage = () => {
    return (
        <div className="aboutPage">
            <BackButton className="aboutPage__back"/>
            <span className="aboutPage__title">Про нас</span>
            <div className="aboutPage__details">
                Барон – команда однодумців, які об’єднані любов’ю до України та до мілітарної тусовки 😊.<br/><br/>
                Ми є торговельною платформою та маректплейсом, де сотні тисяч Українців зможуть купити та продати все що
                їм може бути потрібно в ці складні для України часи. На нашій платформі Ви можете купуючи товар обирати
                з тисяч пропозицій як приватних продавців, так і найбільших мереж з продажу мілітарних товарів.<br/><br/>
                Нашою окремою місією є підтримка саме вітчизняних виробників якісних військових товарів, амуніції та
                продуктів сектору міл тех.<br/><br/>
                Вже сьогодні Барон є найбільш зручним місцем з продажу та придбання мілітарних товарів в України, і ми
                віримо, що незабаром ми станемо найкращим вибором для свідомих громадян в усій Європі.
            </div>
        </div>
    )
}