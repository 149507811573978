import {FC} from "react";
import {Menu} from "antd";
import {menu, STATIC_MENU_ITEMS, StaticMenuItemKeys} from "./MenuBuilder";
import {useCatalog} from "../../../commands/catalog/useCatalog";
import {Pages} from "../../../../routing/Pages";
import {createSearchParams, useNavigate} from "react-router-dom";
import {MenuInfo} from "rc-menu/lib/interface";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import "./styles/headerMenu.scss"

const staticMEnuItems: string[] = Object.values(StaticMenuItemKeys);

export const HeaderMenu: FC = () => {
    const navigate = useNavigate();
    const [catalog] = useCatalog();
    const handleChange = (selectedMenuItem: MenuInfo) => {
        const {key} = selectedMenuItem;
        const isStatic: boolean = staticMEnuItems.includes(key);
        if (isStatic) {
            navigate({pathname: `/app/${key}`});
        } else {
            navigate({
                pathname: Pages.SEARCH,
                search: `?${createSearchParams({category: selectedMenuItem.key})}`
            });
        }
    }
    const menuItems: ItemType[] = [...menu(catalog), ...STATIC_MENU_ITEMS];
    return (
        <div className="headerMenu">
            <Menu items={menuItems} mode="horizontal" theme="dark"
                  className="headerMenu__menu"
                  selectable={false}
                  onClick={handleChange}/>
        </div>
    );
};