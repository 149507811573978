import React, {FC} from "react";
import {AdDetailsView} from "../../common/components/adDetailsView/AdDetailsView";
import AdvertisementDecorator from "../../types/decorators/AdvertisementDecorator";
import {Button, ButtonType} from "../../common/components/button/Button";
import {ReactComponent as TickLogo} from "../newAdPage/resources/Tick.svg";
import {BackButton} from "../../common/components/backButton/BackButton";
import "./styles/adPreview.scss";

interface Props {
    advertisement: AdvertisementDecorator;
    onBack: () => void;
    uploadedImages?: string[];
    onPublish: () => void;
}

export const AdPreview: FC<Props> = (props: Props) => {
    const {advertisement, onBack, uploadedImages, onPublish} = props;
    return (
        <div className="adPreview">
            <BackButton className="adPreview__top-back" onClick={onBack}/>
            <AdDetailsView advertisement={advertisement} isPreview={true} uploadedImages={uploadedImages}/>
            <div className="adPreview__actions">
                <BackButton onClick={onBack}/>
                <Button type={ButtonType.PRIMARY} icon={<TickLogo/>} onClick={onPublish}>
                    Опублікувати
                </Button>
            </div>
        </div>
    );
}
