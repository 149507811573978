import React, {FC, SyntheticEvent, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {isEmpty} from "lodash-es";
import {Pages} from "../../../../routing/Pages";
import {useNavigateWithFrom} from "../../../hooks/useNavigateWithFrom";
import {useCurrentUser} from "../../../commands/user/useCurrentUser";
import {Button, ButtonType} from "../../button/Button";
import CreateConversationCommand from "./commands/CreateConversationCommand";
import "./styles/sendMessage.scss";
import {message} from "antd";

interface Props {
    disabled?: boolean;
    adId: string;
}

export const SendMessage: FC<Props> = (props: Props) => {
    const {disabled, adId} = props;
    const [user] = useCurrentUser();
    const [text, setText] = useState<string>("");
    const navigate = useNavigateWithFrom();

    const handleChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        setText(event.currentTarget.value);
    };

    const handleFocus = () => {
        if (!user) {
            navigate(Pages.LOGIN);
        }
    };

    const handleClick = async () => {
        if (!isEmpty(text)) {
            await new CreateConversationCommand(adId).execute(text);
            message.success("Повідомлення надіслане", 5);
        }
        setText("");
    };

    return (
        <div className="send-message">
            <p>Повідомлення продавцю</p>
            <TextArea placeholder="Текст повідомлення" showCount maxLength={5000} rows={4} value={text}
                      onChange={handleChange} disabled={disabled} onFocus={handleFocus}/>
            <div className="send-message__button">
                <Button type={ButtonType.OUTLINE} disabled={isEmpty(text) || disabled} onClick={handleClick}>Надіслати
                    Повідомлення</Button>
            </div>
        </div>
    )
};
