import FetchCommand from "../../fetch/FetchCommand";
import {Page} from "../../../types/integration/Page";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";

export default abstract class BaseSearchCommand<DTO> extends FetchCommand<Page<AdvertisementDecorator>, DTO> {
    protected nextPage?: string;

    withNexPage(nextPage?: string) {
        this.nextPage = nextPage;

        return this;
    }

    public abstract execute(payload?: DTO): Promise<Page<AdvertisementDecorator>>;
}