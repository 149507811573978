import {FC, useEffect, useState} from "react";
import {Col, List, Row, Segmented, Spin} from "antd";
import {Status} from "../adDetailsPage/commands/types/Advertisement";
import {ReactComponent as Accept} from "./resources/Accept.svg";
import {SegmentedValue} from "rc-segmented";
import AdvertisementDecorator from "../../types/decorators/AdvertisementDecorator";
import {useCatalog} from "../../common/commands/catalog/useCatalog";
import {SelectedAdRenderer} from "./selectedAdRenderer/SelectedAdRenderer";
import {ApproveAdCommand} from "./commands/ApproveAdCommand";
import {isEmpty} from "lodash-es";
import {DeclineAdCommand} from "./commands/DeclineAdCommand";
import {DeclineAction} from "./declineAction/DeclineAction";
import {Button, ButtonType} from "../../common/components/button/Button";
import {EmptyText} from "../../common/components/emptyText/EmptyText";
import "./styles/moderationPage.scss";
import {LoadMoreButton} from "../../common/components/loadMoreButton/LoadMoreButton";
import SearchForModerationCommand from "../../common/commands/search/SearchForModerationCommand";

export const ModerationPage: FC = () => {
    const [status, setStatus] = useState<Status>(Status.DRAFT);
    const [advertisements, setAdvertisements] = useState<AdvertisementDecorator[]>([]);
    const [nextPage, setNextPage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [catalog] = useCatalog();
    const [selectedAd, setSelectedAd] = useState<AdvertisementDecorator | null>(null);
    const handleStatusChange = (value: SegmentedValue) => {
        setStatus(value as Status);
    };
    const approve = async () => {
        await new ApproveAdCommand().execute(selectedAd?.id ?? "");
        setSelectedAd(null);
        setAdvertisements(advertisements.filter((item: AdvertisementDecorator) => item.id !== selectedAd?.id));
    };
    const decline = async (comment: string) => {
        await new DeclineAdCommand(comment).execute(selectedAd?.id ?? "");
        setAdvertisements(advertisements.filter((item: AdvertisementDecorator) => item.id !== selectedAd?.id));
        setSelectedAd(null);
    };
    const options = [
        {value: Status.DRAFT, label: `Очікують модерації (${advertisements.length})`},
    ];

    const loadAds = () => {
        new SearchForModerationCommand().withNexPage(nextPage).execute({status: Status.DRAFT})
            .then(({items, nextPage}) => {
                setNextPage(nextPage);
                setAdvertisements([...advertisements, ...items]);
                if (!selectedAd) {
                    setSelectedAd(items[0]);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (advertisements.length === 1 && nextPage) {
            loadAds();
        }
    }, [advertisements])
    useEffect(() => {
        if (!catalog) return;
        loadAds();
    }, [catalog]);

    return (
        <Spin spinning={loading} wrapperClassName="moderation-page">
            {isEmpty(advertisements)
                ? (<EmptyText text="Наразі немає оголошень для модерації"/>)
                : (
                    <>
                        <div className="moderation-page__header">
                            <div className="moderation-page__segmented">
                                <Segmented options={options} value={status} onChange={handleStatusChange}/>
                            </div>
                            <div className="moderation-page__buttons">
                                <DeclineAction disabled={isEmpty(selectedAd)} onDecline={decline}/>
                                <Button
                                    type={ButtonType.SUCCESS}
                                    icon={<Accept/>}
                                    disabled={isEmpty(selectedAd)}
                                    onClick={approve}
                                >
                                    Опублікувати
                                </Button>
                            </div>
                        </div>
                        <div className="moderation-page__content">
                            <div className="moderation-page__ads-list">
                                <List
                                    dataSource={advertisements}
                                    renderItem={(ad: AdvertisementDecorator) => {
                                        const className: string = selectedAd?.id === ad.id ? "ant-list-item--selected" : "";
                                        return (
                                            <List.Item key={ad.id} onClick={() => setSelectedAd(ad)}
                                                       className={className}>
                                                <Row>
                                                    <Col className="moderation-page__ad-number"
                                                         span="9">{`# ${ad.id}`}</Col>
                                                    <Col span="4">{ad.createdOn}</Col>
                                                    <Col span="11"
                                                         className="moderation-page__ad-title">{ad.title}</Col>
                                                </Row>
                                            </List.Item>
                                        );
                                    }}
                                />
                                {nextPage && (
                                    <LoadMoreButton onClick={loadAds} text="Показати ще 10 оголошень"/>
                                )}
                            </div>
                            <div className="moderation-page__preview">
                                {selectedAd && (
                                    <SelectedAdRenderer advertisement={selectedAd}/>
                                )}
                            </div>
                        </div>
                    </>
                )
            }
        </Spin>);
};
