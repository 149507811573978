import React, {FC, useState} from "react";
import {Button, ButtonType} from "../../../../common/components/button/Button";
import {ReactComponent as Mail} from "../../resources/Mail.svg";
import {Form, Input, Tabs} from "antd";
import {useNavigate} from "react-router-dom";
import {
    AdditionalUserInfo,
    createUserWithEmailAndPassword,
    fetchSignInMethodsForEmail,
    sendEmailVerification,
    signInWithEmailAndPassword,
    updateProfile,
    UserCredential
} from "firebase/auth";
import {auth} from "../../../../firebase/config";
import {isEmpty} from "lodash-es";
import {getAdditionalUserInfo} from "@firebase/auth";
import {isLoggedViaEmail} from "../../../../common/utils/UserUtils";
import {SEND_EMAIL_STORAGE_KEY, TIME_TO_SEND_EMAIL} from "../../emailVerification/EmailVerification";
import {useSessionStorage} from "../../../../common/hooks/useSessionStorrage";
import {Pages} from "../../../../routing/Pages";
import "./styles/emailLogin.scss";
import {BUSINESS_ACCOUNT_NAME_PROPS} from "../../../../common/commands/user/types/CurrentUserInfoDecorator";

interface EmailFormValues {
    email: string;
    password: string;
    isBusinessAccount?: boolean;
}

enum LoginTabsTypes {
    LOG_IN = "LOG_IN",
    SIGN_UP = "SIGN_UP"
}

const items = [
    {
        label: "Вхід із Email",
        key: LoginTabsTypes.LOG_IN
    },
    {
        label: "Реєстрація",
        key: LoginTabsTypes.SIGN_UP
    }
];

const emailField = (
    <Form.Item
        label="Електронна адреса"
        name="email"
        rules={[{required: true, message: "Будь ласка, введіть свою електронну адресу", type: "email"}]}
    >
        <Input/>
    </Form.Item>
);

const passwordField = (
    <Form.Item
        label="Пароль"
        name="password"
        rules={[{required: true, message: "Будь ласка, введіть свій пароль", min: 6}]}
    >
        <Input.Password/>
    </Form.Item>
);

interface Props {
    onResetPasswordClick: (email: string) => void;
}

export const EmailLogin: FC<Props> = (props: Props) => {
    const {onResetPasswordClick} = props;
    const [tab, setTab] = useState<string>(LoginTabsTypes.LOG_IN);
    const [form] = Form.useForm();
    const [, setSendEmailTimeout] = useSessionStorage(SEND_EMAIL_STORAGE_KEY);
    const register = () => {
        form.setFields([{
            name: "isBusinessAccount",
            value: false,
        }]);
        form.submit();
    };

    const logInWithEmail = () => {
        form.submit();
    };

    const handleSignInMethods = (email: string) => {
        return new Promise(async (resolve, reject) => {
            const methods: string[] = await fetchSignInMethodsForEmail(auth, email);
            if (isEmpty(methods) && tab === LoginTabsTypes.LOG_IN) {
                reject({
                    field: "email",
                    errors: [`Аккаунт з електронною адресою ${email} не знайдено. Будь ласка, зареєструйтесь`]
                });
            } else if (methods.includes("google.com")) {
                reject({
                    field: "email",
                    errors: [`Ви вже використовували електронну адресу ${email}. Щоб продовжити, увійдіть через ${methods[0]}`]
                });
            } else {
                resolve(methods);
            }
        });
    };

    const handleSignIn = (email: string, password: string) => {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, email, password)
                .then(resolve)
                .catch((e: any) => {
                    const {code} = e;
                    if (code === "auth/wrong-password") {
                        reject({
                            field: "password",
                            errors: ["Пароль або Email введені невірно"]
                        });
                    } else if (code === "auth/too-many-requests") {
                        reject({
                            field: "password",
                            errors: ["Ви ввели неправильний пароль забагато разів. Спробуйте за кілька хвилин."]
                        });
                    } else {
                        reject({
                            field: "password",
                            errors: ["Щось пішло не так, зверніться до адміністратора"]
                        });
                    }
                });
        })
    };

    const handleSignUp = (email: string, password: string) => {
        return new Promise<UserCredential>((resolve, reject) => {
            createUserWithEmailAndPassword(auth, email, password)
                .then(resolve)
                .catch((e: any) => {
                    const {code} = e;
                    if (code === "auth/email-already-in-use") {
                        reject({
                            field: "email",
                            errors: ["Електронна адреса вже використовується іншим обліковим записом"]
                        });
                    }
                });
        });
    };

    const logIn = async (values: EmailFormValues) => {
        await handleSignInMethods(values.email);
        await handleSignIn(values.email, values.password);
    };

    const signUp = async (values: EmailFormValues) => {
        await handleSignInMethods(values.email);
        const authResult: UserCredential = await handleSignUp(values.email, values.password);
        const {user} = authResult;
        if (values.isBusinessAccount) {
            const displayName: string = user.displayName ?? "";
            await updateProfile(user, {photoURL: `${displayName}${BUSINESS_ACCOUNT_NAME_PROPS}`});
        }
        const additionalUserInfo: AdditionalUserInfo | null = getAdditionalUserInfo(authResult);
        if (isLoggedViaEmail(user) && additionalUserInfo?.isNewUser) {
            setSendEmailTimeout(TIME_TO_SEND_EMAIL);
            await sendEmailVerification(user, {url: window.location.href});
        }
    };

    const handleFinish = async (values: EmailFormValues) => {
        try {
            if (tab === LoginTabsTypes.LOG_IN) {
                await logIn(values);
            } else {
                await signUp(values);
            }
        } catch (e: any) {
            form.setFields([{
                name: e.field,
                value: values[e.field as keyof EmailFormValues],
                errors: e.errors
            }]);
        }
    };

    const goToRegisterBusinessAccountPage = () => {
        form.setFields([{
            name: "isBusinessAccount",
            value: true,
        }]);
        form.submit();
    };

    const handleResetPasswordClick = () => {
        const value: string = form.getFieldValue("email");
        onResetPasswordClick(value);
    };

    const getButton = () => {
        form.resetFields();
        if (tab === LoginTabsTypes.LOG_IN) {
            return (<Button type={ButtonType.PRIMARY} onClick={logInWithEmail} icon={<Mail/>} buttonType="submit">Вхід із Email</Button>);
        }
        return (<Button type={ButtonType.PRIMARY} onClick={register} buttonType="button">Зареєструватись</Button>);
    };

    return (
        <div className="email-login">
            <Tabs items={items} onChange={setTab} activeKey={tab}/>
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
                form={form}
                onFinish={handleFinish}
                requiredMark={false}
            >
                {emailField}
                {passwordField}
                <Form.Item hidden
                           name="isBusinessAccount"
                >
                    <Input type="hidden"/>
                </Form.Item>
                <div>{getButton()}</div>
            </Form>
            {
                tab === LoginTabsTypes.SIGN_UP ? (
                        <Button type={ButtonType.LINK} onClick={goToRegisterBusinessAccountPage}>Зареєструвати
                            Бізнес-Акаунт</Button>)
                    : (
                        <Button type={ButtonType.LINK} onClick={handleResetPasswordClick}>Забули пароль?</Button>
                    )
            }
        </div>
    );
};