import React, {FC} from "react";
import {UploadFile} from "antd/lib/upload/interface";
import {Shop} from "../../types/integration/Shop";
import {ImageUploadCommand} from "../newAdPage/commands/ImageUploadCommand";
import {useCurrentUser} from "../../common/commands/user/useCurrentUser";
import {CreateShopCommand} from "./commands/CreateShopCommand";
import {updateProfile} from "firebase/auth";
import {CurrentUserInfoDecorator} from "../../common/commands/user/types/CurrentUserInfoDecorator";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../routing/Pages";
import {ShopForm, ShopFormMode} from "../../common/components/shopForm/ShopForm";

export const RegisterBusinessAccountPage: FC = () => {
    const [user, setUpdatedUser] = useCurrentUser();
    const navigate = useNavigate();

    const createBusinessAccount = async (values: Shop) => {
        if (!user) return;
        const files: UploadFile[] = values.info.logoUrl as unknown as UploadFile[];
        const uploadedImages: string[] = await new ImageUploadCommand(user!).execute(files);
        values.info.logoUrl = uploadedImages[0];

        const shop: Shop = await new CreateShopCommand().execute(values);
        await updateProfile(user.firebaseUser!, {photoURL: ""});

        setUpdatedUser(new CurrentUserInfoDecorator(user.firebaseUser, user.src, shop));
        navigate(Pages.LANDING);
    };

    return (
        <ShopForm onFinish={createBusinessAccount} mode={ShopFormMode.CREATE}/>
    );
}