import {FC} from "react";
import {Image} from "antd";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";
import {generatePath, Link} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import {AdToFavouritesButton} from "../adToFavouritesButton/AdToFavouritesButton";
import "./style/productTile.scss";

interface Props {
    advertisement: AdvertisementDecorator;
    className?: string;
}

export const ProductTile: FC<Props> = (props: Props) => {
    const {advertisement, className} = props;
    const [currentUserInfo] = useCurrentUser();
    const isFavorite = currentUserInfo ? currentUserInfo.hasFavorite(advertisement.id) : false;
    return (
        <Link to={generatePath(Pages.AD_PUBLIC_DETAILS, {adId: advertisement.id})} className={className ? `${className} product-tile` : "product-tile"} key={advertisement.id}>
            <div className="product-tile__content">
                <Image src={advertisement.thumbnails[0]} className="product-tile__inner-carousel" preview={false}/>
                <h4 className="product-tile__title">{advertisement.title}</h4>
                {advertisement.caliber && (
                    <p className="product-tile__details">{advertisement.caliber}</p>
                )}
                <p className="product-tile__price">{advertisement.price}</p>
            </div>
            <AdToFavouritesButton isFavorite={isFavorite} advertisement={advertisement}/>
        </Link>
    );
};