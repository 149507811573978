import FetchCommand from "../../../common/fetch/FetchCommand";
import {Advertisement} from "../../adDetailsPage/commands/types/Advertisement";
import {api} from "../../../common/fetch/api";

export class DeclineAdCommand extends FetchCommand<Advertisement, string> {
    private comment: string;

    constructor(comment: string) {
        super();
        this.comment = comment;
    }

    async execute(id: string) {
        return api<Advertisement>(`/api/a/${id}/decline`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(this.comment)
            }
        );
    }
}