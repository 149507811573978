import {Advertisement, Status} from "../../content/adDetailsPage/commands/types/Advertisement";
import {ReactNode} from "react";
import {ReactComponent as ActiveIcon} from "./resources/ActiveIcon.svg";
import {ReactComponent as ArchivedIcon} from "./resources/ArchivedIcon.svg";
import {ReactComponent as DeclinedIcon} from "./resources/DeclinedIcon.svg";
import {ReactComponent as AwaitingIcon} from "./resources/AwaitingIcon.svg";
import {omit} from "lodash-es";
import moment from "moment";
import {CatalogDecorator} from "../../common/commands/catalog/CatalogDecorator";
import "./styles/advertisement.scss";

const THUMBNAIL_SIZE: string = "230x230";
export const DATE_FORMAT: string = "DD.MM.yyyy";

export default class AdvertisementDecorator {
    src: Advertisement;

    constructor(src: Advertisement) {
        this.src = src;
    }

    get id(): string {
        return this.src.id ?? "";
    }

    get title(): string {
        return this.src.title ?? "";
    }

    get images(): string[] {
        return this.src.images ?? [];
    }

    get thumbnails(): string[] {
        return this.images.map((item: string) => {
            const segments: string[] = item.split("/");
            const lastIndex: number = segments.length;

            segments.splice(lastIndex - 1, 0, "thumbnails");
            segments[lastIndex] = this.addSizeToFileName(segments[lastIndex]);

            return segments.join("/");
        });
    }

    private addSizeToFileName(fileName: string) {
        const fileNameParts: string[] = fileName.split(".");
        const extension: string = fileNameParts.pop() ?? "";
        const name: string = fileNameParts.join(".");
        return `${name}_${THUMBNAIL_SIZE}.${extension}`;
    }

    get price(): string {
        return `${this.src.price || 0} грн`;
    }

    get status(): Status | undefined {
        return this.src.status;
    }

    get displayStatus(): ReactNode {
        let status: string;
        let icon: ReactNode;

        switch (this.status) {
            case Status.DRAFT:
                status = "Очікує модерації";
                icon = (<AwaitingIcon/>);
                break;
            case Status.DECLINED:
                status = "Відхилено";
                icon = (<DeclinedIcon/>);
                break;
            case Status.ARCHIVED:
                status = "Неактивне";
                icon = (<ArchivedIcon/>);
                break;
            case Status.ACTIVE:
                icon = (<ActiveIcon/>);
                status = "Активне";
                break;
            default:
                status = "";
                icon = null;
                break;
        }

        return (<span className="display-status">{icon}{status}</span>);
    }

    get days() {
        const date1 = new Date().getTime();
        const date2 = new Date(this.src.createdOn!).getTime();
        const diffTime = date1 - date2;
        const result = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        let text: string = "дні(в)"
        if (result === 1) {
            text = "день"
        }
        return `${result} ${text}`;
    }

    get moderatorComment(): string {
        return JSON.parse(this.src.moderatorComment ?? "");
    }

    get caliber(): ReactNode {
        return this.src["Калібр"];
    }

    get characteristics(): Record<string, string> {
        const knownProps: string[] = ["authorId", "category", "createdOn", "description", "email", "expiresOn", "id", "images", "location",
            "phoneNumber", "price", "priority", "schemaId", "shop", "status", "title"
        ];

        return omit(this.src, knownProps);
    }

    get createdOn() {
        return moment(this.src.createdOn).format(DATE_FORMAT);
    }

    get email(): string {
        return this.src.email ?? "";
    }

    get phoneNumber(): string {
        return this.src.phoneNumber ?? "";
    }

    get location(): string {
        return this.src.location ?? "";
    }

    get category(): string | undefined {
        return this.src["category"];
    }

    editDetails(catalog: CatalogDecorator) {
        const knownProps: string[] = ["authorId", "category", "expiresOn", "id", "priority", "schemaId", "shop", "status"];
        const result: Record<string, string | string[] | undefined> = omit(this.src, knownProps);
        result.category = catalog.pathToLeaf(this.src.category)?.split(".");
        //TODO: Check for possible fix
        result.category![result!.category!.length - 1] = this.src.category;
        return result;
    }
}