import {FC} from "react";
import {ReactComponent as Refresh} from "./resources/refresh.svg";
import "./styles/loadMoreButton.scss";

interface Props {
    onClick: () => void;
    text: string;
}

export const LoadMoreButton: FC<Props> = (props: Props) => {
    const {onClick, text} = props;
    return (
        <div className="load-more">
            <div className="load-more__button" onClick={onClick}>
                <Refresh/> {text}
            </div>
        </div>
    )
}