import React, {FC, useEffect, useState} from "react";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import {ReactComponent as Lock} from "./resources/Lock.svg";
import {ReactComponent as Logo} from "./resources/Logo.svg";
import {Form, Input} from "antd";
import {Button, ButtonType} from "../../../common/components/button/Button";
import {confirmPasswordReset, verifyPasswordResetCode} from "firebase/auth";
import {auth} from "../../../firebase/config";
import {ReactComponent as ArrowLeft} from "../resetPasswordRequest/resources/ArrowLeft.svg";
import "./styles/resetPassword.scss";

const {useForm} = Form;

interface FormValues {
    password1?: string;
    password2?: string;
}

export const ResetPassword: FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [form] = useForm();
    const oobCode: string = searchParams.get("oobCode") ?? "";
    const [isResetSuccessful, setResetSuccessful] = useState<boolean>(false);
    const [isLinkBroken, setLinkBroken] = useState<boolean>(false);

    const submitForm = () => {
        form.submit();
    };

    const resetPassword = async (password: string) => {
        try {
            await confirmPasswordReset(auth, oobCode, password);
            setResetSuccessful(true);
        } catch (e) {
            console.log(e);
        }
    };

    const validateCode = async () => {
        try {
            await verifyPasswordResetCode(auth, oobCode);
        } catch (e: any) {
            setLinkBroken(true);
        }
    };

    const goToLandingPage = () => {
        navigate("/");
    };

    const handleSubmit = async (values: FormValues) => {
        const {password1 = "", password2} = values;
        if (password1 !== password2) {
            form.setFields([
                {
                    name: "password2",
                    value: password2,
                    errors: ["Паролі не збігаються"]
                }
            ])
        } else {
            await resetPassword(password1);
        }
    };

    const getFormContent = () => {
        if (!isResetSuccessful) {
            return (
                <>
                    <p>Створення нового пароля</p>
                    <Form<FormValues> form={form} onFinish={handleSubmit} layout="vertical" requiredMark={false}>
                        <Form.Item
                            label="Новий пароль"
                            name="password1"
                            rules={[{required: true, message: "Будь ласка, введіть свій новий пароль!", min: 6}]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item
                            label="Повторити новий пароль"
                            name="password2"
                            rules={[{required: true, message: "Будь ласка, повторіть свій новий пароль!", min: 6}]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    </Form>
                    <div className="reset-password__controls">
                        <Button type={ButtonType.PRIMARY} onClick={submitForm}>Зберегти</Button>
                    </div>
                </>
            );
        }

        return (
            <>
                <p>Пароль успішно змінено</p>
                <Button type={ButtonType.LINK} icon={<ArrowLeft/>} onClick={goToLandingPage}>Повернутись на
                    головну сторінку</Button>
            </>
        );
    };

    const getLinkBrokenContent = () => {
        return (
            <p>Ваш запит на скидання пароля вже не дійсний або посиланням уже скористалися. Скиньте пароль ще раз.</p>);
    };

    if (!oobCode) {
        return (<Navigate to="/"/>);
    }

    useEffect(() => {
        validateCode();
    }, []);

    return (
        <div className="reset-password">
            <div className="reset-password__logo">
                <Lock/>
            </div>
            <div className="reset-password__logo">
                <Logo/>
            </div>
            {isLinkBroken ? getLinkBrokenContent() : getFormContent()}
        </div>
    );
};