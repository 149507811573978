import * as React from "react";
import {FC, ReactNode, useEffect, useState} from "react";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {ProductTile} from "../../common/components/productTile/ProductTile";
import AdvertisementDecorator from "../../types/decorators/AdvertisementDecorator";
import {FilterPanel} from "./lhs/FilterPanel";
import {AdTypes, getPickList} from "../../common/staticData/StaticData";
import {Segmented, Select, Space, Spin, Tag} from "antd";
import {ReactComponent as Close} from "./resources/close.svg";
import {Pages} from "../../routing/Pages";
import {SegmentedValue} from "rc-segmented";
import {removeFromSearchParams, searchParamsToPayload} from "../../common/utils/ObjectUtils";
import {FindGeneralDto, PublicSort} from "../../types/integration/SearchDto";
import {Status} from "../adDetailsPage/commands/types/Advertisement";
import {useCatalog} from "../../common/commands/catalog/useCatalog";
import {Button, ButtonType} from "../../common/components/button/Button";
import {isEmpty} from "lodash-es";
import {EmptyText} from "../../common/components/emptyText/EmptyText";
import {LoadMoreButton} from "../../common/components/loadMoreButton/LoadMoreButton";
import SearchGeneralCommand from "../../common/commands/search/SearchGeneralCommand";
import "./styles/searchPage.scss";
import {useScreenWidth} from "../../common/hooks/useScreenWidth";
import {isMobile} from "../../common/components/screen/Screen";

export const SearchPage: FC = () => {
    const [catalog] = useCatalog();
    const navigate = useNavigate();
    const width = useScreenWidth();
    const [searchParams] = useSearchParams();
    const [advertisements, setAdvertisements] = useState<AdvertisementDecorator[]>([]);
    const [nextPage, setNextPage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [sortingValue, setSortingValue] = useState<PublicSort>(PublicSort.EXPIRATION_DESC);
    const options = getPickList("adTypes");

    useEffect(() => {
        if (catalog === null) {
            return;
        }
        setNextPage(undefined);
        loadAds(undefined);
    }, [searchParams, catalog, sortingValue]);

    const loadAds = (nextPageToLoad: string | undefined) => {
        if (catalog) {
            const searchPhrase: string | null = searchParams.get("searchPhrase");
            const {priceFrom, priceTo, ...searchObject} = searchParamsToPayload(searchParams, catalog);
            const tags: Record<string, string[]>[] = Object.keys(searchObject).map((item: string) => {
                return {[item]: searchObject[item]};
            });
            setLoading(true);
            const payload: FindGeneralDto = {
                limit: 30,
                status: Status.ACTIVE,
                sort: sortingValue,
                ...(searchPhrase && {searchPhrase}),
                priceFrom,
                priceTo
            };
            payload.tags = tags;
            new SearchGeneralCommand().withNexPage(nextPageToLoad).execute(payload)
                .then(({items, nextPage}) => {
                    if (nextPageToLoad) {
                        setAdvertisements([...advertisements, ...items]);
                    } else {
                        setAdvertisements(items);
                    }
                    setNextPage(nextPage);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const handleAdTypeChange = (value: SegmentedValue) => {
        searchParams.set("type", value as string);
        navigate({
            pathname: Pages.SEARCH,
            search: `?${searchParams}`
        });
    }

    const searchTags: ReactNode[] = []

    const handleTagClose = (key: string, value: string) => {
        return () => {
            navigate({
                pathname: Pages.SEARCH,
                search: `?${removeFromSearchParams(searchParams, key, value)}`
            });
        }
    }

    searchParams.forEach((value: string, key: string) => {
        let label: string = value;

        if (key === "priceFrom") {
            label = `Ціна від: ${value}`
        }

        if (key === "priceTo") {
            label = `Ціна до: ${value}`
        }

        if (key === "category") {
            label = catalog?.getCategoryNameById(value, catalog.src) ?? "";
        }

        if (!["type", "searchPhrase"].includes(key)) {
            searchTags.push(<Tag onClose={handleTagClose(key, value)} closable={true}
                                 key={`${key}_${value}`}>{label}</Tag>)
        }
    })

    const clearFilters = () => {
        navigate({
            pathname: Pages.SEARCH,
            search: `?${createSearchParams({type: searchParams.get("type") ?? AdTypes.All})}`
        });
    };

    const loadMoreAds = () => {
        loadAds(nextPage);
    };

    const handleSortingChange = (value: PublicSort) => {
        setSortingValue(value);
    };

    const closeFiltersButton: ReactNode = (
        <Button type={ButtonType.LINK} icon={<Close/>} onClick={clearFilters}>Скинути фільтри</Button>
    );

    return (
        <div className="search-page">
            {!isEmpty(searchTags) && (
                <div className="search-page-searchTags">
                    {isMobile(width) && closeFiltersButton}
                    <div className="search-page-searchTags__content">
                        {searchTags}
                        {!isMobile(width) && closeFiltersButton}
                    </div>
                </div>
            )}

            <div className="search-page__content">
                {!isMobile(width) && (<FilterPanel/>)}
                <div className="search-page__search-result">
                    <div className="search-page__top-filters">
                        <Segmented options={options} onChange={handleAdTypeChange}
                                   value={searchParams.get("type") ?? AdTypes.All}/>
                        <Select
                            options={getPickList("sorting")}
                            placeholder="Сортувати"
                            value={sortingValue}
                            onChange={handleSortingChange}
                        />
                    </div>
                    <Spin wrapperClassName="search-page__results" spinning={loading} size="large">
                        {
                            isEmpty(advertisements)
                                ? (<EmptyText text="Не знайдено оголошень за даними фільтрами"/>)
                                : <Space size={[18, 20]} wrap>{advertisements.map((advertisement: AdvertisementDecorator) => (
                                    <ProductTile
                                        className="search-page__ad"
                                        advertisement={advertisement}
                                        key={advertisement.id}
                                    />
                                ))
                                }
                                </Space>
                        }
                    </Spin>
                    {nextPage && (<LoadMoreButton onClick={loadMoreAds} text="Показати ще 30 оголошень"/>)}
                </div>
            </div>
        </div>
    );
}