import {FC, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Breadcrumb, Card, Col, Row, Spin} from "antd";
import {BackButton} from "../../common/components/backButton/BackButton";
import {Pages} from "../../routing/Pages";
import {Button, ButtonType} from "../../common/components/button/Button";
import {AdDetailsCarousel} from "../../common/components/adDetailsView/adDetailsCarousel/AdDetailsCarousel";
import {UploadFile} from "antd/lib/upload/interface";
import {useCurrentUser} from "../../common/commands/user/useCurrentUser";
import {createPartner, PREVIEW_PARTNER_ID} from "../administration/createPartnerPage/CreatePartnerPage";
import GetPartnersPublicInfoCommand
    from "../../common/components/partnersCarousel/commands/GetPartnersPublicInfoCommand";
import {PublicPartnerPageInfo} from "../../types/integration/Partners";
import {ReactComponent as Viber} from "./resources/Viber.svg";
import {ReactComponent as Telegram} from "./resources/telegram.svg";
import {ReactComponent as Facebook} from "./resources/facebook.svg";
import {ReactComponent as Phone} from "./resources/Phone.svg";
import {ReactComponent as Mail} from "./resources/Mail.svg";
import {ReactComponent as Web} from "./resources/Web.svg";
import Instagram from "./resources/instagram.png";
import "./styles/partnerDetailsPage.scss";

export const PartnerDetailsPage: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [partner, setPartner] = useState<PublicPartnerPageInfo | undefined>();
    const [user] = useCurrentUser();
    const {state} = useLocation();
    const {partnerId} = useParams();
    const isExistingPartner: boolean = !!state?.createdOn;
    const navigate = useNavigate();
    const currentPartner: PublicPartnerPageInfo | undefined = partner || state;
    const isPreviewMode: boolean = partnerId === PREVIEW_PARTNER_ID;
    const getImages = (): string[] => {
        let uploadedFiles: string[] = []
        if (isPreviewMode) {
            if (state.files) {
                uploadedFiles = state.files.map((item: UploadFile) => URL.createObjectURL(item.originFileObj as Blob));
            }
        }
        return [
            ...(currentPartner?.images || []),
            ...uploadedFiles
        ];
    };
    const goBack = () => {
        if (isPreviewMode) {
            navigate(Pages.CREATE_PARTNER, {state});
        } else {
            navigate(Pages.LANDING);
        }
    };
    const publish = async () => {
        const {files, ...rest} = state;
        const currentPartner = state || {};
        try {
            await createPartner(user!, {...rest, id: currentPartner.id}, rest.images, files);
            navigate(`${Pages.ADMINISTRATION}?tab=partners`);
        } catch (e) {
            console.log(e);
        }
    };
    const loadPartner = async () => {
        setLoading(true);
        try {
            const [result] = await new GetPartnersPublicInfoCommand().execute([partnerId ?? ""]);
            setPartner(result);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (partnerId !== PREVIEW_PARTNER_ID) {
            loadPartner();
        }
    }, []);

    return (
        <Spin wrapperClassName="partner-details" spinning={loading}>
            <BackButton onClick={goBack}/>
            {isPreviewMode && (
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to={`${Pages.ADMINISTRATION}?tab=partners`}>Партнери</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={Pages.CREATE_PARTNER} state={currentPartner}>Сторінка нового партнера</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Попередній перегляд
                    </Breadcrumb.Item>
                </Breadcrumb>
            )}
            <h1>{currentPartner?.name}</h1>
            <Row>
                <Col span="12" className="partner-details__carousel">
                    <AdDetailsCarousel images={getImages()}/>
                </Col>
                <Col span="12">
                    <Card title="Опис" bordered={false}>
                        {currentPartner?.description}
                    </Card>
                    <Card title="Контакти" bordered={false}>
                        <p className="partner-details__address">{currentPartner?.address}</p>
                        <ul className="partner-details__contacts">
                            {currentPartner?.webUrl && (
                                <li>
                                    <Row>
                                        <Col span="4">
                                            <span>Сайт:</span>
                                        </Col>
                                        <Col span="20">
                                            <a className="partner-details__link" href={currentPartner?.webUrl} target="__blank">
                                                <Web/>
                                                {currentPartner?.webUrl}
                                            </a>
                                        </Col>
                                    </Row>
                                </li>
                            )}
                            {currentPartner?.email && (
                                <li>
                                    <Row>
                                        <Col span="4">
                                            <span>Email:</span>
                                        </Col>
                                        <Col span="20">
                                            <a className="partner-details__link" href={`mailto:${currentPartner?.email}`}>
                                                <Mail/>
                                                {currentPartner?.email}
                                            </a>
                                        </Col>
                                    </Row>
                                </li>
                            )}
                            {currentPartner?.phone && (
                                <li>
                                    <Row>
                                        <Col span="4">
                                            <span>Тел.:</span>
                                        </Col>
                                        <Col span="20">
                                            <a className="partner-details__link" href={`tel:${currentPartner?.phone}`}>
                                                <Phone/>
                                                {currentPartner?.phone}
                                            </a>
                                        </Col>
                                    </Row>
                                </li>
                            )}
                            {currentPartner?.telegram && (
                                <li>
                                    <Row>
                                        <Col span="4">
                                            <span>Telegram:</span>
                                        </Col>
                                        <Col span="20">
                                            <a className="partner-details__link" href={`https://t.me/${currentPartner?.telegram}`}>
                                                <Telegram/>
                                                {currentPartner?.telegram}
                                            </a>
                                        </Col>
                                    </Row>
                                </li>
                            )}
                            {currentPartner?.viber && (
                                <li>
                                    <Row>
                                        <Col span="4">
                                            <span>Viber:</span>
                                        </Col>
                                        <Col span="20">
                                            <a className="partner-details__link" href={`viber://chat/?number=${currentPartner?.viber.replace("+", "%2B")}`}>
                                                <Viber/>
                                                {currentPartner?.viber}
                                            </a>
                                        </Col>
                                    </Row>
                                </li>
                            )}
                            {currentPartner?.facebook && (
                                <li>
                                    <Row>
                                        <Col span="4">
                                            <span>Facebook:</span>
                                        </Col>
                                        <Col span="20">
                                            <a className="partner-details__link" href={currentPartner?.facebook}>
                                                <Facebook/>
                                                {currentPartner.name}
                                            </a>
                                        </Col>
                                    </Row>
                                </li>
                            )}
                            {currentPartner?.instagram && (
                                <li>
                                    <Row>
                                        <Col span="4">
                                            <span>Instagram:</span>
                                        </Col>
                                        <Col span="20">
                                            <a className="partner-details__link" href={currentPartner?.instagram}>
                                                <img src={Instagram} alt="instagram"/>
                                                {currentPartner.name}
                                            </a>
                                        </Col>
                                    </Row>
                                </li>
                            )}
                        </ul>
                    </Card>
                </Col>
            </Row>
            {isPreviewMode && (
                <div className="partner-details__controls">
                    <Button type={ButtonType.PRIMARY} onClick={publish}>
                        {isExistingPartner ? "Зберегти" : "Опублікувати"}
                    </Button>
                </div>
            )}
        </Spin>
    );
};