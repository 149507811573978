import {FC} from "react";
import {Pages} from "../../../../routing/Pages";
import {useNavigateWithFrom} from "../../../hooks/useNavigateWithFrom";
import {useCurrentUser} from "../../../commands/user/useCurrentUser";
import {Button, ButtonType} from "../../button/Button";
import {isMobile} from "../../screen/Screen";
import {useScreenWidth} from "../../../hooks/useScreenWidth";

export const CreateNewAdButton: FC = () => {
    const [user] = useCurrentUser();
    const width = useScreenWidth();
    const navigate = useNavigateWithFrom();
    const handleClick = () => {
        if (!user) {
            navigate(Pages.LOGIN, Pages.NEW_AD);
        } else {
            navigate(Pages.NEW_AD);
        }
    };

    return (<Button type={ButtonType.SECONDARY} icon="+" onClick={handleClick}>{isMobile(width) ? null : "Нове Оголошення"}</Button>);
};