import {FC} from "react";
import "./styles/emptyText.scss";

interface Props {
    text: string;
}

export const EmptyText: FC<Props> = (props: Props) => {
    const {text} = props;
    return (<h1 className="empty-text">{text}</h1>);
}