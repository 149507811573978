import FetchCommand from "../../../../../common/fetch/FetchCommand";
import {api} from "../../../../../common/fetch/api";
import {ConversationDecorator} from "../../../../../types/decorators/ConversationDecorator";
import {Conversation} from "../../../../../types/integration/Conversation";
import AdvertisementDecorator from "../../../../../types/decorators/AdvertisementDecorator";
import GetAdPublicInfoCommand from "../../../../../common/commands/ad/GetAdPublicInfoCommand";
import {arrayToMap} from "../../../../../common/utils/ArrayUtils";
import {Page} from "../../../../../types/integration/Page";
import {isEmpty} from "lodash-es";

export default class GetConversationsCommand extends FetchCommand<Page<ConversationDecorator>, undefined> {
    private initiator: boolean = false;
    private nextPage?: string;

    forInitiator(initiator: boolean) {
        this.initiator = initiator;
        return this;
    }

    withNextPage(nextPage?: string) {
        this.nextPage = nextPage;
        return this;
    }

    async execute() {
        const url: string = this.nextPage || `/api/conversations?initiator=${this.initiator}`;
        return api<Page<Conversation>>(url).then(async ({items, nextPage}: Page<Conversation>) => {
            const adIds: string[] = items.map((item: Conversation) => item.adId);
            let advertisements: Record<string, AdvertisementDecorator> = {};
            if (!isEmpty(adIds)) {
                advertisements = await new GetAdPublicInfoCommand().execute(adIds).then(arrayToMap<AdvertisementDecorator>);
            }
            let conversations: ConversationDecorator[] = items.map((item: Conversation) => new ConversationDecorator(item));
            conversations = conversations.map((item: ConversationDecorator) => {
                item.advertisement = advertisements[item.adId];
                return item;
            });
            return {items: conversations, nextPage};
        });
    }
}