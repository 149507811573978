import {FC, FunctionComponent, SyntheticEvent} from "react";
import {Tooltip} from "antd";
import {ReactComponent as IconHeartSelected} from "../productTile/resources/heartSelected.svg";
import {ReactComponent as IconHeart} from "../productTile/resources/heart.svg";
import AddToFavoritesCommand from "../../commands/ad/AddToFavoritesCommand";
import {Pages} from "../../../routing/Pages";
import RemoveFromFavoritesCommand from "../../commands/ad/RemoveFromFavoritesCommand";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";
import {useNavigate} from "react-router-dom";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import "./styles/addToFavourites.scss";

interface Props {
    disabled?: boolean;
    advertisement: AdvertisementDecorator;
    isFavorite: boolean;
    removeButtonComponent?: FunctionComponent<{ onClick: (event: SyntheticEvent) => void, disabled?: boolean }>;
    addButtonComponent?: FunctionComponent<{ onClick: (event: SyntheticEvent) => void , disabled?: boolean}>
}

export const AdToFavouritesButton: FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const {
        isFavorite, advertisement,
        removeButtonComponent: RemoveButton = IconHeartSelected,
        addButtonComponent: AddButton = IconHeart,
        disabled = false
    } = props;
    const [user, setCurrentUserInfo] = useCurrentUser();

    const addToFavorite = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (user) {
            const userInfo = await new AddToFavoritesCommand().execute({adId: advertisement.id, user});
            setCurrentUserInfo(userInfo);
        } else {
            navigate(Pages.LOGIN);
        }
    }

    const removeFromFavorite = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (user) {
            const userInfo = await new RemoveFromFavoritesCommand().execute({
                adIds: [advertisement.id],
                user
            });
            setCurrentUserInfo(userInfo);
        }
    }

    return (
        <div className="add-to-favourite">
            {isFavorite && (
                <Tooltip title="Видалити з обраного">
                    <RemoveButton disabled={disabled} onClick={removeFromFavorite} className="add-to-favourite__remove"/>
                </Tooltip>
            )}
            {!isFavorite && (
                <AddButton disabled={disabled} onClick={addToFavorite} className="add-to-favourite__add"/>
            )}
        </div>
    );
};