import {FC} from "react";
import {ReactComponent as DeleteIcon} from "./resources/DeleteIcon.svg";
import ArchiveAdsCommand from "../../../../../../common/commands/ad/ArchiveAdsCommand";
import {Button, ButtonType} from "../../../../../../common/components/button/Button";

interface Props {
    onArchive?: () => void;
    adId: string;
}

export const DeleteButton: FC<Props> = (props: Props) => {
    const {onArchive, adId} = props;
    const makeNotActive = async () => {
        await new ArchiveAdsCommand().execute([adId]).then(() => {
            onArchive?.();
        })
    }
    if (!onArchive) {
        return null;
    }
    return (<Button type={ButtonType.LINK} icon={<DeleteIcon/>} onClick={makeNotActive}>Зняти Оголошення</Button>);
};