import FetchCommand from "../../../../../../common/fetch/FetchCommand";
import {api} from "../../../../../../common/fetch/api";
import {CurrentUserInfo} from "../../../../../../common/commands/user/types/CurrentUserInfo";
import {CurrentUserInfoDecorator} from "../../../../../../common/commands/user/types/CurrentUserInfoDecorator";

export default class FindUsersCommand extends FetchCommand<CurrentUserInfoDecorator[], string> {
    async execute(criteria: string) {
        return api<CurrentUserInfo[]>("/api/users/find", {
            method: "PUT",
            body: criteria
        }).then((result: CurrentUserInfo[]) => result
            .map((item: CurrentUserInfo) => new CurrentUserInfoDecorator(undefined, item)));
    }
}