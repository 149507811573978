import FetchCommand from "../../../../../common/fetch/FetchCommand";
import {api} from "../../../../../common/fetch/api";
import {Shop, ShopInfo} from "../../../../../types/integration/Shop";

export default class UpdateShopCommand extends FetchCommand<Shop, ShopInfo> {
    shopId: string;

    constructor(shopId: string) {
        super();
        this.shopId = shopId;
    }

    async execute(shopInfo: ShopInfo) {
        return api<Shop>(`/api/shops/${this.shopId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(shopInfo)
        })
    }
}