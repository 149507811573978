import FetchCommand from "../../fetch/FetchCommand";
import {api} from "../../fetch/api";
import {CurrentUserInfoDecorator} from "../user/types/CurrentUserInfoDecorator";
import {CurrentUserInfo} from "../user/types/CurrentUserInfo";

interface Payload {
    user: CurrentUserInfoDecorator;
    adId: string;
}

export default class AddToFavoritesCommand extends FetchCommand<CurrentUserInfoDecorator, Payload> {

    async execute(payload: Payload) {
        return api<CurrentUserInfo>(`/api/users/${payload.user.id}/ad/favorites?adId=${payload.adId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((user: CurrentUserInfo) => {
            return new CurrentUserInfoDecorator(payload.user.firebaseUser, user);
        });
    }
}