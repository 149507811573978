import FetchCommand from "../../fetch/FetchCommand";
import {api} from "../../fetch/api";
import {CurrentUserInfoDecorator} from "../user/types/CurrentUserInfoDecorator";
import {CurrentUserInfo} from "../user/types/CurrentUserInfo";

interface Payload {
    user: CurrentUserInfoDecorator;
    adIds: string[];
}

export default class RemoveFromFavoritesCommand extends FetchCommand<CurrentUserInfoDecorator, Payload> {

    async execute(payload: Payload) {
        return api<CurrentUserInfo>(`/api/users/${payload.user.id}/ad/favorites`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.adIds)
        }).then((user: CurrentUserInfo) => {
            return new CurrentUserInfoDecorator(payload.user.firebaseUser, user);
        });
    }
}