import {getIdToken} from "firebase/auth";
import {getCookie} from "./FetchUtils";
import {auth} from "../../firebase/config";

const APPLICATION_JSON: string = "application/json";

export async function api<RESPONSE>(info: RequestInfo, init?: RequestInit): Promise<RESPONSE> {
    const enrichedRequest = await enrichRequest(info, init);
    return fetch(info, enrichedRequest).then(async (response: Response) => {
        const contentType: string | null = response.headers.get("content-type");

        if (contentType?.startsWith(APPLICATION_JSON)) {
            const data: RESPONSE = await response.json();
            if (response.ok) {
                const contentType: string | null = response.headers.get("content-type");

                if (contentType?.startsWith(APPLICATION_JSON)) {
                    return Promise.resolve(data) as unknown as Promise<RESPONSE>;
                }

                return Promise.resolve(response) as unknown as Promise<RESPONSE>;
            }

            const {status, statusText} = response;
            const {message} = data as unknown as Record<string, string>;
            return Promise.reject({status, statusText, message});
        }

        if (response.ok) {
            return Promise.resolve(response) as unknown as Promise<RESPONSE>;
        }

        return Promise.reject(response);
    });
}

async function enrichRequest(info: RequestInfo, request?: RequestInit) {
    const enrichedRequest: RequestInit = request ? {...request} : {};
    enrichedRequest.headers = await enrichHeaders(enrichedRequest, info);
    return enrichedRequest;
}

async function enrichHeaders(request: RequestInit, info: RequestInfo) {
    const headers: Headers = request.headers ? new Headers(request.headers) : new Headers();
    const xsrfToken: string | null = getCookie("XSRF-TOKEN");

    if (xsrfToken) {
        headers.append("X-XSRF-TOKEN", xsrfToken);
    }

    if (typeof info === "string" && info.startsWith("/")) {
        if (auth.currentUser) {
            const idToken: string = await getIdToken(auth.currentUser!);
            headers.append("Authorization", `Bearer ${idToken}`);
        }
    }

    headers.append("X-Requested-With", "XMLHttpRequest");

    return headers;
}