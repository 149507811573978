import FetchCommand from "../../../../common/fetch/FetchCommand";
import {api} from "../../../../common/fetch/api";
import {PartnerPage} from "../../../../types/integration/Partners";

export default class UpdatePartnerCommand extends FetchCommand<PartnerPage, string> {
    private partner: PartnerPage;

    constructor(partner: PartnerPage) {
        super();
        this.partner = partner;
    }

    async execute(id: string) {
        return api<PartnerPage>(`/api/partners/${id}`, {
            method: "PUT",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(this.partner)
        });
    }
}