import {FC, useEffect, useState} from "react";
import {MyAdsView} from "../../../common/components/myAdsView/MyAdsView";
import FindUsersCommand from "../administrationPage/tabs/users/commands/FindUsersCommand";
import {GetUserAdsCountCommand} from "../../userProfilePage/content/myAds/commands/GetUserAdsCountCommand";
import {generatePath, Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {CurrentUserInfoDecorator, UserRole} from "../../../common/commands/user/types/CurrentUserInfoDecorator";
import {Status} from "../../adDetailsPage/commands/types/Advertisement";
import {Breadcrumb, Col, Row, Select, Spin, Tabs} from "antd";
import SearchForAuthorCommand from "../../../common/commands/search/SearchForAuthorCommand";
import {AuthorSort} from "../../../types/integration/SearchDto";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";
import {ReactComponent as Avatar} from "./resources/Avatar.svg";
import {UserStatusRenderer} from "../administrationPage/tabs/users/usersList/userStatusRenderer/UserStatusRenderer";
import {SetStatusButton} from "../../../common/components/setStatusButton/SetStatusButton";
import {userRoles} from "../administrationPage/tabs/users/Users";
import SetCustomClaimsCommand from "./commands/SetCustomClaimsCommand";
import {Pages} from "../../../routing/Pages";
import {UserProfileTabs} from "../../userProfilePage/UserProfileTabs";
import {isEmpty} from "lodash-es";
import {CoreAttributes} from "../../userProfilePage/content/profile/coreAttributes/CoreAttributes";
import "./styles/administrationUserDetails.scss";
import {Tab} from "rc-tabs/lib/interface";

const rolesOptions = [
    ...userRoles,
    {
        value: UserRole.PHYSICAL_PERSON,
        label: "Фізична Особа"
    }
];

export const AdministrationUserDetails: FC = () => {
    const navigate = useNavigate();
    const {userId = ""} = useParams();
    const [user, setUser] = useState<CurrentUserInfoDecorator | undefined>();
    const [status, setStatus] = useState<Status>(Status.ACTIVE);
    const [loading, setLoading] = useState<boolean>(true);
    const [nextPage, setNextPage] = useState<string | undefined>();
    const [advertisements, setAdvertisements] = useState<AdvertisementDecorator[]>([]);
    const [counts, setCounts] = useState<Record<Status, number>>({DRAFT: 0, ACTIVE: 0, DECLINED: 0, ARCHIVED: 0});
    const [role, setRole] = useState<string>("");

    const items: Tab[] = [
        {
            label: "Оголошення",
            key: UserProfileTabs.MY_ADS,
            disabled: isEmpty(advertisements)
        },
        {
            label: "Профіль",
            key: UserProfileTabs.PROFILE
        },
    ];

    const loadUser = async () => {
        setLoading(true);
        try {
            const [user] = await new FindUsersCommand().execute(userId);
            setUser(user);
            setRole(user.role);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const loadData = async (nextPageToLoad: string | undefined = undefined) => {
        if (!user) return;
        setLoading(true);
        try {
            const counts = await new GetUserAdsCountCommand().execute(userId);
            await new SearchForAuthorCommand().withNexPage(nextPageToLoad).execute({
                status,
                limit: 20,
                sort: AuthorSort.EXPIRATION_ASC,
                tags: [{shop: [""]}],
                userId: user?.id ?? ""
            }).then(({items, nextPage}) => {
                if (nextPageToLoad) {
                    setAdvertisements([...advertisements, ...items]);
                } else {
                    setAdvertisements(items);
                }
                setNextPage(nextPage);
            });
            setCounts(counts);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = async () => {
        await loadData(nextPage);
    };

    const handleRoleChange = async (value: string | string[]) => {
        setRole(value as string);
        const roles: UserRole[] = value === UserRole.PHYSICAL_PERSON ? [] : [value] as UserRole[];
        try {
            await new SetCustomClaimsCommand(roles).execute(user?.id ?? "");
            await loadUser();
        } catch (e) {
            console.log(e);
        }
    };

    const handleTabChange = (activeKey: string) => {
        const path: string = generatePath(Pages.ADMINISTRATION_USER_DETAILS, {userId: user?.id ?? ""});
        navigate(`${path}?tab=${activeKey}`);
    };

    useEffect(() => {
        loadUser();
    }, [])

    useEffect(() => {
        loadData();
    }, [status, user]);

    useEffect(() => {
        if (!user) return;
        const path: string = generatePath(Pages.ADMINISTRATION_USER_DETAILS, {userId: user?.id ?? ""});
        if (!isEmpty(advertisements)) {
            navigate(`${path}?tab=${UserProfileTabs.MY_ADS}`);
        } else {
            navigate(`${path}?tab=${UserProfileTabs.PROFILE}`);
        }
    }, [user, advertisements]);

    const [searchParams] = useSearchParams();
    const currentTab: string = searchParams.get("tab") ?? "";

    const getContent = () => {
        switch (currentTab) {
            case UserProfileTabs.MY_ADS:
                return (<MyAdsView isLoading={loading} adsCount={counts} advertisements={advertisements} pageSize={20}
                                   statusValue={status} onStatusChange={setStatus} onLoadMore={handleLoadMore}
                                   nextPage={nextPage} hideActions
                />);
            case UserProfileTabs.PROFILE:
                return user ? (<CoreAttributes user={user}/>) : null;
            default:
                return null;
        }
    };

    return (
        <Spin wrapperClassName="administration-user-details" spinning={loading}>
            <Breadcrumb separator=">">
                <Breadcrumb.Item><Link to={`${Pages.ADMINISTRATION}?tab=users`}>Користувачі</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{user?.displayName}</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col span={12} className="administration-user-details__profile">
                    <Avatar/>
                    <h3>{user?.displayName}</h3>
                </Col>
                <Col span={12} className="administration-user-details__status">
                    {user && (<UserStatusRenderer user={user}/>)}
                    {user && (<SetStatusButton user={user} onChanged={loadUser}/>)}
                </Col>
            </Row>
            <Row className="administration-user-details__roles-select">
                <Col span={6}>
                    <label>Роль:</label>
                    <Select placeholder="Роль" value={role} options={rolesOptions} onChange={handleRoleChange}/>
                </Col>
            </Row>
            <Tabs items={items} onChange={handleTabChange} activeKey={currentTab}/>
            {getContent()}
        </Spin>
    );
};