import {FC, useEffect} from "react";
import {ReactComponent as Email} from "./resources/Email.svg";
import {ReactComponent as Logo} from "./resources/Logo.svg";
import {Button, ButtonType} from "../../../common/components/button/Button";
import {useSendEmailVerification, useSignOut} from "react-firebase-hooks/auth";
import {auth} from "../../../firebase/config";
import {useNavigate} from "react-router-dom";
import "./styles/emailVerification.scss";
import {useSessionStorage} from "../../../common/hooks/useSessionStorrage";

export const SEND_EMAIL_STORAGE_KEY = "TIME_TO_SEND_EMAIL";
export const TIME_TO_SEND_EMAIL = "60";

export const EmailVerification: FC = () => {
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useSessionStorage(SEND_EMAIL_STORAGE_KEY);
    const [sendEmailVerification, sending] = useSendEmailVerification(auth);
    const [signOut] = useSignOut(auth);

    useEffect(() => {
        if (Number(timeLeft) === 0) {
            setTimeLeft(null);
        }

        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft((Number(timeLeft) - 1).toString());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const handleClick = async () => {
        setTimeLeft(TIME_TO_SEND_EMAIL);
        await sendEmailVerification();
    }

    const navigateToLanding = async () => {
        await signOut();
        navigate("/");
    }

    return (
        <div className="emailVerification">
            <Email/>
            <Logo className="emailVerification__logo"/>
            <div className="emailVerification__description">
                На Вашу електронну адресу ми надіслали посилання для активації облікового запису. Просто перейдіть за посиланням у повідомленні
            </div>
            <div className="emailVerification__buttons">
                <Button type={ButtonType.SECONDARY} onClick={navigateToLanding}>На головну сторінку</Button>
                <Button disabled={sending || timeLeft !== null} onClick={handleClick}>Надіслати ще раз</Button>
            </div>
            {timeLeft && (`Повторно надіслати код через ${timeLeft}`)}
        </div>
    )
}
