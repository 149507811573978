import {FC} from "react";
import {ReactComponent as LikesIcon} from "./resources/LikesIcon.svg";
import {ReactComponent as MessagesIcon} from "./resources/MessagesIcon.svg";
import {ReactComponent as ViewsIcon} from "./resources/ViewsIcon.svg";
import "./styles/statistic.scss";

export const Statistic: FC = () => {
    return (
        <div className="my-ad-statistic">
            <div className="my-ad-statistic__icon">
                <ViewsIcon/><span className="my-ad-statistic__value">22</span>
            </div>
            <div className="my-ad-statistic__icon">
                <MessagesIcon/><span className="my-ad-statistic__value">22</span>
            </div>
            <div className="my-ad-statistic__icon">
                <LikesIcon/><span className="my-ad-statistic__value">22</span>
            </div>
        </div>
    )
}