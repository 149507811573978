import FetchCommand from "../../../../../../common/fetch/FetchCommand";
import {Conversation} from "../../../../../../types/integration/Conversation";
import {api} from "../../../../../../common/fetch/api";
import {ConversationDecorator} from "../../../../../../types/decorators/ConversationDecorator";

export default class AdNewMessageCommand extends FetchCommand<ConversationDecorator, ConversationDecorator> {
    private message: string;

    constructor(message: string) {
        super();
        this.message = message;
    }

    async execute(conversation: ConversationDecorator) {
        return api<Conversation>(`/api/conversations/${conversation.id}?adId=${conversation.adId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "text/html",
            },
            body: this.message
        }).then((result: Conversation) => {
            const decorator: ConversationDecorator = new ConversationDecorator(result);
            decorator.advertisement = conversation.advertisement;
            return decorator;
        })
    }
}