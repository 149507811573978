import React, {FC} from "react";
import {Carousel} from "antd";
import {ReactComponent as IconRight} from "./resoures/ArrowRight.svg";
import {ReactComponent as IconLeft} from "./resoures/ArrowLeft.svg";
import "./styles/adDetailsCarousel.scss";
import {useScreenWidth} from "../../../hooks/useScreenWidth";
import {isMobile} from "../../screen/Screen";

interface Props {
    images: string[];
}

export function NextArrow(props: any) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}>
            <div className="ad-details-carousel__slick-arrow">
                <IconRight/>
            </div>
        </div>
    );
}

export function PrevArrow(props: any) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            <div className="ad-details-carousel__slick-arrow">
                <IconLeft/>
            </div>
        </div>
    );
}

export const AdDetailsCarousel: FC<Props> = (props: Props) => {
    const width = useScreenWidth();
    const {images} = props;
    return (
        <Carousel className="ad-details-carousel"
                  lazyLoad="ondemand"
                  dots={{className: "ad-details-carousel-item__dots"}}
                  arrows={true}
                  nextArrow={!isMobile(width) ? <NextArrow/> : undefined}
                  prevArrow={!isMobile(width) ? <PrevArrow/> : undefined}>
            {images.map((item: string) => {
                return (
                    <div className="ad-details-carousel-item" key={item}>
                        <div className="ad-details-carousel-item__wrapper" key={item}>
                            <img src={item} key={item}/>
                        </div>
                    </div>
                );
            })}
        </Carousel>
    )
};