import FetchCommand from "../../../common/fetch/FetchCommand";
import {UploadFile} from "antd/lib/upload/interface";
import {ref, uploadBytes} from "firebase/storage";
import {storage, STORAGE_BUCKET} from "../../../firebase/config";
import {CurrentUserInfoDecorator} from "../../../common/commands/user/types/CurrentUserInfoDecorator";

export class ImageUploadCommand extends FetchCommand<string[], UploadFile[]> {
    private user: CurrentUserInfoDecorator;

    constructor(user: CurrentUserInfoDecorator) {
        super();
        this.user = user;
    }

    async execute(uploadedFiles: UploadFile[]) {
        const urls: string[] = [];
        for (const uploadedFile of uploadedFiles) {
            const timeStamp: string = Date.now().toString();
            const path = `images/${this.user.id}/${timeStamp}_${uploadedFile?.name}`;
            try {
                await uploadBytes(
                    ref(storage, path),
                    uploadedFile.originFileObj as Blob,
                    {cacheControl: "public, max-age=31536000, immutable"}
                );
                const url: string = `https://storage.googleapis.com/${STORAGE_BUCKET}/${path}`;
                urls.push(url);
            } catch (e) {
                console.log(e);
            }
        }

        return urls;
    }
}