import {FC} from "react";
import {Modal} from "antd";
import {auth} from "../../../../firebase/config";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../../../routing/Pages";
import {ReactComponent as CrossIcon} from "./resources/Cross.svg";
import {CoreAttributes} from "./coreAttributes/CoreAttributes";
import {deleteUser, signOut} from "firebase/auth";
import {useCurrentUser} from "../../../../common/commands/user/useCurrentUser";
import "./styles/profile.scss";
import {Button, ButtonType} from "../../../../common/components/button/Button";

export const Profile: FC = () => {
    const [user, setUpdatedUser] = useCurrentUser();
    const navigate = useNavigate();
    const handleClick = () => {
        Modal.confirm({
            title: "Ви дійсно бажаєте видалити обліковий запис?",
            onOk: async () => {
                if (user) {
                    try {
                        if (user.firebaseUser) {
                            await deleteUser(user.firebaseUser);
                            await signOut(auth);
                            setUpdatedUser(null);
                        }
                    } catch (e: any) {
                        if (e.code === "auth/requires-recent-login") {
                            Modal.info({title: "Увійдіть ще раз, щоб видалити обліковий запис."})
                            await signOut(auth);
                        }
                    }
                    navigate(Pages.LANDING);
                }
            }
        });
    };

    return (
        <>
            {user && <CoreAttributes user={user}/>}
            <div className="profile-controls">
                <Button type={ButtonType.DANGER} icon={<CrossIcon/>} onClick={handleClick}>
                    Видалити Профіль і Всі Дані
                </Button>
            </div>
        </>
    );
};