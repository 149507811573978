import React from "react";
import {AppRoutes} from "./routing/Routes";
import {Header} from "./common/components/header/Header";
import {Footer} from "./common/components/footer/Footer";
import {Spin} from "antd";
import {useCurrentUser} from "./common/commands/user/useCurrentUser";
import {ScrollToTop} from "./common/components/scrollToTop/ScrollToTop";
import "./styles/app.scss";
import {CookiesConfirmation} from "./content/cookies/CookiesConfirmation";
import {isMobile} from "./common/components/screen/Screen";
import {MobileUserLinks} from "./common/components/mobileUserLinks/MobileUserLinks";
import {useScreenWidth} from "./common/hooks/useScreenWidth";

function App() {
    const [, , isLoading] = useCurrentUser();
    const width: number = useScreenWidth();
    if (isLoading) {
        return (
            <div className="app-spin-container">
                <Spin size="large"/>
            </div>
        );
    }
    return (
        <div className="app">
            <CookiesConfirmation/>
            <ScrollToTop/>
            <Header/>
            <div className="app-content">
                <AppRoutes/>
            </div>
            {isMobile(width) && <MobileUserLinks/>}
            {!isMobile(width) && (<Footer/>)}
        </div>
    );
}

export default App;
