import React, {FC, useState} from "react";
import {ReactComponent as PhoneIcon} from "../resources/Phone.svg";
import {ReactComponent as ShowIcon} from "../resources/Show.svg";
import {Button, ButtonType} from "../../button/Button";
import "./styles/phoneNumberCollapse.scss";

interface Props {
    phoneNumber: string;
}

export const PhoneNumberCollapse: FC<Props> = (props: Props) => {
    const [isPhoneVisible, setPhoneVisible] = useState<boolean>(false);
    const {phoneNumber} = props;
    const hiddenPhoneNumber: string = phoneNumber.replace(/\d/g, "X");
    const handleClick = () => {
      setPhoneVisible(!isPhoneVisible);
    };
    return (
        <p className="phone-number-collapse">
            <a href={`tel:${phoneNumber}`}><PhoneIcon/>{isPhoneVisible ? phoneNumber : hiddenPhoneNumber}</a>
            {!isPhoneVisible && (<Button type={ButtonType.LINK} icon={<ShowIcon/>} onClick={handleClick}>Показати</Button>)}
        </p>
    );
};