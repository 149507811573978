import {CurrentUserInfo, UserStatus} from "./CurrentUserInfo";
import {isEmpty} from "lodash-es";
import {User} from "firebase/auth";
import {PublicUserInfo} from "./PublicUserInfo";
import {Shop} from "../../../../types/integration/Shop";

export enum UserRole {
    ADMIN = "ADMIN",
    MODERATOR = "MODERATOR",
    SHOP = "SHOP",
    PHYSICAL_PERSON = "PHYSICAL_PERSON"
}

export const BUSINESS_ACCOUNT_NAME_PROPS: string = "?isBusinessAccount=true";

export const hasNotConfirmedBusinessAccount = (user?: User | null) => {
    return (user?.photoURL ?? "").includes(BUSINESS_ACCOUNT_NAME_PROPS);
};

export class CurrentUserInfoDecorator {
    src: CurrentUserInfo | undefined;
    firebaseUser: User | undefined;
    shop: Shop | undefined;

    constructor(firebaseUser?: User, user?: CurrentUserInfo, shop?: Shop) {
        this.src = user;
        this.firebaseUser = firebaseUser;
        this.shop = shop;
    }

    get id() {
        return this.src?.id ?? this.firebaseUser?.uid;
    }

    get phoneNumber() {
        return this.src?.phoneNumber ?? this.firebaseUser?.phoneNumber;
    }

    get dbDisplayName() {
        return (this.src?.displayName || this.firebaseUser?.displayName) ?? "";
    }

    get displayName(): string {
        if (this.hasShop) {
            return this.shop?.info.name ?? "";
        }

        if (this.src?.displayName) {
            return this.src?.displayName;
        }

        if (this.firebaseUser?.displayName) {
            return this.firebaseUser?.displayName;
        }

        if (this.isClient) {
            return "Користувач"
        }

        if (this.isAdmin) {
            return "Адміністратор";
        }

        if (this.isModerator) {
            return "Модератор";
        }

        return ""
    }

    get photoUrl() {
        if (this.hasShop) {
            return this.shop?.info.logoUrl;
        }
        return this.firebaseUser?.photoURL;
    }

    hasFavorite(adId: string) {
        return (this.src?.favoriteAds ?? []).includes(adId);
    }

    get hasFavorites(): boolean {
        return !isEmpty(this.src?.favoriteAds);
    }

    get numberOfFavorites(): number {
        return this.src?.favoriteAds?.length ?? 0;
    }

    get isAdmin(): boolean {
        return this.src?.customClaims?.roles?.includes(UserRole.ADMIN)
    }

    get isModerator(): boolean {
        return this.src?.customClaims?.roles?.includes(UserRole.MODERATOR)
    }

    get isClient(): boolean {
        return !this.isAdmin && !this.isModerator;
    }

    get isNotClient(): boolean {
        return this.isModerator || this.isAdmin;
    }

    get favouriteAds(): string[] {
        return this.src?.favoriteAds ?? [];
    }

    get email(): string {
        return this.src?.email ?? this.firebaseUser?.email ?? "";
    }

    get publicInfo(): PublicUserInfo {
        return {
            id: this.id ?? "",
            displayName: this.displayName,
            photoURL: this.firebaseUser?.photoURL ?? "",
            creationTime: this.firebaseUser?.metadata.creationTime ?? ""
        }
    }

    get isDisabled(): boolean {
        return !isEmpty(this.src?.deletedOn) || [UserStatus.DELETED, UserStatus.DISABLED].includes(this.src?.status as UserStatus);
    }

    get status() {
        if (this.isDisabled) {
            return "Заблокований"
        }

        return "Активний";
    }

    get role() {
        return this.src?.customClaims?.roles?.[0] ?? UserRole.PHYSICAL_PERSON;
    }

    get displayRoles() {
        return this.src?.customClaims?.roles?.map((item: UserRole) => {
            switch (item) {
                case UserRole.ADMIN:
                    return "Адміністратор";
                case UserRole.MODERATOR:
                    return "Модератор";
                case UserRole.SHOP:
                    return "Магазин";
            }

            return "Фізична Особа";
        }).join(",");
    }

    get isNotConfirmedBusinessAccount(): boolean {
        return hasNotConfirmedBusinessAccount(this.firebaseUser);
    }

    get hasShop(): boolean {
        return !isEmpty(this.shop);
    }
}