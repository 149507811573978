import FetchCommand from "../../fetch/FetchCommand";
import {api} from "../../fetch/api";
import {CurrentUserInfo} from "./types/CurrentUserInfo";
import {CurrentUserInfoDecorator} from "./types/CurrentUserInfoDecorator";
import {User} from "firebase/auth";
import {Shop} from "../../../types/integration/Shop";
import GetShopCommand from "./types/GetShopCommand";

type CallBack = (user: CurrentUserInfoDecorator | null) => void;

export class GetCurrentUser extends FetchCommand<void, User> {
    private static _user: CurrentUserInfoDecorator | null = null;

    static set user(user: CurrentUserInfoDecorator | null) {
        GetCurrentUser._user = user;
        GetCurrentUser.subscribers.forEach((callback: CallBack) => {
            callback(user);
        })
    }

    static get user(): CurrentUserInfoDecorator | null {
        return GetCurrentUser._user;
    }

    static subscribers: Set<CallBack> = new Set<CallBack>();

    static promise: Promise<void> | null = null;

    static subscribe(callback: CallBack) {
        GetCurrentUser.subscribers.add(callback);
    }

    static unsubscribe(callback: CallBack) {
        GetCurrentUser.subscribers.delete(callback);
    }

    async getShop() {
        const shops: Shop[] | null = await new GetShopCommand().execute();
        if (shops) {
            return shops[0];
        }
        return undefined;
    }

    async execute(user: User): Promise<void> {
        if (!GetCurrentUser._user && !GetCurrentUser.promise) {
            const shop: Shop | undefined = await this.getShop();
            GetCurrentUser.promise = api<CurrentUserInfo>(`/api/users/${user.uid}`).then((result: CurrentUserInfo) => {
                GetCurrentUser.user = new CurrentUserInfoDecorator(user, result, shop);
            }).catch(() => {
                GetCurrentUser.user = new CurrentUserInfoDecorator(user, undefined, shop);
                return Promise.reject();
            }).finally(() => {
                GetCurrentUser.promise = null;
            });
            return GetCurrentUser.promise;
        }
        return Promise.resolve();
    }
}