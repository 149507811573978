import {FC, ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../../../../routing/Pages";
import {IconButton} from "../../../iconButton/IconButton";
import {ReactComponent as MyAds} from "./resources/MyAds.svg";
import {UserProfileTabs} from "../../../../../content/userProfilePage/UserProfileTabs";

interface Props {
    badge?: ReactNode;
}

export const MyAdsButton: FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`${Pages.USER_PROFILE}?tab=${UserProfileTabs.MY_ADS}`);
    };
    return (
        <IconButton onClick={handleClick}>
            <MyAds/>
            Мої оголошення
        </IconButton>
    );
};