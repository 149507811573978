import {FC, useEffect} from "react";
import {GoogleAuthProvider, signInWithCredential, signInWithPopup} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import {Button, ButtonType} from "../../../../common/components/button/Button";
import {ReactComponent as Google} from "./resources/Google.svg";
import {auth, CLIENT_ID} from "../../../../firebase/config";

const provider = new GoogleAuthProvider();

declare var google: any

export const GoogleLogin: FC = () => {
    const [user] = useAuthState(auth);
    const onSignInSuccess = () => {
    };

    const onSignInError = () => {
    };

    const handleCredentialResponse = (response: { credential: string }) => {
        const credential = GoogleAuthProvider.credential(response.credential);
        signInWithCredential(auth, credential).then(onSignInSuccess).catch(onSignInError);
    }

    const showOneTapPrompt = () => {
        if (google) {
            google.accounts.id.initialize({
                client_id: CLIENT_ID,
                cancel_on_tap_outside: true,
                context: "signin",
                callback: handleCredentialResponse
            });
            google.accounts.id.prompt();
        }
    };

    const addGoogleScript = () => {
        const googleScript = document.createElement("script");
        googleScript.src = "https://accounts.google.com/gsi/client";
        googleScript.async = true;
        googleScript.defer = true;
        document.head.appendChild(googleScript);
        googleScript.onload = () => {
            showOneTapPrompt();
        }
    };

    useEffect(() => {
        if (!user && CLIENT_ID) {
            addGoogleScript();
        }
    }, []);

    const onClick = () => {
        if (!user) {
            signInWithPopup(auth, provider)
                .then(onSignInSuccess)
                .catch(onSignInError);
        }
    };

    return (<Button type={ButtonType.LINK_OUTLINE} onClick={onClick} icon={<Google/>}>Вхід із Google</Button>);
};