import React, {FC, useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import AdvertisementDecorator from "../../types/decorators/AdvertisementDecorator";
import {Spin} from "antd";
import {Pages} from "../../routing/Pages";
import {NewAdPage} from "../newAdPage/NewAdPage";
import {useCatalog} from "../../common/commands/catalog/useCatalog";
import GetAddInfoCommand from "../adDetailsPage/commands/GetAddInfoCommand";

export const EditPage: FC = () => {
    const [catalog] = useCatalog();
    const {adId} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [advertisement, setAdvertisement] = useState<AdvertisementDecorator>();
    useEffect(() => {
        new GetAddInfoCommand().execute(adId ?? "").then(setAdvertisement).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading || !catalog) {
        return (<Spin size="large"/>);
    }
    if (!advertisement) {
        return (<Navigate to={Pages.LANDING}/>);
    }
    const {images, ...details} = advertisement.editDetails(catalog);
    return (
        <NewAdPage adId={advertisement.id} details={details} images={images as string[]}/>
    );
}