import FetchCommand from "../../../../../../common/fetch/FetchCommand";
import {ConversationDecorator} from "../../../../../../types/decorators/ConversationDecorator";
import {api} from "../../../../../../common/fetch/api";
import {Conversation} from "../../../../../../types/integration/Conversation";

export default class MarkAsReadConversationCommand extends FetchCommand<ConversationDecorator, ConversationDecorator> {
    async execute(payload: ConversationDecorator) {
        return api<Conversation>(`/api/conversations/${payload.id}/mark-as-read`, {
            method: "PUT"
        })
            .then((result: Conversation) => {
                const conversation: ConversationDecorator = new ConversationDecorator(result);
                conversation.advertisement = payload.advertisement;
                return conversation;
            })
    }
}