import {Status} from "../../content/adDetailsPage/commands/types/Advertisement";

export enum PublicSort {
    EXPIRATION_DESC = "EXPIRATION_DESC",
    PRICE_ASC = "PRICE_ASC",
    PRICE_DESC = "PRICE_DESC"
}

export enum AuthorSort {
    EXPIRATION_ASC = "EXPIRATION_ASC",
    EXPIRATION_DESC = "EXPIRATION_DESC"
}

export interface FindPriorityDto {
    tags?: Record<string, string[]>[];
    limit?: number;
    status?: Status;
}

export interface FindGeneralDto extends FindPriorityDto {
    priceTo?: number;
    priceFrom?: number;
    searchPhrase?: string;
    sort: PublicSort;
}

export interface FindForAuthorDto extends FindPriorityDto {
    userId: string;
    status: Status;
    sort: AuthorSort;
}

export interface FindForModerationDto {
    limit?: number;
    status?: Status;
}