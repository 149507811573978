import {FC} from "react";
import {IconButton} from "../../iconButton/IconButton";
import {ReactComponent as LikesIcon} from "./resources/Likes.svg";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../../../routing/Pages";
import {UserProfileTabs} from "../../../../content/userProfilePage/UserProfileTabs";
import {useCurrentUser} from "../../../commands/user/useCurrentUser";
import {Badge} from "antd";

interface Props {
    label?: string;
}

export const SavedAds: FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const [currentUserInfo] = useCurrentUser();

    const handleClick = () => {
        navigate(`${Pages.USER_PROFILE}?tab=${UserProfileTabs.FAVOURITES}`);
    };

    return (
        <IconButton onClick={handleClick}>
            <Badge count={currentUserInfo?.numberOfFavorites} overflowCount={99} color="#0C81DF" offset={[-16, 20]}>
                <LikesIcon/>
            </Badge>
            {props.label}
        </IconButton>
    );
};