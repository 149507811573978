import {FC} from "react";
import {RemovableImage} from "./removableImage/RemovableImage";

interface Props {
    urls: string[];
    onChange: (urls: string[]) => void;
    disabled?: boolean;
}

export const UploadedImages: FC<Props> = (props: Props) => {
    const {urls, onChange, disabled} = props;
    const handleClick = (url: string) => {
        onChange?.(urls.filter((target: string) => target !== url));
    }
    return <>
        {urls.map((url: string) => <RemovableImage url={url} onClick={handleClick} disabled={disabled}/>)}
    </>
}