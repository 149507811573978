import FetchCommand from "../../fetch/FetchCommand";
import {api} from "../../fetch/api";

export default class DeleteAdsCommand extends FetchCommand<void, string[]> {

    async execute(adIds: string[]) {
        return api<void>(`/api/a/${adIds.join(",")}/delete`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
}