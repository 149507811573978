import {CurrentUserInfo, UserStatus} from "../../../commands/user/types/CurrentUserInfo";
import {api} from "../../../fetch/api";
import FetchCommand from "../../../fetch/FetchCommand";
import {CurrentUserInfoDecorator} from "../../../commands/user/types/CurrentUserInfoDecorator";


export default class SetUserStatusCommand extends FetchCommand<CurrentUserInfoDecorator, string> {
    status?: UserStatus;

    constructor(status?: UserStatus) {
        super();
        this.status = status;
    }

    async execute(id: string) {
        let url: string = `/api/users/${id}/status`;
        if (this.status) {
            url = `${url}?status=${this.status}`;
        }
        return api<CurrentUserInfo>(url, {
            method: "PUT"
        }).then((result: CurrentUserInfo) => new CurrentUserInfoDecorator(undefined, result));
    }
}