import FetchCommand from "../../../../fetch/FetchCommand";
import {api} from "../../../../fetch/api";

export type UnreadConversationsCount = {
    mine: number;
    other: number;
    total: number;

}

export default class GetUnreadConversationsCount extends FetchCommand<UnreadConversationsCount, undefined> {
    async execute() {
        const mine: number = await api<number>("/api/conversations/unread/count?initiator=false");
        const other: number = await api<number>("/api/conversations/unread/count?initiator=true");
        return {mine, other, total: mine + other}
    }
}