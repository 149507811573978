export interface PublicPartnerPageInfo {
    id: string;
    name?: string;
    description?: string;
    webUrl?: string;
    email?: string;
    phone?: string;
    viber?: string;
    telegram?: string;
    facebook?: string;
    instagram?: string;
    address?: string;
    images?: string[];
}

export enum PartnerStatus {
    ACTIVE = "ACTIVE",
    NOT_ACTIVE = "NOT_ACTIVE"
}

export interface PartnerPage extends PublicPartnerPageInfo {
    status: PartnerStatus;
    createdOn: number;
    modifiedOn: number;
    createBy: string;
}