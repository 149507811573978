import {ChangeEvent, FC} from "react";
import {Input} from "antd";
import {RuleObject, StoreValue} from "rc-field-form/lib/interface";
import "./styles/phoneNumberInput.scss";

interface Props {
    value?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
}

export const phoneNumberValidator = (rule: RuleObject, value: StoreValue) => {
    if (value.length < 13) {
        return Promise.reject("Будь ласка, додайте номер телефону")
    }
    return Promise.resolve(null);
};

export const PhoneNumberInput: FC<Props> = (props: Props) => {
    const {value, onChange, disabled} = props;
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        if (/^\d+$/g.test(value) || value === "") {
            onChange?.(`+380${value}`);
        }
    };

    return (
        <Input
            className="phone-number-input"
            placeholder="Телефон"
            addonBefore="+380"
            value={value?.replace("+380", "")}
            onChange={handleChange}
            disabled={disabled}
            minLength={9}
            maxLength={9}
        />
    )
};