import {FC} from "react";
import {ReactComponent as EditIcon} from "./resources/EditIcon.svg";
import {generatePath, useNavigate} from "react-router-dom";
import {Pages} from "../../../../../../routing/Pages";
import {Button, ButtonType} from "../../../../../../common/components/button/Button";

interface Props {
    adId: string;
}

export const EditButton: FC<Props> = (props: Props) => {
    const {adId} = props;
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(generatePath(Pages.EDIT_AD, {adId}));
    };

    return (<Button type={ButtonType.LINK} icon={<EditIcon/>} onClick={handleClick}>Редагувати</Button>);
};