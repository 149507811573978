import {cloneDeep} from "lodash-es";
import * as React from "react";
import {ReactNode} from "react";

export interface TreeValue {
    level?: number;
    value?: string;
    child?: TreeValue;
}

export interface TreeOption {
    label?: React.ReactNode;
    value?: string | number | null;
    children?: TreeOption[];
}


export class SimpleTree {
    private _tree: TreeValue;

    static treeToArray(tree: TreeValue): string[] {
        const result: string[] = [];

        const walk = (val: TreeValue) => {
            if (val.value) {
                result.push(val.value)
            }

            if (val.child) {
                walk(val.child);
            }
        };

        walk(tree);
        return result;
    }

    static arrayToTree(array: string[]): TreeValue {
        const result: SimpleTree = new SimpleTree();
        array.forEach((value: string) => {
            result.addValue({value});
        });
        return result.tree;
    }

    constructor(value: TreeValue = {}) {
        this._tree = value;
        this.addLevels();
    }

    addValue(leave: TreeValue) {
        const addValue = (leave: TreeValue, tree: TreeValue) => {
            if (!tree.value) {
                tree.value = leave.value;
                tree.child = {};
            } else {
                addValue(leave, tree.child ?? {});
            }
        };
        addValue(leave, this._tree);
        this.addLevels();
    }

    hasValue(level: number) {
        const hasValue = (l: number, tree?: TreeValue): boolean => {
            if (!tree) {
                return false;
            }

            if (tree.level === l) {
                return !!tree.value;
            }

            return hasValue(l, tree.child);
        };
        return hasValue(level, this._tree);
    }

    updateValue(level: number, value: TreeValue) {
        const updateValue = (l: number, newValue: TreeValue, tree: TreeValue) => {
            if (tree.level === l) {
                tree.value = newValue.value;
                tree.child = newValue.child ?? {level: l + 1};
            } else {
                updateValue(l, newValue, tree.child ?? {});
            }
        };
        updateValue(level, value, this._tree);
        this.addLevels();
    }

    deleteValue(level: number) {
        const deleteValue = (l: number, tree: TreeValue) => {
            if (tree.level === l) {
                delete tree.value;
                delete tree.level;
                delete tree.child;
            } else {
                deleteValue(l, tree.child ?? {});
            }
        };
        deleteValue(level, this._tree);
        this.addLevels();
    }

    private addLevels() {
        let level = 0;
        const addLevel = (tree: TreeValue) => {
            tree.level = level;
            level++;
            if (tree.child) {
                addLevel(tree.child);
            }
        };
        addLevel(this._tree);
    }

    clear() {
        this._tree = {};
    }

    get tree(): TreeValue {
        return cloneDeep(this._tree);
    }
}

export const addLevelsToOptions = (options: TreeOption[], level: number) => {
    return options.map((item: TreeOption) => ({...item, value: `${item.value}_${level}`}));
};

export const findOptions = (options: TreeOption[], tree?: TreeValue) => {
    let result: TreeOption[] = [];
    if (!tree) return result;

    function iter(a: TreeOption) {
        const value: string = `${tree?.value}_${tree?.level}`;
        if (a.value === value) {
            if (a.children) {
                result = addLevelsToOptions(a.children, (tree?.level ?? 0) + 1);
            }
            return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
    }

    options.some(iter);

    return result
};

export const parseOption = (option: TreeOption) => {
    const parts: string[] = (option.value ?? "").toString().split("_");
    return {
        ...option,
        value: parts[0], level: Number(parts[1] ?? 0)
    }
}