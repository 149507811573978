import {FC} from "react";
import {ReactComponent as Guns} from "./resources/Guns.svg";
import {Button, ButtonType} from "../button/Button";
import "./styles/errorScreen.scss";

export const ErrorScreen: FC = () => {
    const handleClick = () => {
        window.location.replace("/");
    }
    return (
        <div className="errorScreen">
            <Guns/>
            <div className="errorScreen__content">
                <p className={"errorScreen__title"}>Йой...</p>
                <div>
                    <p>Здається щось пішло не так. Але ми працюємо над цим.</p>
                    <p>Спробуйте ще раз</p>
                </div>
                <Button onClick={handleClick} type={ButtonType.PRIMARY}>Перейти на головну сторінку</Button>
            </div>
        </div>
    );
}
