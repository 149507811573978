import {Col, Row} from "antd";
import {FC} from "react";
import {PartnerPage, PartnerStatus} from "../../../../../../types/integration/Partners";
import moment from "moment";
import {DATE_FORMAT} from "../../../../../../types/decorators/AdvertisementDecorator";
import {Button, ButtonType} from "../../../../../../common/components/button/Button";
import {ReactComponent as DeleteIcon} from "./resources/DeleteIcon.svg";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../../../../../routing/Pages";
import "./styles/partnersList.scss";

interface Props {
    partners: PartnerPage[];
    onDelete: (partner: PartnerPage) => void;
    onRestore: (partner: PartnerPage) => void;
}

export const PartnersList: FC<Props> = (props: Props) => {
    const {partners, onDelete, onRestore} = props;
    const navigate = useNavigate();

    return (
        <ul className="partners-list">
            {partners.map((item: PartnerPage) => {
                const {images = [], name, description, createdOn, id, status} = item;
                const [image] = images;
                const daysSincePublish: number = moment().diff(moment(createdOn), "days");
                const handleDeleteClick = () => {
                    onDelete(item);
                };
                const handleRestoreClick = () => {
                    onRestore(item);
                };
                const goToPartnerDetailsPage = () => {
                    navigate(Pages.CREATE_PARTNER, {state: item});
                };
                return (
                    <li className="partners-list__item" key={id}>
                        <Row>
                            <Col span="4" className="partners-list__image" onClick={goToPartnerDetailsPage}>
                                {image && (
                                    <img src={image} alt="logo"/>
                                )}
                            </Col>
                            <Col span="8" className="partners-list__info" onClick={goToPartnerDetailsPage}>
                                <h4>{name}</h4>
                                <p>{description}</p>
                            </Col>
                            <Col span="8" className="partners-list__dates" onClick={goToPartnerDetailsPage}>
                                <p>
                                    <span>Опубліковано </span><strong>{moment(createdOn).format(DATE_FORMAT)}</strong>
                                </p>
                                <p><span>В показі </span><strong>{daysSincePublish || 1} днів</strong></p>

                            </Col>
                            <Col span="4" className="partners-list__controls">
                                {status === PartnerStatus.ACTIVE
                                    ? (<Button type={ButtonType.LINK} icon={<DeleteIcon/>} onClick={handleDeleteClick}>Видалити
                                        з показу</Button>)
                                    : (<Button type={ButtonType.LINK} onClick={handleRestoreClick}>Повернути
                                        до показу</Button>)}
                            </Col>
                        </Row>
                    </li>
                );
            })}

        </ul>
    );
}
