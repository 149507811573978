import {FC, SyntheticEvent, useState} from "react";
import {Modal} from "antd";
import {ReactComponent as Decline} from "../resources/Decline.svg";
import TextArea from "antd/es/input/TextArea";
import {isEmpty} from "lodash-es";
import {Button, ButtonType} from "../../../common/components/button/Button";
import "./styles/declineAction.scss";

interface Props {
    disabled: boolean;
    onDecline: (comment: string) => void;
}

export const DeclineAction: FC<Props> = (props: Props) => {
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [declineReason, setDeclineReason] = useState<string>("");
    const {disabled, onDecline} = props;
    const showModal = () => {
        setModalVisible(true);
    };
    const hideModal = () => {
        setModalVisible(false);
        setDeclineReason("");
    };
    const handleDeclineReasonChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        setDeclineReason(event.currentTarget.value);
    };
    const handleDecline = () => {
        onDecline(declineReason);
        hideModal();
    };

    return (
        <>
            <Button
                type={ButtonType.DANGER}
                icon={<Decline/>}
                disabled={disabled}
                onClick={showModal}
            >
                Відхилити
            </Button>
            <Modal className="moderation-page-decline__modal"
                   title="Відхилити Оголошення"
                   width={640}
                   open={isModalVisible}
                   onCancel={hideModal}
                   footer={
                       (
                           <div className="moderation-page-decline__modal-footer">
                               <Button type={ButtonType.OUTLINE} onClick={hideModal}>Назад</Button>
                               <Button
                                   type={ButtonType.DANGER}
                                   onClick={handleDecline}
                                   icon={<Decline/>}
                                   disabled={isEmpty(declineReason)}
                               >
                                   Відхилити Оголошення
                               </Button>
                           </div>
                       )
                   }
            >
                <div className="moderation-page-decline__modal-content">
                    <p>Причина Відхилення</p>
                    <TextArea value={declineReason} onChange={handleDeclineReasonChange} showCount maxLength={5000}
                              rows={5}/>
                </div>
            </Modal>
        </>
    );
};