import {FC, ReactNode, useState} from "react";
import {Checkbox, Col, Row, Segmented, Spin} from "antd";
import {EmptyText} from "../emptyText/EmptyText";
import {isEmpty} from "lodash-es";
import {Button, ButtonType} from "../button/Button";
import {ReactComponent as DeleteIcon} from "../../../content/userProfilePage/content/myAdCard/buttons/deleteButton/resources/DeleteIcon.svg";
import {Status} from "../../../content/adDetailsPage/commands/types/Advertisement";
import {ReactComponent as PromoteIcon} from "../../../content/userProfilePage/content/myAdCard/buttons/promoteButton/resources/PromoteIcon.svg";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";
import {generatePath, useNavigate} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {MyAdCard} from "../../../content/userProfilePage/content/myAdCard/MyAdCard";
import {getPickList, StaticDataItem} from "../../staticData/StaticData";
import {SegmentedValue} from "rc-segmented";
import {isMobile} from "../screen/Screen";
import {useScreenWidth} from "../../hooks/useScreenWidth";
import "./styles/myAdsView.scss";
import {MobileDrawer} from "../mobileDrawer/MobileDrawer";
import {ProfileMobileHeader} from "../../../content/userProfilePage/content/profileMobileHeader/ProfileMobileHeader";
import {ReactComponent as ChevronLeft} from "./resources/chevronLeft.svg";
import {ReactComponent as Dots} from "./resources/Dots.svg";

interface Props {
    adsCount: Record<Status, number>;
    advertisements: AdvertisementDecorator[];
    isLoading: boolean;
    statusValue: Status;
    onStatusChange: (value: Status) => void;
    onLoadMore: () => void;
    onDelete?: () => void;
    selectedAds?: string[];
    onSelectAd?: (adIds: string[]) => void;
    nextPage?: string;
    pageSize?: number;
    onArchive?: () => void;
    onRefresh?: () => void;
    onPromoteSelected?: () => void;
    hideActions?: boolean;
}

export const MyAdsView: FC<Props> = (props: Props) => {
    const {
        adsCount,
        isLoading,
        onStatusChange,
        advertisements,
        selectedAds = [],
        onSelectAd,
        onDelete,
        nextPage,
        pageSize = 20,
        onLoadMore,
        onArchive,
        onRefresh,
        statusValue,
        onPromoteSelected,
        hideActions
    } = props;
    const navigate = useNavigate();
    const width = useScreenWidth();
    const [isDrawerVisible, setDrawerVisible] = useState(false);
    const options = getPickList("adStatuses")
        .map((item: StaticDataItem) => {
            const count = adsCount[item.value as Status];
            return {...item, label: `${item.label} (${count})`, disabled: count === 0}
        });

    const hasNoAds = Object.values(adsCount).every((value: number) => value === 0);

    const handleSegmentedChange = (value: SegmentedValue) => {
        onStatusChange(value as Status);
    };

    const selectAll = (event: CheckboxChangeEvent) => {
        if (event.target.checked) {
            onSelectAd?.(advertisements.map((item: AdvertisementDecorator) => item.id));
        } else {
            onSelectAd?.([]);
        }
    };
    const handleSelect = (itemId: string, selected: boolean) => {
        if (selected) {
            onSelectAd?.([...selectedAds, itemId]);
        } else {
            onSelectAd?.(selectedAds.filter((item: string) => item !== itemId));
        }
    };
    const showDrawer = () => {
        if (!isEmpty(selectedAds)) {
            setDrawerVisible(true);
        }
    };
    const hideDrawer = () => {
        setDrawerVisible(false);
    };
    const buttons: ReactNode = (
        <>
            {!hideActions && onDelete && (
                <Button type={ButtonType.LINK_OUTLINE} icon={<DeleteIcon/>}
                        disabled={isEmpty(selectedAds)}
                        onClick={onDelete}
                >
                    {statusValue === Status.ARCHIVED ? "Видалили Обрані Оголошення" : "Зняти Обрані Оголошення"}
                </Button>
            )}
            {!hideActions && onPromoteSelected && (
                <Button type={ButtonType.LINK_OUTLINE} icon={<PromoteIcon/>}
                        disabled={isEmpty(selectedAds)}>Підняти Обрані
                    Оголошення</Button>
            )}
        </>
    );
    const deselectAll = () => {
      hideDrawer();
        onSelectAd?.([]);
    };

    const isItemSelected = (id: string) => selectedAds.includes(id);

    const isSelectable = !!onSelectAd;

    return (
        <Spin spinning={isLoading}>
            {(!isEmpty(selectedAds) && isMobile(width)) && (
                <ProfileMobileHeader>
                    <div className="my-ads__mobile-header">
                        <button onClick={deselectAll}>
                            <ChevronLeft/>
                            Обрано: {selectedAds.length}
                        </button>
                        <button
                            onClick={showDrawer}
                            disabled={isEmpty(selectedAds)}
                        >
                            Дії
                            <Dots/>
                        </button>
                    </div>
                </ProfileMobileHeader>
            )}
            <div className="my-ads">
                {hasNoAds && (
                    <EmptyText text="У Вас ще немає оголошень"/>
                )}
                {!hasNoAds && (
                    <>
                        <Row>
                            <Col span="24" className="my-ads__filters">
                                <Segmented options={options} value={statusValue} onChange={handleSegmentedChange}/>
                            </Col>
                        </Row>
                        {!isEmpty(advertisements) && (
                            <div className="my-ads__list-header">
                                {isSelectable && (
                                    <div className="my-ads__select">
                                        <Checkbox
                                            onChange={selectAll}
                                            checked={selectedAds.length === advertisements.length}
                                            disabled={isEmpty(advertisements)}
                                        />
                                    </div>
                                )}
                                <Row>
                                    <Col span={isMobile(width) ? "24" : "7"} className="my-ads__trigger-mobile-actions">
                                        {!hideActions && <>Обрано оголошень: {selectedAds.length} /
                                            Знайдено: {adsCount[statusValue]}</>}
                                    </Col>
                                    {!isMobile(width) && (<Col span="4">Дата публікації</Col>)}
                                    {!isMobile(width) && (
                                        <Col span="13" className="my-ads__list-header-controls">
                                            {buttons}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        )}
                        {
                            isEmpty(advertisements)
                                ? (<EmptyText text="У Вас ще немає оголошень"/>)
                                : (
                                    <ul className="my-ads__list">
                                        {advertisements.map((item: AdvertisementDecorator) => {
                                            const handleClick = item.status === Status.ACTIVE ?
                                                () => {
                                                    const adDetailsPath: string = generatePath(Pages.AD_PUBLIC_DETAILS, {adId: item.id});
                                                    navigate(adDetailsPath);
                                                } : undefined;

                                            return (
                                                <li className="my-ads__list-item" key={item.id}>
                                                    {
                                                        isSelectable && (
                                                            <div className="my-ads__select">
                                                                <Checkbox
                                                                    checked={isItemSelected(item.id)}
                                                                    onChange={(e: CheckboxChangeEvent) => handleSelect(item.id, e.target.checked)}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    <MyAdCard advertisement={item} onArchive={onArchive}
                                                              onRefresh={onRefresh} onClick={handleClick}
                                                              hideActions={hideActions}/>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                )
                        }
                        {nextPage && (
                            <Button type={ButtonType.LINK} onClick={onLoadMore} className="my-ads__load-more">Показати
                                ще {pageSize} оголошень</Button>
                        )}
                    </>
                )}
            </div>
            <MobileDrawer visible={isDrawerVisible} onClose={hideDrawer}>
                <div className="my-ads__mobile-actions">
                    {buttons}
                </div>
            </MobileDrawer>
        </Spin>
    );
};