import {Input} from 'antd';
import React, {useState} from 'react';

interface NumericInputProps {
    style?: React.CSSProperties;
    placeholder?: string;
    onChange?: (value: string) => void;
    value?: string | number;
}

export const NumericInput = (props: NumericInputProps) => {
    const [value, setValue] = useState("");
    const {onChange} = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value: inputValue} = e.target;
        const reg = /^\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '') {
            setValue(inputValue);
            onChange?.(inputValue);
        }
    };

    // '.' at the end or only '-' in the input box.
    const handleBlur = () => {
        let valueTemp = value;
        if (value.charAt(value.length - 1) === '.' || value === '-') {
            valueTemp = value.slice(0, -1);
        }
        valueTemp = valueTemp.replace(/0*(\d+)/, '$1');
        setValue(valueTemp);
        onChange?.(valueTemp);
    };

    return (
        <Input
            {...props}
            allowClear={true}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={10}
        />
    );
};