import {FC, useEffect, useState} from "react";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import "./styles/mobileUSerLinks.scss";
import {useLocation} from "react-router-dom";
import {SavedAds} from "../header/savedAds/SavedAds";
import {Messages} from "../header/messages/Messages";
import {UserProfileMenu} from "../header/userProfileMenu/UserProfileMenu";
import {CreateNewAdButton} from "../header/createNewAd/CreateNewAdButton";
import {
    isCreateNewAdButtonVisible,
    isMessageButtonVisible,
    isProfileButtonVisible,
    isSearchVisible
} from "../../utils/LocationUtils";
import {MobileDetailedSearch} from "./mobileDetailedSearch/MobileDetailedSearch";
import {debounce} from "lodash-es";
import {useScreenWidth} from "../../hooks/useScreenWidth";

export const MobileUserLinks: FC = () => {
    const [isScrolling, setScrolling] = useState(false);
    const width: number = useScreenWidth();
    const [user] = useCurrentUser();
    const location = useLocation();


    useEffect(() => {
        const show = debounce(() => {
            setScrolling(false);
        }, 1000);
        let lastScrollTop: number = 0;
        const handleScroll = (event: any) => {
            const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollTop > lastScrollTop) {
                setScrolling(true);
                show();
            }
            lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <div className={`mobile-user-link ${isScrolling ? "mobile-user-link--scrolling" : ""}`}>
            {isSearchVisible(location.pathname, user, width) && (<MobileDetailedSearch/>)}
            {isMessageButtonVisible(location.pathname, user) && (<SavedAds/>)}
            {isCreateNewAdButtonVisible(location.pathname, user) && (<CreateNewAdButton/>)}
            {isMessageButtonVisible(location.pathname, user) && (<Messages/>)}
            {isProfileButtonVisible(location.pathname) && (<UserProfileMenu/>)}
        </div>
    );
}