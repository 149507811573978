import GetCatalogCommand from "./GetCatalogCommand";
import {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {CatalogDecorator} from "./CatalogDecorator";

export function useCatalog(): [CatalogDecorator | null, boolean, Dispatch<SetStateAction<CatalogDecorator | null>>] {
    const [catalog, setCatalog] = useState<CatalogDecorator | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const cancelRequest = useRef<boolean>(false)
    useEffect(() => {
        cancelRequest.current = false
        const getCatalog = async () => {
            if (cancelRequest.current) return;
            setLoading(true);
            const catalog: CatalogDecorator = GetCatalogCommand.catalog ?? await new GetCatalogCommand().execute()
                .finally(() => {
                    setLoading(false);
                });

            setLoading(false);
            setCatalog(catalog);
        }

        getCatalog().catch(() => {
            if (cancelRequest.current) return;
            setCatalog(null);
        });

        return () => {
            cancelRequest.current = true;
        }
    }, []);

    return [catalog, isLoading, setCatalog];
}