import FetchCommand from "../../fetch/FetchCommand";
import {api} from "../../fetch/api";

export default class UnArchiveAdCommand extends FetchCommand<void, string[]> {

    async execute(adIds: string[]) {
        return api<void>(`/api/a/${adIds.join(",")}/unarchive`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
}