import FetchCommand from "../../../fetch/FetchCommand";
import {PublicPartnerPageInfo} from "../../../../types/integration/Partners";
import {api} from "../../../fetch/api";

export default class GetPartnersPublicInfoCommand extends FetchCommand<PublicPartnerPageInfo[], string[] | undefined> {
    async execute(ids?: string[]) {
        let url: string;
        if (ids) {
            url = `/api/partners/public-info?ids=${ids}`;
        } else {
            url = "/api/partners/public-info";
        }
        return api<PublicPartnerPageInfo[]>(url);
    }
}