import React, {FC, useEffect, useState} from "react";
import {ReactComponent as Email} from "./resources/Email.svg";
import {ReactComponent as Logo} from "./resources/Logo.svg";
import {Button, ButtonType} from "../../../common/components/button/Button";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import {Spin} from "antd";
import {applyActionCode} from "firebase/auth";
import {auth} from "../../../firebase/config";
import {getIdToken} from "@firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import "./styles/emailConfirmation.scss"
import {
    CurrentUserInfoDecorator,
    hasNotConfirmedBusinessAccount
} from "../../../common/commands/user/types/CurrentUserInfoDecorator";
import {useCurrentUser} from "../../../common/commands/user/useCurrentUser";

export const EmailConfirmation: FC = () => {
    const [firebaseUser] = useAuthState(auth);
    const [user, setUpdatedUser] = useCurrentUser();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const oobCode: string = searchParams.get("oobCode") ?? "";
    const isBusinessAccount: boolean = hasNotConfirmedBusinessAccount(firebaseUser);

    const handleClick = async () => {
        navigate(Pages.LANDING);
    };

    const confirmEmail = async () => {
        try {
            await applyActionCode(auth, oobCode);
            if (firebaseUser) {
                await getIdToken(firebaseUser!, true);
                setUpdatedUser(new CurrentUserInfoDecorator(firebaseUser, user?.src, user?.shop));
            }
        } catch (e: any) {
            setError(e.code);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        confirmEmail();
    }, []);

    if (!loading && isBusinessAccount) {
        return (<Navigate to={Pages.REGISTER_BUSINESS_ACCOUNT}/>);
    }

    if (error === "auth/expired-action-code") {
        return (<Navigate to={Pages.LOGIN}/>);
    }

    return (
        <Spin wrapperClassName="email-confirmation" spinning={loading}>
            <Email/>
            <Logo className="email-confirmation__logo"/>
            <p>Ваш обліковий запис активовано</p>
            <Button type={ButtonType.PRIMARY} onClick={handleClick}>На Головну</Button>
        </Spin>
    );
};