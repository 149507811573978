import React, {FC, useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import {BackButton} from "../../common/components/backButton/BackButton";
import AdvertisementDecorator from "../../types/decorators/AdvertisementDecorator";
import {Spin} from "antd";
import {Pages} from "../../routing/Pages";
import GetAdPublicInfoCommand from "../../common/commands/ad/GetAdPublicInfoCommand";
import {AdDetailsView} from "../../common/components/adDetailsView/AdDetailsView";
import "./styles/adDetailsPage.scss";
import {useScreenWidth} from "../../common/hooks/useScreenWidth";
import {isMobile} from "../../common/components/screen/Screen";

export const AdDetailsPage: FC = () => {
    const {adId} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [advertisement, setAdvertisement] = useState<AdvertisementDecorator>();
    const width = useScreenWidth();
    useEffect(() => {
        new GetAdPublicInfoCommand().execute([adId ?? ""])
            .then(([ad]) => setAdvertisement(ad))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className="ad-details-page__loading">
                <Spin size="large"/>
            </div>
        );
    }

    if (!advertisement) {
        return (<Navigate to={Pages.LANDING}/>);
    }
    return (
        <div className="ad-details-page">
            {!isMobile(width) && (<BackButton className="ad-details-page__back"/>)}
            <AdDetailsView advertisement={advertisement} uploadedImages={advertisement.images}/>
        </div>);
};