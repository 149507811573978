import {FC} from "react";
import {FooterMenu} from "./footerMenu/FooterMenu";
import {Copyright} from "./copyright/Copyright";
import {ContactUs} from "./contactUs/ContactUs";
import {SocialNetworks} from "./socialNetworks/SocialNetworks";
import {useScreenWidth} from "../../hooks/useScreenWidth";
import {isMobile} from "../screen/Screen";
import "./styles/footer.scss"

export const Footer: FC = () => {
    const width = useScreenWidth();
    return (
        <footer className="app-footer">
            {!isMobile(width) && (<FooterMenu/>)}
            <div className="app-footer__bottom">
                <div className="app-footer__left">
                    <Copyright/>
                </div>
                <div className="app-footer__right">
                    <SocialNetworks/>
                    <ContactUs/>
                </div>
            </div>
        </footer>
    );
};