import {FC} from "react";
import {User} from "firebase/auth";
import {Col, Row} from "antd";
import {PhoneNumberInput} from "../../../../../../common/components/phoneNumberInput/PhoneNumberInput";
import {CurrentUserInfoDecorator} from "../../../../../../common/commands/user/types/CurrentUserInfoDecorator";
import {useScreenWidth} from "../../../../../../common/hooks/useScreenWidth";
import {isMobile} from "../../../../../../common/components/screen/Screen";

interface Props {
    user: CurrentUserInfoDecorator;
}

export const PhoneNumber: FC<Props> = (props: Props) => {
    const {user} = props;
    const userPhoneNumber: string = user.phoneNumber ?? "";
    const width: number = useScreenWidth();
    const span: string = isMobile(width) ? "24" : "12";
    return (
        <>
            <Row>
                <Col span={span}>
                    <label className="form-label">
                    <span className="form-label__text">
                    Телефон:
                    </span>
                        <div className="form-label__input">
                            <PhoneNumberInput
                                value={userPhoneNumber}
                                disabled={true}
                            />
                        </div>
                    </label>
                </Col>
            </Row>
        </>
    )
};