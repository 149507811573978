import FetchCommand from "../../../../../../common/fetch/FetchCommand";
import {Conversation} from "../../../../../../types/integration/Conversation";
import {ConversationDecorator} from "../../../../../../types/decorators/ConversationDecorator";
import {api} from "../../../../../../common/fetch/api";

export default class DeleteConversationCommand extends FetchCommand<string, ConversationDecorator> {
    async execute(conversation: ConversationDecorator) {
        return api<Conversation>(`/api/conversations/${conversation.id}`, {method: "DELETE"})
            .then(() => conversation.id);
    }
}