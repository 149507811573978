import FetchCommand from "../../../common/fetch/FetchCommand";
import {api} from "../../../common/fetch/api";
import {Advertisement} from "../../adDetailsPage/commands/types/Advertisement";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";

interface Payload {
    ad: Record<string, any>;
    adId: string;
}

export default class UpdateAddCommand extends FetchCommand<AdvertisementDecorator, Payload> {
    async execute(payload: Payload) {
        const result = {
            ...payload.ad,
            category: payload.ad.category[payload.ad.category.length - 1],
            price: Number(payload.ad.price)
        }
        return await api<Advertisement>(
            `/api/a/${payload.adId}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(result)
            },
        ).then((ad: Advertisement) => new AdvertisementDecorator(ad));
    }
}