import {FC, ReactNode} from "react";
import "./styles/profileMobileHeader.scss";

interface Props {
    children?: ReactNode;
}

export const ProfileMobileHeader: FC<Props> = (props: Props) => {
    const {children} = props;
    return (
        <div className="profile-mobile-header">
            <div className="profile-mobile-header__container">
                {children}
            </div>
        </div>
    );
};