import {FC, ReactNode} from "react";
import "./styles/iconButton.scss";

interface Props {
    children: ReactNode;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
}

export const IconButton: FC<Props> = (props: Props) => {
    const {children, onClick, disabled} = props;
    const className: string = ["icon-button", props.className].filter(Boolean).join(" ");
    return (<button className={className} onClick={onClick} disabled={disabled}>{children}</button>)
}