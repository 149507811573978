import React from "react";
import {memoize} from "lodash-es";
import {Select} from "antd";
import StaticData from "./StaticData.json";

const STATIC_DATA = StaticData as Record<string, any>;

const {Option} = Select;

export interface StaticDataItem {
    label: string;
    value: string;
}

export enum AdTypes {
    All = "Всі",
    PRIVATE = "Приватні Оголошення",
    SHOP = "Пропозиції Магазинів"
}

export const getPickList = memoize((picklist: string): StaticDataItem[] => {
    if (!STATIC_DATA[picklist]) {
        return [];
    }

    return STATIC_DATA[picklist].map((item: StaticDataItem) => ({label: item.label, value: item.value || item.label}));
});

export const valueToLabel = memoize((picklist: string, code: string): string => {
    return getPickList(picklist).find((item: StaticDataItem) => item.value === code)?.label ?? "";
}, (picklist: string, code: string) => `${picklist}_${code}`);

export const getSelectOptions = (picklist: string) => {
    return (getPickList(picklist) ?? []).map((item: StaticDataItem) => {
        return <Option key={item.value} value={item.value}>{item.label}</Option>
    })
}