import {Card, Col, Form, Input, Row, Segmented, Select} from "antd";
import {FC, useState} from "react";
import {AdTypes, getPickList} from "../../common/staticData/StaticData";
import {getCategories} from "../newAdPage/CategoriesMenuBuilder";
import {useCatalog} from "../../common/commands/catalog/useCatalog";
import {PriceRange} from "../../common/components/priceRange/PriceRange";
import {SingleValueType} from "rc-cascader/lib/Cascader";
import {ReactComponent as SearchIcon} from "../../common/components/header/headerSearch/resources/SearchIcon.svg";
import {Pages} from "../../routing/Pages";
import {createSearchParams, useNavigate} from "react-router-dom";
import {cleanedObject} from "../../common/utils/ObjectUtils";
import {Button, ButtonType} from "../../common/components/button/Button";
import {BackButton} from "../../common/components/backButton/BackButton";
import {CategoriesSelect} from "../../common/components/categoriesSelect/CategoriesSelect";
import {CatalogDecorator} from "../../common/commands/catalog/CatalogDecorator";
import "./styles/detailedSearch.scss";

export const getSearchPhraseFormItem = () => {
    return (
        <Form.Item name="searchPhrase">
            <Input placeholder="Пошукова фраза"/>
        </Form.Item>
    );
};

export const getCategoriesFormItem = (catalog: CatalogDecorator | null, onChange: (value: SingleValueType) => void) => {
    return (
        <Form.Item
            label="Категорія"
            name="category"
        >
            <CategoriesSelect options={getCategories(catalog?.src ?? {})} onChange={onChange}/>
        </Form.Item>

    );
};

export const getLocationFormItem = () => {
    return (
        <Form.Item
            label="Область"
            name="location"
        >
            <Select options={getPickList("region")} placeholder="Область"
                    mode="multiple"
                    dropdownMatchSelectWidth={false} allowClear={true}/>
        </Form.Item>
    );
};

export const DetailedSearch: FC = () => {
    const [catalog] = useCatalog();
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
    const [characteristics, setCharacteristics] = useState<Record<string, []> | undefined>(undefined);
    const navigate = useNavigate();

    const handleCategoryChange = (value: SingleValueType) => {
        const category: string = value[value.length - 1] as string;
        setSelectedCategory(category);
        setCharacteristics(catalog?.getCharacteristics(value as string[]));
    };

    const handleSubmit = (values: Record<string, any>) => {
        if (selectedCategory) {
            values["category"] = selectedCategory;
        }
        navigate({
            pathname: Pages.SEARCH,
            search: `?${createSearchParams(cleanedObject(values))}`
        });
    };

    return (
        <div className="detailed-search-page">
            <BackButton/>
            <h1>
                Детальний пошук
            </h1>
            <Form layout="vertical" onFinish={handleSubmit} initialValues={{type: AdTypes.All}}>
                <Card title="Пошукова фраза">{getSearchPhraseFormItem()}</Card>
                <Card title="Категорія">
                    <Row>
                        <Col span="12">
                            <Form.Item name="type">
                                <Segmented options={getPickList("adTypes")}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="12">{getCategoriesFormItem(catalog, handleCategoryChange)}</Col>
                    </Row>
                </Card>
                <Card title="Ціна">
                    <Row>
                        <Col span="12">
                            <PriceRange fromLabel="Мінімальна" toLabel="Максимальна"/>
                        </Col>
                    </Row>
                </Card>
                {selectedCategory && characteristics && (
                    <Card title="Характеристики">
                        {
                            Object.keys(characteristics).map((key: string) =>
                                (
                                    <Row key={key}>
                                        <Col span="12">
                                            <Form.Item
                                                name={key}
                                                label={key}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    options={characteristics[key].map((value: string) => ({
                                                        value,
                                                        key: value
                                                    }))}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                            )
                        }
                    </Card>
                )}
                <Card title="Місце знаходження">
                    <Row>
                        <Col span="12">{getLocationFormItem()}</Col>
                    </Row>
                </Card>
                <Row>
                    <Col span="4">
                        <Form.Item>
                            <Button buttonType="submit" type={ButtonType.PRIMARY} icon={<SearchIcon/>}>Пошук</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};