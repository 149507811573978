import {FC} from "react";
import {Col, Form, Input, Row, Segmented, Select} from "antd";
import {ReactComponent as SearchIcon} from "./resources/SearchIcon.svg";
import {AdTypes, getPickList} from "../../../staticData/StaticData";
import {useCatalog} from "../../../commands/catalog/useCatalog";
import {Pages} from "../../../../routing/Pages";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
import {ReactComponent as DetailedSearch} from "./resources/DetailedSearch.svg";
import {ReactComponent as Geo} from "./resources/Geo.svg";
import {cleanedObject} from "../../../utils/ObjectUtils";
import {DefaultOptionType} from "rc-select/lib/Select";
import {Button, ButtonType} from "../../button/Button";
import "./styles/headerSearchForm.scss";

export const HeaderSearchForm: FC = () => {
    const navigate = useNavigate();
    const [catalog] = useCatalog();
    const options = getPickList("adTypes");

    const getTypeOptions = (): DefaultOptionType[] => {
        return (catalog?.topLevelCategories() ?? []).map((key: string) => ({label: key, value: key}));
    }

    const handleSubmit = (values: Record<string, string>) => {
        navigate({
            pathname: Pages.SEARCH,
            search: `?${createSearchParams(cleanedObject(values))}`
        });
    };

    return (
        <Form onFinish={handleSubmit} className="header-search-form" initialValues={{type: AdTypes.All}}>
            <Row className="header-search-form__first-row">
                <Col span={20}>
                    <Form.Item name="type">
                        <Segmented options={options}/>
                    </Form.Item>
                </Col>
                <Col span="4">
                    <Link className="header-search-form__detailed-search" to={Pages.DETAILED_SEARCH}><DetailedSearch/>Детальний пошук</Link>
                </Col>
            </Row>
            <Row className="header-search-form__second-row">
                <Col span="6">
                    <Form.Item name="category">
                        <Select options={getTypeOptions()} placeholder="Тип товару"
                                dropdownMatchSelectWidth={false} allowClear={true}/>
                    </Form.Item>
                </Col>
                <Col span="10">
                    <Form.Item name="searchPhrase">
                        <Input placeholder="Пошукова фраза"/>
                    </Form.Item>
                </Col>
                <Col span="5">
                    <Form.Item name="location">
                        <Select options={getPickList("region")} placeholder={(
                            <div className="header-search-form__region-placeholder">
                                <Geo/>
                                {"Вся Україна"}
                            </div>
                        )}
                                dropdownMatchSelectWidth={false} allowClear={true}/>
                    </Form.Item>
                </Col>
                <Col span="3">
                    <Form.Item>
                        <Button buttonType="submit" className="header-search-form__submit" type={ButtonType.PRIMARY} icon={<SearchIcon/>}>Пошук</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
