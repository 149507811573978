import {FC, useState} from "react";
import {ReactComponent as Search} from "../resources/Search.svg";
import {Drawer} from "antd";
import {FilterPanel} from "../../../../content/searchPage/lhs/FilterPanel";
import "./styles/mobileDetailedSearch.scss";

export const MobileDetailedSearch: FC = () => {
    const [open, setOpen] = useState<boolean>(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    return (
        <>
            <button onClick={showDrawer} className="mobile-search__button">
                <Search/>
            </button>
            <Drawer
                closeIcon={null}
                title="Пошук"
                className="mobile-search__drawer"
                open={open}
                onClose={closeDrawer}
                placement="left"
            >
                <FilterPanel onCLoseClick={closeDrawer} onSubmit={closeDrawer}/>
            </Drawer>
        </>
    );
};