import {FC, useState} from "react";
import {ReactComponent as Trash} from "./resources/Trash.svg";
import {ReactComponent as Cross} from "./resources/Cross.svg";
import {ConversationDecorator} from "../../../../../types/decorators/ConversationDecorator";
import {Modal} from "antd";
import {Button, ButtonType} from "../../../../../common/components/button/Button";
import "./styles/deleteConversationButton.scss";
import DeleteConversationCommand from "./commands/DeleteConversationCommand";

interface Props {
    conversation: ConversationDecorator;
    onDelete: (conversationId: string) => void;
}

export const DeleteConversationButton: FC<Props> = (props: Props) => {
    const {conversation, onDelete} = props;
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const openModal = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };
    const deleteConversation = async () => {
        await new DeleteConversationCommand().execute(conversation).then(onDelete);
        setModalVisible(false);
    };

    return (
        <>
            <Trash className="delete-conversation" onClick={openModal}/>
            <Modal open={isModalVisible} title="Видалити Листування" className="delete-conversation__modal"
                   onCancel={closeModal}
                   footer={(
                       <div className="delete-conversation__modal-footer">
                           <Button type={ButtonType.OUTLINE} onClick={closeModal}>Назад</Button>
                           <Button type={ButtonType.DANGER} icon={<Cross/>} onClick={deleteConversation}>Видалити Листування</Button>
                       </div>)}
            >
                Ви збираєтесь видалити все листування. Ця операція не зможе бути відмінена.
            </Modal>
        </>
    );
};