import FetchCommand from "../../../../common/fetch/FetchCommand";
import {api} from "../../../../common/fetch/api";
import {notification} from "antd";
import {CatalogResult} from "../../../../common/commands/catalog/GetCatalogCommand";
import {CatalogDecorator} from "../../../../common/commands/catalog/CatalogDecorator";

export default class UploadCatalogCommand extends FetchCommand<CatalogDecorator | null, string> {
    async execute(body: string) {
        return await api<CatalogResult>("/api/catalog", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body
        }).then((result: CatalogResult) => {
            notification.success({message: "Нова структура каталогу завантажена"});
            return new CatalogDecorator(result);
        }).catch(() => {
            this.showError({
                message: "Нова структура каталогу не завантажена",
                text: "Перевірте правильність заповнення файлу каталогу"
            });
            return null;
        })
    }
}