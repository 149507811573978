import {Card, Col, Row} from "antd";
import {FC, ReactNode, SyntheticEvent, useState} from "react";
import AdvertisementDecorator from "../../../../types/decorators/AdvertisementDecorator";
import {EditButton} from "./buttons/editButton/EditButton";
import {PromoteButton} from "./buttons/promoteButton/PromoteButton";
import {PublishButton} from "./buttons/publishButton/PublishButton";
import {Status} from "../../../adDetailsPage/commands/types/Advertisement";
import {DeleteButton} from "./buttons/deleteButton/DeleteButton";
import {Statistic} from "./statistic/Statistic";
import {AdToFavouritesButton} from "../../../../common/components/adToFavouritesButton/AdToFavouritesButton";
import {useCurrentUser} from "../../../../common/commands/user/useCurrentUser";
import {ReactComponent as Decline} from "./resources/Decline.svg";
import {ReactComponent as ThreeDotsVertical} from "./resources/ThreeDotsVertical.svg";
import {Button, ButtonType} from "../../../../common/components/button/Button";
import {useScreenWidth} from "../../../../common/hooks/useScreenWidth";
import {isMobile} from "../../../../common/components/screen/Screen";
import "./styles/myAdCard.scss";
import {MobileDrawer} from "../../../../common/components/mobileDrawer/MobileDrawer";

interface Props {
    advertisement: AdvertisementDecorator;
    isFavourite?: boolean;
    onArchive?: () => void;
    onRefresh?: () => void;
    onClick?: () => void;
    hideActions?: boolean;
}

const RemoveFromFavouritesButton = (props: { onClick: (event: SyntheticEvent) => void }) => {
    return (<Button type={ButtonType.OUTLINE} onClick={props.onClick} icon={<Decline/>}>Прибрати з Обраного</Button>);
};

export const MyAdCard: FC<Props> = (props: Props) => {
    const [user] = useCurrentUser();
    const {advertisement, isFavourite, onArchive, onRefresh, onClick, hideActions} = props;
    const [image] = advertisement.thumbnails;
    const width: number = useScreenWidth();
    const imageSpan: string = isMobile(width) ? "12" : "3";
    const descriptionSpan: string = isMobile(width) ? "12" : "4";
    const infoSpan: string = isMobile(width) ? "24" : "5";
    const detailsSpan: string = isMobile(width) ? "24" : "7";
    const [isDrawerVisible, setDrawerVisible] = useState(false);
    const getDetails = () => {
        switch (advertisement.status) {
            case Status.DECLINED:
                return (<p className="my-ad-card__decline">{advertisement.moderatorComment}</p>);
            case Status.ARCHIVED:
                return (<Statistic/>);
            case Status.ACTIVE:
                return (<Statistic/>);
            default:
                return null;
        }
    };
    const showDrawer = () => {
        setDrawerVisible(true);
    };
    const hideDrawer = () => {
        setDrawerVisible(false);

    };
    const buttons: ReactNode = (
        <div className="my-ad-card__buttons">
            {
                (user?.id === advertisement.src.authorId) && !isFavourite && (
                    <EditButton adId={advertisement.id}/>)
            }
            {advertisement.status === Status.ACTIVE && (<PromoteButton/>)}
            {[Status.ACTIVE, Status.DECLINED, Status.DRAFT].includes(advertisement.status as Status) && (
                <DeleteButton adId={advertisement.id} onArchive={onArchive}/>)}
            {advertisement.status === Status.ARCHIVED && (
                <PublishButton adId={advertisement.id} onRefresh={onRefresh}/>)}
            {isFavourite && (<AdToFavouritesButton isFavorite advertisement={advertisement}
                                                   removeButtonComponent={RemoveFromFavouritesButton}/>)}
        </div>
    );
    return (
        <Card className={`my-ad-card ${onClick ? "my-ad-card--clickable" : ""}`}>
            <Row>
                <Col span={imageSpan}>
                    <div className="my-ad-card__image">
                        <img src={image} alt={advertisement.title} onClick={onClick}/>
                    </div>
                </Col>
                <Col span={descriptionSpan}>
                    <div className="my-ad-card__characteristics">
                        <h4 className="my-ad-card__heading" onClick={onClick}>{advertisement.title}</h4>
                        {advertisement.caliber && (
                            <p className="my-ad-card__text">{advertisement.caliber}</p>
                        )}
                        <p className="my-ad-card__price">{advertisement.price}</p>
                    </div>
                </Col>
                <Col span={infoSpan}>
                    <div className="my-ad-card__info">
                        <p className="my-ad-card__heading">{advertisement.createdOn}</p>
                        <p className="my-ad-card__text">{advertisement.days}</p>
                        <p className="my-ad-card__status">{advertisement.displayStatus}</p>
                    </div>
                </Col>
                <Col span={detailsSpan}>
                    <div className="my-ad-card__details">
                        {getDetails()}
                    </div>
                </Col>
                {(!hideActions && !isMobile(width)) && (
                    <Col span="5">{buttons}</Col>
                )}
            </Row>
            {(!hideActions && isMobile(width)) && (
                <>
                    <ThreeDotsVertical className="my-ad-card__mobile-menu-trigger" onClick={showDrawer}/>
                    <MobileDrawer
                        visible={isDrawerVisible}
                        onClose={hideDrawer}
                    >
                        {buttons}
                    </MobileDrawer>
                </>
            )}
        </Card>
    );
};