import FetchCommand from "../../../../fetch/FetchCommand";
import {api} from "../../../../fetch/api";

export default class CreateConversationCommand extends FetchCommand<null, string> {
    private adId: string

    constructor(adId: string) {
        super();
        this.adId = adId;
    }

    async execute(message: string) {
        return api<null>(`/api/conversations/create?adId=${this.adId}`, {
            method: "POST",
            headers: {
                "Content-Type": "text/html",
            },
            body: message
        }).then(() => Promise.resolve(null))
    }

}