import FetchCommand from "../../../../common/fetch/FetchCommand";
import GetCatalogCommand from "../../../../common/commands/catalog/GetCatalogCommand";
import {CatalogDecorator} from "../../../../common/commands/catalog/CatalogDecorator";

export default class DownloadCatalogCommand extends FetchCommand<void, undefined> {
    async execute() {
        const catalog: CatalogDecorator = await new GetCatalogCommand().execute();
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([JSON.stringify(catalog.src, null, 2)], {
            type: "application/json"
        }));
        a.setAttribute("download", catalog.getFileName());
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}