import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {FirebaseOptions} from "@firebase/app";
import {getAuth} from "firebase/auth"
import {getStorage} from "firebase/storage"

const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyBtA_UmJC4Y02yF60jkI7urzuIyKHlHhcM",
    authDomain: window.location.host.includes("localhost") ? "baron.in.ua" : window.location.host,
    projectId: "baron-ua",
    storageBucket: "baron-ua.appspot.com",
    messagingSenderId: "143718454380",
    appId: "1:143718454380:web:8cad09177668d78c087449",
    measurementId: "G-W6Z5Z4MNL9",
};

export const CLIENT_ID: string = "143718454380-u3ge8epk397uv6ue5h1gnrit4b2aj8dj.apps.googleusercontent.com";
export const STORAGE_BUCKET = firebaseConfig.storageBucket as string;
const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const compatAuth = firebase.auth(app);

//TODO: This is necessary for localized version of firebaseui, which is provided through CDN
// @ts-ignore
window.firebase = firebase;