import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../../../routing/Pages";
import {Dropdown, Menu} from "antd";
import {Avatar} from "../avatar/Avatar";
import {SavedAds} from "../savedAds/SavedAds";
import {Messages} from "../messages/Messages";
import {LogoutButton} from "./logoutButton/LogoutButton";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import {UserProfileButton} from "./userProfileButton/UserProfileButton";
import {MyAdsButton} from "./myAdsButton/MyAdsButton";
import "./styles/userProfile.scss";
import {CurrentUserInfoDecorator} from "../../../commands/user/types/CurrentUserInfoDecorator";
import {useCurrentUser} from "../../../commands/user/useCurrentUser";
import {useScreenWidth} from "../../../hooks/useScreenWidth";
import {isMobile} from "../../screen/Screen";

const getMenuItems = (user: CurrentUserInfoDecorator): ItemType[] => {
    const items: ItemType[] = [{
        key: "1",
        label: (
            <div className="user-profile-button__user-name">
                <Avatar size={"large"}/>
                {user.displayName}
            </div>
        ),
    }];

    if (user.isClient) {
        items.push(...[
            {
                key: "2",
                label: (<MyAdsButton/>),
                className: "user-profile-button__menu-item"
            },
            {
                key: "3",
                label: (<Messages text="Повідомлення"/>),
                className: "user-profile-button__menu-item"
            },
            {
                key: "4",
                label: (<SavedAds label="Улюблені"/>),
                className: "user-profile-button__menu-item"
            },
            {
                key: "5",
                label: (<UserProfileButton user={user}/>),
                className: "user-profile-button__menu-item"
            }
        ])
    }
    items.push(...[
        {
            type: "divider"
        } as ItemType,
        {
            key: "6",
            label: (<LogoutButton/>),
            className: "user-profile-button__menu-item"
        }
    ]);

    return items;
}

export const UserProfileMenu: FC = () => {
    const [currentUser] = useCurrentUser();
    const navigate = useNavigate();
    const width: number = useScreenWidth();

    const handleClick = () => {
        navigate(Pages.LOGIN);
    };

    const goToProfilePage = () => {
        navigate(`${Pages.USER_PROFILE}?tab=profile`);
    };

    if (currentUser) {
        const overlay = (<Menu items={getMenuItems(currentUser)} className="user-profile-button__menu"/>);

        return isMobile(width)
            ? (
                <Avatar onClick={goToProfilePage}/>
            )
            : (
                <Dropdown overlay={overlay} placement="bottom" trigger={["click"]}>
                    <Avatar/>
                </Dropdown>
            );
    }

    return (<Avatar onClick={handleClick}/>);
}