import React, {FC, useEffect, useState} from "react";
import {ReactComponent as Logo} from "./resources/Logo.svg";
import {Card} from "antd";
import {GoogleLogin} from "./loginForm/google/GoogleLogin";
import {EmailLogin} from "./loginForm/emailLogin/EmailLogin";
import {isLoggedViaEmail} from "../../common/utils/UserUtils";
import {EmailVerification, SEND_EMAIL_STORAGE_KEY} from "./emailVerification/EmailVerification";
import {Navigate, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase/config";
import {useSessionStorage} from "../../common/hooks/useSessionStorrage";
import {ResetPasswordRequest} from "./resetPasswordRequest/ResetPasswordRequest";
import {ResetPassword} from "./resetPassword/ResetPassword";
import "./styles/login.scss";
import {EmailConfirmation} from "./emailConfirmation/EmailConfirmation";

export const Login: FC = () => {
    const location = useLocation();
    const [user] = useAuthState(auth);
    const [email, setEmail] = useState<string>();
    const [isPasswordResetRequested, setPasswordResetRequested] = useState<boolean>(false);
    const [, setSendEmailTimeout] = useSessionStorage(SEND_EMAIL_STORAGE_KEY);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const mode: string | null = searchParams.get("mode");

    const handlePasswordResetClick = (email: string) => {
        setPasswordResetRequested(true);
        setEmail(email);
    };

    const navigateBackFromPasswordReset = () => {
        setPasswordResetRequested(false);
    };

    useEffect(() => {
        if (mode === "verifyEmail") return;
        let interval: any;
        if (user && isLoggedViaEmail(user) && !user.emailVerified) {
            interval = setInterval(async () => {
                await user.reload();
                if (user.emailVerified) {
                    setSendEmailTimeout(null);
                    clearInterval(interval);
                    navigate("/");
                }
            }, 20000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [user]);

    const getContent = () => {
        if (mode === "verifyEmail") {
            return (<EmailConfirmation/>);
        }

        if (mode === "resetPassword") {
            return (<ResetPassword/>);
        }

        if (isPasswordResetRequested) {
            return (<ResetPasswordRequest navigateBack={navigateBackFromPasswordReset} email={email}/>);
        }

        if (user) {
            if (isLoggedViaEmail(user) && !user.emailVerified) {
                return <EmailVerification/>;
            }
            if (location?.state?.from) {
                return <Navigate to={location.state.from}/>
            }

            return <Navigate to="/"/>
        }

        return (
            <Card className="login__form">
                <div className="login__logo">
                    <Logo/>
                </div>
                <p>Вхід до вашого аккаунта</p>
                <div className="login__buttons">
                    <GoogleLogin/>
                </div>
                <h2 className="login__subtitle"><span>або</span></h2>
                <EmailLogin onResetPasswordClick={handlePasswordResetClick}/>
            </Card>
        )
    };

    return (
        <div className="login">
            {getContent()}
        </div>
    );
};
