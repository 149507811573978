import {FC, useEffect} from "react";
import {Tabs} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import {AdministrationTabs} from "./AdministrationTabs";
import {Catalog} from "./tabs/catalog/Catalog";
import {Users} from "./tabs/users/Users";
import {Partners} from "./tabs/partners/Partners";
import "./styles/administrationPage.scss";

export const AdministrationPage: FC = () => {
    const [searchParams] = useSearchParams();
    const tab: string = searchParams.get("tab") ?? "";
    const navigate = useNavigate();
    const items = [
        {
            label: "Користувачі",
            key: AdministrationTabs.USERS
        },
        {
            label: "Тарифні Плани",
            key: AdministrationTabs.PLANS
        },
        {
            label: "Партнери",
            key: AdministrationTabs.PARTNERS
        },
        {
            label: "Структура Каталогу",
            key: AdministrationTabs.CATALOGUE
        },
    ];

    const handleChange = (activeKey: string) => {
        navigate(`${Pages.ADMINISTRATION}?tab=${activeKey}`);
    };

    const getContent = () => {
        switch (tab) {
            case AdministrationTabs.USERS:
                return (<Users/>);
            case AdministrationTabs.PLANS:
                return (<div>PLANS</div>);
            case AdministrationTabs.PARTNERS:
                return (<Partners/>);
            case AdministrationTabs.CATALOGUE:
                return (<Catalog/>);
        }
    };

    useEffect(() => {
        if (!tab) {
            handleChange(AdministrationTabs.USERS);
        }
    }, []);

    return (
        <div className="administration-page">
            <Tabs items={items} onChange={handleChange} activeKey={tab}/>
            {getContent()}
        </div>
    );
};