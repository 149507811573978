import {FC} from "react";
import {ReactComponent as EnvelopeIcon} from "./resources/Envelope.svg";
import {Button, ButtonType} from "../../button/Button";

export const ContactUs: FC = () => {
    return (
        <>
            <Button type={ButtonType.SECONDARY} icon={<EnvelopeIcon/>}>Напишіть нам</Button>
        </>
    );
}