import React, {FC} from "react";
import "./styles/listItem.scss";

interface Props {
    label: string;
    value: string;
}

export const ListItem: FC<Props> = (props: Props) => {
    const {label, value} = props;
    return (
        <li className="ad-characteristic">
            <span>{label}</span>
            <strong>{value}</strong>
        </li>
    );
};