import {FC, useState} from "react";
import {Col, Row} from "antd";
import {UserRolesRenderer} from "./userRoleBadge/UserRolesRenderer";
import {CurrentUserInfoDecorator} from "../../../../../../common/commands/user/types/CurrentUserInfoDecorator";
import {UserStatusRenderer} from "./userStatusRenderer/UserStatusRenderer";
import {Button, ButtonType} from "../../../../../../common/components/button/Button";
import {ReactComponent as Details} from "./resources/Details.svg";
import {generatePath, useNavigate} from "react-router-dom";
import {Pages} from "../../../../../../routing/Pages";
import {SetStatusButton} from "../../../../../../common/components/setStatusButton/SetStatusButton";
import "./styles/usersList.scss";

interface Props {
    users: CurrentUserInfoDecorator[];
    onUserUpdate: (user: CurrentUserInfoDecorator) => void;
}

export const UsersList: FC<Props> = (props: Props) => {
    const {users, onUserUpdate} = props;
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState<CurrentUserInfoDecorator | null>(null);
    const isUserSelected = (user: CurrentUserInfoDecorator) => user.id === selectedUser?.id;
    const getItemClassName = (user: CurrentUserInfoDecorator) => {
        return `users-list__item ${user.isDisabled ? "users-list__item--disabled" : "users-list__item--active"} ${isUserSelected(user) ? "users-list__item--selected" : ""}`
    };

    const selectUser = (user: CurrentUserInfoDecorator) => {
        setSelectedUser(user);
    };

    const goToDetails = () => {
        if (!selectedUser) return;
        navigate(generatePath(Pages.ADMINISTRATION_USER_DETAILS, {userId: selectedUser?.id ?? ""}));
    };

    return (
        <>
            <ul className="users-list">
                {users.map((user: CurrentUserInfoDecorator) => (
                        <li className={getItemClassName(user)} onClick={() => selectUser(user)} key={user.id}>
                            <Row>
                                <Col span="6">
                                    {user.displayName}
                                </Col>
                                <Col span="8">
                                    {user.email}
                                </Col>
                                <Col span="3">
                                    {user.phoneNumber}
                                </Col>
                                <Col span="3">
                                    <UserRolesRenderer user={user}/>
                                </Col>
                                <Col span="3">
                                    <UserStatusRenderer user={user}/>
                                </Col>
                            </Row>
                            {isUserSelected(user) && (
                                <div className="users-list__item-controls">
                                    <Button type={ButtonType.SECONDARY} icon={<Details/>} onClick={goToDetails}>Повна
                                        Інформація</Button>
                                    <SetStatusButton user={user} onChanged={onUserUpdate}/>
                                </div>
                            )}
                        </li>
                    )
                )}
            </ul>
        </>
    );
};