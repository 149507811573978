import {FC} from "react";
import {Col, Image, Row} from "antd";
import {ConversationDecorator} from "../../../../../types/decorators/ConversationDecorator";
import {generatePath, Link} from "react-router-dom";
import {Pages} from "../../../../../routing/Pages";
import "./styles/conversationInfoRenderer.scss";

interface Props {
    conversation: ConversationDecorator;
    authorDisplayName?: string;
}

export const ConversationInfoRenderer: FC<Props> = (props: Props) => {
    const {conversation, authorDisplayName} = props;
    const {advertisement} = conversation;
    const [thumbnail] = advertisement?.thumbnails ?? [];
    const to: string = generatePath(Pages.AD_PUBLIC_DETAILS, {adId: advertisement?.id ?? ""});
    return (
        <Row className="conversation-info-renderer">
            <Col span="6" className="conversation-info-renderer__ad-image">
                <Link to={to}>
                    <Image src={thumbnail} preview={false}/>
                </Link>
            </Col>
            <Col span="18" className="conversation-info-renderer__ad-info">
                <p className="conversation-info-renderer__date">
                    <Link to={to}>
                        {advertisement?.createdOn}
                        {authorDisplayName && (
                            <>
                                {" - "}
                                <strong>{authorDisplayName}</strong>
                            </>
                        )}
                    </Link>
                </p>
                <p className="conversation-info-renderer__title">
                    <Link to={to}>
                        {advertisement?.title}
                    </Link>
                </p>
                <p className="conversation-info-renderer__price">
                    <Link to={to}>
                        <strong>{advertisement?.price}</strong>
                    </Link>
                </p>
            </Col>
        </Row>
    )
};
