import {Conversation, Message, Participant} from "../integration/Conversation";
import {MessageDecorator} from "./MessageDecorator";
import AdvertisementDecorator from "./AdvertisementDecorator";
import {PublicUserInfo} from "../../common/commands/user/types/PublicUserInfo";

export class ConversationDecorator {
    src: Conversation;
    _advertisement?: AdvertisementDecorator;
    _participants: PublicUserInfo[] = [];

    constructor(src: Conversation) {
        this.src = src;
    }

    get id(): string {
        return this.src.id;
    }

    get adId(): string {
        return this.src.adId;
    }

    get messages(): MessageDecorator[] {
        return this.src.messages.map((item: Message) => new MessageDecorator(item));
    }

    get participantIds(): string[] {
        return this.src.participants.map((item: Participant) => item.userId);
    }

    set advertisement(ad: AdvertisementDecorator | undefined) {
        this._advertisement = ad;
    }

    get advertisement(): AdvertisementDecorator | undefined {
        return this._advertisement
    }

    hasUnreadMessages(currentUserId: string): boolean {
        return this.src.participants
            .some((participant: Participant) => participant.userId === currentUserId && participant.status === 1000000);
    }
}