import {Navigate, Route, Routes} from "react-router-dom";
import {Pages} from "./Pages";
import {Login} from "../content/login/Login";
import {Landing} from "../content/landing/Landing";
import {UserProfilePage} from "../content/userProfilePage/UserProfilePage";
import {NewAdPage} from "../content/newAdPage/NewAdPage";
import {SearchPage} from "../content/searchPage/SearchPage";
import {DetailedSearch} from "../content/detailedSearch/DetailedSearch";
import {AdDetailsPage} from "../content/adDetailsPage/AdDetailsPage";
import {EditPage} from "../content/editPage/EditPage";
import {FC} from "react";
import {ModerationPage} from "../content/moderationPage/ModerationPage";
import {useCurrentUser} from "../common/commands/user/useCurrentUser";
import {AdministrationPage} from "../content/administration/administrationPage/AdministrationPage";
import {AdministrationUserDetails} from "../content/administration/administrationUserDetails/AdministrationUserDetails";
import {RegisterBusinessAccountPage} from "../content/registerBusinessAccountPage/RegisterBusinessAccountPage";
import {CreatePartnerPage} from "../content/administration/createPartnerPage/CreatePartnerPage";
import {PartnerDetailsPage} from "../content/partnerDetailsPage/PartnerDetailsPage";
import {AboutPage} from "../content/about/AboutPage";
import {PublicContract} from "../content/law/legalAspects/publicСontract/PublicContract";
import {PrivacyPage} from "../content/law/legalAspects/privacy/PrivacyPage";
import {LegalAspects} from "../content/law/legalAspects/LegalAspects";
import {CookiesPage} from "../content/law/legalAspects/cookiesPage/CookiesPage";
import {DeletePersonalData} from "../content/law/legalAspects/deletePersonalData/DeletePersonalData";
import {PaymentInfo} from "../content/law/legalAspects/payment/PaymentInfo";

export const AppRoutes: FC = () => {
    const [currentUser] = useCurrentUser();
    return (
        <Routes>
            <Route path={Pages.LOGIN} element={<Login/>}/>
            {allUsersRoutes()}
            {!currentUser && getNotAuthorizedUserRoutes()}
            {currentUser?.isNotConfirmedBusinessAccount && getCreateBusinessAccountRouter()}
            {(currentUser?.isClient && !currentUser?.isNotConfirmedBusinessAccount) && getClientRoutes()}
            {currentUser?.isModerator && getModeratorRoutes()}
            {currentUser?.isAdmin && getAdminRoutes()}
        </Routes>
    );
}

const getNotAuthorizedUserRoutes = () => (
    <>
        <Route path="*" element={<Navigate to={Pages.LANDING}/>}/>
        <Route path="/" element={<Navigate to={Pages.LANDING}/>}/>
        <Route path={Pages.LANDING} element={<Landing/>}/>
        <Route path={Pages.SEARCH} element={<SearchPage/>}/>
        <Route path={Pages.DETAILED_SEARCH} element={<DetailedSearch/>}/>
        <Route path={Pages.AD_PUBLIC_DETAILS} element={<AdDetailsPage/>}/>
        <Route path={Pages.PARTNER_DETAILS} element={<PartnerDetailsPage/>}/>
    </>
);

const getClientRoutes = () => (
    <>
        {getNotAuthorizedUserRoutes()}
        <Route path={Pages.EDIT_AD} element={<EditPage/>}/>
        <Route path={Pages.USER_PROFILE} element={<UserProfilePage/>}/>
        <Route path={Pages.NEW_AD} element={<NewAdPage/>}/>
    </>
);

const getModeratorRoutes = () => (
    <>
        <Route path="/" element={<Navigate to={Pages.MODERATION}/>}/>
        <Route path={Pages.MODERATION} element={<ModerationPage/>}/>
        <Route path="*" element={<Navigate to={Pages.MODERATION}/>}/>
    </>
);

const getAdminRoutes = () => (
    <>
        <Route path="/" element={<Navigate to={Pages.ADMINISTRATION}/>}/>
        <Route path={Pages.ADMINISTRATION} element={<AdministrationPage/>}/>
        <Route path={Pages.ADMINISTRATION_USER_DETAILS} element={<AdministrationUserDetails/>}/>
        <Route path={Pages.AD_PUBLIC_DETAILS} element={<AdDetailsPage/>}/>
        <Route path={Pages.CREATE_PARTNER} element={<CreatePartnerPage/>}/>
        <Route path={Pages.PARTNER_DETAILS} element={<PartnerDetailsPage/>}/>
        <Route path="*" element={<Navigate to={Pages.ADMINISTRATION}/>}/>
    </>
);

const getCreateBusinessAccountRouter = () => (
    <>
        <Route path="/" element={<Navigate to={Pages.REGISTER_BUSINESS_ACCOUNT}/>}/>
        <Route path={Pages.REGISTER_BUSINESS_ACCOUNT} element={<RegisterBusinessAccountPage/>}/>
        <Route path="*" element={<Navigate to={Pages.REGISTER_BUSINESS_ACCOUNT}/>}/>
    </>
);

const allUsersRoutes = () => (
    <>
        <Route path={Pages.ABOUT} element={<AboutPage/>}/>
        <Route path={Pages.PUBLIC_CONTRACT} element={<PublicContract/>}/>
        <Route path={Pages.PRIVACY} element={<PrivacyPage/>}/>
        <Route path={Pages.LEGAL_ASPECTS} element={<LegalAspects/>}/>
        <Route path={Pages.COOKIES} element={<CookiesPage/>}/>
        <Route path={Pages.DELETE_PERSONAL_DATA} element={<DeletePersonalData/>}/>
        <Route path={Pages.PAYMENT_INFO} element={<PaymentInfo/>}/>
    </>
)

