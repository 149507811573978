import {FC, useState} from "react";
import {Form} from "antd";
import {RuleObject} from "rc-field-form/lib/interface";
import {NumericInput} from "../numericInput/NumericInput";
import "./styles/priceRange.scss";

interface Props {
    fromLabel?: string;
    toLabel?: string;
}

export const PriceRange: FC<Props> = (props: Props) => {
    const {fromLabel = "Ціна від:", toLabel} = props;
    const [priceFromValue, setPriceFrom] = useState<string | undefined>();
    const [priceToValue, setPriceTo] = useState<string | undefined>();
    return (
        <div className="price-range">
            <Form.Item name="priceFrom"
                       label={fromLabel}
                       dependencies={["priceTo"]}
                       rules={[{
                           validator: (rue: RuleObject, value: string | undefined) => {
                               if (!value && !priceToValue || priceToValue && !value || !priceToValue && value) {
                                   return Promise.resolve();
                               }
                               if (priceToValue && Number(value) < Number(priceToValue)) {
                                   return Promise.resolve();
                               }
                               return Promise.reject();
                           }
                       }]}>
                <NumericInput onChange={setPriceFrom}/>
            </Form.Item>
            <span className="price-range__devider">-</span>
            <Form.Item name="priceTo"
                       label={toLabel}
                       dependencies={["priceFrom"]}
                       rules={[{
                           validator: (rule: RuleObject, value: string | undefined) => {
                               if (!priceFromValue && !value || value && !priceFromValue || !value && priceFromValue) {
                                   return Promise.resolve();
                               }
                               if (priceFromValue && Number(priceFromValue) < Number(value)) {
                                   return Promise.resolve();
                               }
                               return Promise.reject();
                           }
                       }]}
            >
                <NumericInput onChange={setPriceTo}/>
            </Form.Item>
        </div>
    )
}
