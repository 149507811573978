import {StaticContent} from "../../../../common/components/staticContentPage/StaticContent";

export const DeletePersonalData = () => {
    return (
        <StaticContent title="Як видалити персональні дані?">
            <p>Якщо ви бажаєте, щоб ваші персональні дані були видалені з baron.ua, відповідно до законодавства України, вам потрібно направити спеціальний запит із повідомленням про відкликання згоди на обробку персональних даних.<br/><br/>
                Ви можете направити такий запит через форму зворотнього зв&rsquo;язку або поштою на ім&rsquo;я ТОВ &laquo;БАРОН 2005&raquo; за адресою: просп. Червоної Калини, 66а, к. 168, м. Київ, 02064. Ми можемо попросити вас надати додаткову інформацію, щоб розуміти, що до нас звертається саме суб&#39;єкт персональних даних.<br/><br/>
                Зверніть увагу, що для певних цілей ми можемо мати юридичні зобов&#39;язання щодо збереження ваших даних.</p>

        </StaticContent>
    );
}