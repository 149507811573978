import {FC, ReactNode, useEffect, useState} from "react";
import {ReactComponent as MessagesIon} from "./resources/Messages.svg";
import {IconButton} from "../../iconButton/IconButton";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../../../routing/Pages";
import {UserProfileTabs} from "../../../../content/userProfilePage/UserProfileTabs";
import GetUnreadConversationsCount from "./commands/GetUnreadConversationsCount";
import {Badge} from "antd";
import {useUnreadMessagesCount} from "./hooks/useUnreadMessagesCount";

interface Props {
    text?: string;
    badge?: ReactNode;
}

export const Messages: FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const [count] = useUnreadMessagesCount();
    const handleClick = () => {
        navigate(`${Pages.USER_PROFILE}?tab=${UserProfileTabs.MESSAGES}`);
    };

    return (
        <IconButton onClick={handleClick}>
            <Badge count={count.total} overflowCount={99} color="#FF5722" offset={[-16, 20]}>
                <MessagesIon/>
            </Badge>
            {props.text}
        </IconButton>
    );
};