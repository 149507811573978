import BaseSearchCommand from "./BaseSearchCommand";
import {FindPriorityDto} from "../../../types/integration/SearchDto";
import {api} from "../../fetch/api";
import {Page} from "../../../types/integration/Page";
import {Advertisement} from "../../../content/adDetailsPage/commands/types/Advertisement";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";

export default class SearchWithPriorityCommand extends BaseSearchCommand<FindPriorityDto> {
    async execute(params:FindPriorityDto ) {
        const url: string = this.nextPage || "/api/a/find-priority";
        return api<Page<Advertisement>>(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params)
        }).then((page: Page<Advertisement>) => {
            const result: Page<AdvertisementDecorator> = {
                items: page.items.map((item: Advertisement) => new AdvertisementDecorator(item)),
                nextPage: page.nextPage,
            };
            return result;
        });
    }
}