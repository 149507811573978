import {FC} from "react";
import {signOut} from "firebase/auth";
import {ReactComponent as UserIcon} from "./resources/UserIcon.svg";
import {ReactComponent as LogOutIcon} from "./resources/LogOutIcon.svg";
import {useNavigate} from "react-router-dom";
import {auth} from "../../../firebase/config";
import {Pages} from "../../../routing/Pages";
import {Avatar} from "../../../common/components/header/avatar/Avatar";
import {useCurrentUser} from "../../../common/commands/user/useCurrentUser";
import "./styles/userProfileAvatar.scss";
import {Button, ButtonType} from "../../../common/components/button/Button";

export const UserProfileAvatar: FC = () => {
    const [user, setUser] = useCurrentUser();
    const navigate = useNavigate();

    const handleLogOut = async () => {
        await signOut(auth);
        setUser(null);
        navigate(Pages.LANDING);
    };

    return (
        <div className="user-profile-avatar">
            <div className="user-profile-avatar__image">
                <Avatar icon={<UserIcon/>} size="large"/>
            </div>
            {user?.displayName && (
                <div className="user-profile-avatar__display-name">
                    {user.displayName}
                </div>
            )}
            <Button type={ButtonType.LINK_OUTLINE} className="user-profile-avatar__log-out" icon={<LogOutIcon/>}
                    onClick={handleLogOut}>Вихід</Button>
        </div>
    );
};