import {FC} from "react";
import {Logo} from "./logo/Logo";
import {CreateNewAdButton} from "./createNewAd/CreateNewAdButton";
import {UserProfileMenu} from "./userProfileMenu/UserProfileMenu";
import {Messages} from "./messages/Messages";
import {SavedAds} from "./savedAds/SavedAds";
import {HeaderMenu} from "./headerMenu/HeaderMenu";
import {useLocation} from "react-router-dom";
import {Affix} from "antd";
import {HeaderSearchForm} from "./headerSearch/HeaderSearchForm";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import {useScreenWidth} from "../../hooks/useScreenWidth";
import {isMobile} from "../screen/Screen";
import {MobileMenu} from "./mobileMenu/MobileMenu";
import "./styles/header.scss";
import {
    isCreateNewAdButtonVisible,
    isMessageButtonVisible,
    isProfileButtonVisible,
    isSearchVisible
} from "../../utils/LocationUtils";

export const Header: FC = () => {
    const location = useLocation();
    const [currentUser] = useCurrentUser();
    const width = useScreenWidth();
    return (
        <header className="app-header">
            <Affix>
                <div className="app-header__top">
                    <div className="app-header__container">
                        {!isMobile(width)
                            ? (
                                <>
                                    <div className="app-header__top-left">
                                        <Logo/>
                                    </div>
                                    <div className="app-header__top-right">
                                        <ul className="app-header__links">
                                            {isMessageButtonVisible(location.pathname, currentUser) && (
                                                <>
                                                    <li className="app-header__link">
                                                        <SavedAds/>
                                                    </li>
                                                    <li className="app-header__link">
                                                        <Messages/>
                                                    </li>
                                                </>
                                            )}
                                            {isProfileButtonVisible(location.pathname) && (
                                                <li className="app-header__link">
                                                    <UserProfileMenu/>
                                                </li>
                                            )}
                                        </ul>
                                        {isCreateNewAdButtonVisible(location.pathname, currentUser) && (
                                            <CreateNewAdButton/>
                                        )}
                                    </div>
                                </>
                            )
                            : (
                                <>
                                    <MobileMenu/>
                                    <div className="app-header__mobile-logo">
                                        <Logo/>
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </Affix>
            {(isSearchVisible(location.pathname, currentUser, width) && !isMobile(width)) && (
                <>
                    <div className="app-header__search">
                        <div className="app-header__container">
                            <HeaderSearchForm/>
                        </div>
                    </div>
                    <div className="app-header__menu">
                        <div className="app-header__container">
                            <HeaderMenu/>
                        </div>
                    </div>
                </>
            )}
        </header>
    );
};
