import {FC, ReactNode} from "react";
import {IconButton} from "../../../iconButton/IconButton";
import {ReactComponent as ProfileIcon} from "./resources/Profile.svg";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../../../../routing/Pages";
import {UserProfileTabs} from "../../../../../content/userProfilePage/UserProfileTabs";
import {CurrentUserInfoDecorator} from "../../../../commands/user/types/CurrentUserInfoDecorator";

interface Props {
    badge?: ReactNode;
    user: CurrentUserInfoDecorator;
}

export const UserProfileButton: FC<Props> = (props: Props) => {
    const {user} = props;
    const navigate = useNavigate();
    const handleClick = () => {

        navigate(`${Pages.USER_PROFILE}?tab=${user.hasShop ? UserProfileTabs.SHOP : UserProfileTabs.PROFILE}`);
    };
    return (
        <IconButton onClick={handleClick}>
            <ProfileIcon/>
            {user.hasShop ? "Мій Магазин" : "Мій Профіль"}
        </IconButton>
    );
};