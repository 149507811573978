import {ChangeEvent, FC, useState} from "react";
import {Col, Input, Row} from "antd";
import {useUpdateProfile} from "react-firebase-hooks/auth";
import {auth} from "../../../../../../firebase/config";
import {EditButton} from "../editButton/EditButton";
import {CurrentUserInfoDecorator} from "../../../../../../common/commands/user/types/CurrentUserInfoDecorator";
import {useCurrentUser} from "../../../../../../common/commands/user/useCurrentUser";
import {useScreenWidth} from "../../../../../../common/hooks/useScreenWidth";
import {isMobile} from "../../../../../../common/components/screen/Screen";

interface Props {
    user: CurrentUserInfoDecorator;
}

export const UserDisplayName: FC<Props> = (props: Props) => {
    const [user] = useCurrentUser();
    const [displayName, setDisplayName] = useState<string>(props.user.displayName ?? "");
    const [updateProfile] = useUpdateProfile(auth);
    const [isEditing, setEditing] = useState<boolean>(false);
    const width: number = useScreenWidth();
    const span: string = isMobile(width) ? "24" : "12";
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDisplayName(event.target.value);
    };

    const handleClick = async () => {
        if (isEditing) {
            await updateProfile({displayName});
        }
        setEditing(!isEditing);
    };

    return (
        <Row>
            <Col span={span}>
                <label className="form-label">
                    <span className="form-label__text">
                    Імʼя:
                    </span>
                    <div className="form-label__input">
                        <Input placeholder="Імʼя" value={displayName} onChange={handleChange} disabled={!isEditing}/>
                        {user?.id === props.user.id && (<EditButton onClick={handleClick} isEditing={isEditing}/>)}
                    </div>
                </label>
            </Col>
        </Row>
    );
};