import {FC} from "react";
import {CurrentUserInfo} from "../../../../../../../common/commands/user/types/CurrentUserInfo";
import {isEmpty} from "lodash-es";
import {Tag} from "antd";
import {
    CurrentUserInfoDecorator,
    UserRole
} from "../../../../../../../common/commands/user/types/CurrentUserInfoDecorator";
import "./styles/userRolesRenderer.scss";

interface Props {
    user: CurrentUserInfoDecorator;
}

export const UserRolesRenderer: FC<Props> = (props: Props) => {
    const {roles = []} = props.user.src?.customClaims ?? {};

    if (isEmpty(roles)) {
        return <Tag className="user-role-renderer">Фізична Особа</Tag>;
    }

    const getRoleName = (role: UserRole) => {
        switch (role) {
            case UserRole.ADMIN:
                return "Адміністратор";
            case UserRole.MODERATOR:
                return "Модератор";
            case UserRole.SHOP:
                return "Магазин";
        }
    };

    const [role] = roles;

    return (<Tag className={`user-role-renderer ${role}`}>{getRoleName(role)}</Tag>);
};