import {FC, useState} from "react";
import {ShopForm, ShopFormMode} from "../../../../common/components/shopForm/ShopForm";
import {Shop} from "../../../../types/integration/Shop";
import {useCurrentUser} from "../../../../common/commands/user/useCurrentUser";
import {cloneDeep} from "lodash-es";
import {UploadFile} from "antd/lib/upload/interface";
import {ImageUploadCommand} from "../../../newAdPage/commands/ImageUploadCommand";
import UpdateShopCommand from "./commands/UpdateShopCommand";
import {Spin} from "antd";
import {CurrentUserInfoDecorator} from "../../../../common/commands/user/types/CurrentUserInfoDecorator";

export const ShopProfile: FC = () => {
    const [user, setUpdatedUser] = useCurrentUser();
    const [loading, setLoading] = useState<boolean>(false);

    const handleFinish = async (values: Shop) => {
        if (!user) return;
        setLoading(true);
        try {
            if (values.info.logoUrl) {
                const files: UploadFile[] = values.info.logoUrl as unknown as UploadFile[];
                const uploadedImages: string[] = await new ImageUploadCommand(user!).execute(files);
                values.info.logoUrl = uploadedImages[0];
            } else {
                values.info.logoUrl = user.shop?.info.logoUrl;
            }
            const shop: Shop = await new UpdateShopCommand(user.shop?.id ?? "").execute(values.info);
            setUpdatedUser(new CurrentUserInfoDecorator(user.firebaseUser, user.src, shop));
        } finally {
            setLoading(false);
        }
    };

    const getInitialValues = () => {
        if (user?.shop) {
            const shop = cloneDeep(user.shop);
            const {info} = shop;
            const {logoUrl, ...rest} = info;

            shop.info = rest;

            return {shop, logoUrl};
        }
        return {};
    };

    const {shop, logoUrl} = getInitialValues();
    return (
        <Spin spinning={loading}>
            <ShopForm onFinish={handleFinish} initialValues={shop} mode={ShopFormMode.EDIT} logoUrl={logoUrl}/>
        </Spin>
    );
};