import {useEffect, useState} from "react";
import GetUnreadConversationsCount, {UnreadConversationsCount} from "../commands/GetUnreadConversationsCount";

type Handler = (count: UnreadConversationsCount) => void;

class CountObserver {
    listener: Handler[] = [];
    command: GetUnreadConversationsCount = new GetUnreadConversationsCount();

    subscribe(handler: Handler) {
        this.listener.push(handler);
    }

    unsubscribe(handler: Handler) {
        this.listener = this.listener.filter((item: Handler) => item !== handler);
    }

    refresh() {
        this.command.execute().then((count: UnreadConversationsCount) => {
            this.listener.forEach((handler: Handler) => {
                handler(count);
            })
        });
    }
}

const observer = new CountObserver();
let lastCall: number;

export function useUnreadMessagesCount(): [count: UnreadConversationsCount, refresh: () => void] {
    const [count, setCount] = useState<UnreadConversationsCount>({mine: 0, other: 0, total: 0});
    const handler = (count: UnreadConversationsCount) => {
        setCount(count);
    };

    const refresh = () => {
        observer.refresh();
    }

    useEffect(() => {
        observer.subscribe(handler);
        const now: number = Date.now();
        const diff = (now - lastCall) / 1000;

        // Refresh data on demand, but only 60 seconds after last call
        if (!lastCall || diff > 60) {
            lastCall = Date.now()
            observer.refresh();
        }

        return () => {
            observer.unsubscribe(handler);
        }
    }, []);

    return [count, refresh];
}