import FetchCommand from "../../fetch/FetchCommand";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";
import {api} from "../../fetch/api";
import {Advertisement} from "../../../content/adDetailsPage/commands/types/Advertisement";

export default class GetAdPublicInfoCommand extends FetchCommand<AdvertisementDecorator[], string[]> {
    async execute(adIds: string[]) {
        return api<Advertisement[]>(`/api/a/${adIds}/public-info`).then((result: Advertisement[]) => {
            return result.map((ad: Advertisement) =>new AdvertisementDecorator(ad));
        });
    }
}