import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import ErrorBoundary from "./common/components/errorBoundary/ErrorBoundary";
import {BrowserRouter} from "react-router-dom";
import {ErrorScreen} from "./common/components/errorScreen/ErrorScreen";
import {ConfigProvider} from "antd";
import ukUa from 'antd/es/locale/uk_UA';

export function render(Component: React.ComponentType) {
    const root = ReactDOM.createRoot(
        document.getElementById("root") as HTMLElement
    );

    root.render(
        <ConfigProvider locale={ukUa}>
            <ErrorBoundary>
                <BrowserRouter>
                    <Component/>
                </BrowserRouter>
            </ErrorBoundary>
        </ConfigProvider>
    );
}

window.onerror = () => {
    const root: HTMLElement | null = document.getElementById("root");
    if (root && root.innerHTML === "") {
        render(ErrorScreen);
    }
};

render(App);