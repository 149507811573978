import {FC} from "react";
import {IconButton} from "../../../../../../common/components/iconButton/IconButton";
import {ReactComponent as EditIcon} from "../../resources/EditIcon.svg";
import {ReactComponent as Tick} from "../../resources/Tick.svg";
import "./styles/editButton.scss";

interface Props {
    disabled?: boolean;
    isEditing: boolean;
    onClick: () => void;
}

export const EditButton: FC<Props> = (props: Props) => {
    const {onClick, disabled, isEditing = false} = props;
    return (
        <div className="core-info-edit-button">
            <IconButton onClick={onClick} disabled={disabled}>{!isEditing ? <EditIcon/> : <Tick/>}</IconButton>
        </div>
    )
};