import {FC} from "react";
import {CurrentUserInfoDecorator} from "../../../../../../../common/commands/user/types/CurrentUserInfoDecorator";
import {ReactComponent as UserActive} from "./resources/UserActive.svg";
import {ReactComponent as UserDisabled} from "./resources/UserDisabled.svg";
import "./styles/userStatusRenderer.scss";

interface Props {
    user: CurrentUserInfoDecorator;
}

export const UserStatusRenderer: FC<Props> = (props: Props) => {
    const {user} = props;
    const getIcon = () => user.isDisabled ? <UserDisabled/> : <UserActive/>;
    const getClassname = () => `user-status-renderer ${user.isDisabled ? "user-status-renderer__disabled" : "user-status-renderer__active"}`;

    return <div className={getClassname()}>{getIcon()}{user.status}</div>
};