import {FC, ReactNode, useEffect, useState} from "react";
import {Select} from "antd";
import {isEmpty} from "lodash-es";
import {addLevelsToOptions, findOptions, parseOption, SimpleTree, TreeOption, TreeValue} from "./Utils";
import "./styles/categoriesSelect.scss";

interface Props {
    options: TreeOption[];
    onChange: (value: string[]) => void;
    mode?: "single" | "multiple";
    value?: string[];
}

export const CategoriesSelect: FC<Props> = (props: Props) => {
    const {options, value = [], onChange, mode = "multiple"} = props;
    const [treeValue, setValue] = useState<SimpleTree>(new SimpleTree(SimpleTree.arrayToTree(value)));
    const updateTree = (option: TreeOption, tree: TreeValue) => {
        if (!option) {
            treeValue.deleteValue(tree.level ?? 0);
        } else {
            const {level, value} = parseOption(option as TreeOption);
            const leave: TreeValue = {value};
            if (treeValue.hasValue(level)) {
                treeValue.updateValue(level, leave);
            } else {
                treeValue.addValue(leave);
            }
        }
    };

    const handleChange = (_: string, option: TreeOption, tree: TreeValue) => {
        updateTree(option as TreeOption, tree);

        const newValue: SimpleTree = new SimpleTree(treeValue.tree);

        setValue(newValue);
        if (mode === "single") {
            if (!option) {
                onChange([]);
            } else if (!option.children) {
                onChange(SimpleTree.treeToArray(newValue.tree))
            }
        } else {
            onChange(SimpleTree.treeToArray(newValue.tree));
        }
    };

    const renderTree = (tree: TreeValue, options: TreeOption[], onChange: any): ReactNode => {
        const hasChildOptions: boolean = !isEmpty(findOptions(options, tree));
        const handleChange = (value: string, option: TreeOption | TreeOption[]) => {
            onChange(value, option, tree);
        };
        const value: string = tree.value ? `${tree.value}_${tree.level}` : "";
        return (
            <>
                <Select<string, TreeOption> value={value} options={options} onChange={handleChange}
                                            placeholder="Оберіть категорію" allowClear/>
                {hasChildOptions && renderTree(tree.child ?? {}, findOptions(options, tree), onChange)}
            </>
        );
    };

    useEffect(() => {
        if (!isEmpty(value)) {
            setValue(new SimpleTree(SimpleTree.arrayToTree(value)));
        }
    }, [value]);

    return (
        <div className="categories-select">
            {renderTree(treeValue.tree, addLevelsToOptions(options, 0), handleChange)}
        </div>
    );
};
