import {FC, ReactNode} from "react";
import {Drawer} from "antd";
import {ReactComponent as Close} from "./resources/Close.svg";
import "./styles/mobileDrawer.scss";

interface Props {
    children?: ReactNode;
    visible: boolean;
    onClose: () => void;
}

export const MobileDrawer: FC<Props> = (props: Props) => {
    const {children,visible, onClose} = props;
    return (
        <Drawer
            className="mobile-drawer"
            visible={visible}
            onClose={onClose}
            placement="bottom"
            height="auto"
            closeIcon={<Close/>}
        >
            {children}
        </Drawer>
    );
};