import {FC} from "react";
import {Col, Input, Row} from "antd";
import {CurrentUserInfoDecorator} from "../../../../../../common/commands/user/types/CurrentUserInfoDecorator";
import {useScreenWidth} from "../../../../../../common/hooks/useScreenWidth";
import {isMobile} from "../../../../../../common/components/screen/Screen";

interface Props {
    user: CurrentUserInfoDecorator;
}

export const UserEmail: FC<Props> = (props: Props) => {
    const {user} = props;
    const width: number = useScreenWidth();
    const span: string = isMobile(width) ? "24" : "12";
    return (
        <Row>
            <Col span={span}>
                <label className="form-label">
                    <span className="form-label__text">
                    Email:
                    </span>
                    <div className="form-label__input">
                        <Input placeholder="Email" type="email" value={user.email ?? ""} disabled={true}/>
                    </div>
                </label>
            </Col>
        </Row>
    )
}