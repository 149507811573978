import Command from "./Command";
import {notification} from "antd";
import Notification, {NotificationProps} from "../components/notification/Notification";

export default abstract class FetchCommand<RESULT, PAYLOAD> implements Command<RESULT, PAYLOAD> {
    public abstract execute(payload?: PAYLOAD): Promise<RESULT>;

    showError(props: NotificationProps) {
        const key: string = `notification_${Date.now()}`;
        const {
            message = "помилка",
            duration = 0,
            text = "помилка",
            onRetry
        } = props;

        const handleRetry = () => {
            if (onRetry) {
                notification.close(key);
                onRetry();
            }
        };

        notification.error({
            key,
            message,
            description: (<Notification onRetry={onRetry ? handleRetry : undefined} text={text}/>),
            duration
        });
    }
}
