import {FC} from "react";
import {Button, ButtonType} from "../../../../../common/components/button/Button";
import {Card, Upload} from "antd";
import {dummyRequest} from "../../../../../common/utils/UploadUtils";
import DownloadCatalogCommand from "../../commands/DownloadCatalogCommand";
import {RcFile} from "antd/lib/upload/interface";
import UploadCatalogCommand from "../../commands/UploadCatalogCommand";
import {ReactComponent as CatalogIcon} from "./resources/Catalog.svg";
import {ReactComponent as UploadIcon} from "./resources/Upload.svg";
import {useCatalog} from "../../../../../common/commands/catalog/useCatalog";
import {CatalogDecorator} from "../../../../../common/commands/catalog/CatalogDecorator";
import "./styles/catalog.scss";

export const Catalog: FC = () => {
    const [catalog, , setCatalog] = useCatalog();
    const handleDownloadCategoriesCommand = async () => {
        await new DownloadCatalogCommand().execute();
    };

    const handleUpload = (file: RcFile) => {
        const reader = new FileReader();
        reader.onload = async (e: ProgressEvent<FileReader>) => {
            if (e?.target?.result) {
                const result: CatalogDecorator | null = await new UploadCatalogCommand().execute(e.target.result as string);
                if (result) {
                    setCatalog(result);
                }
            }
        };
        reader.readAsText(file);
        return false;
    };

    if (!catalog) return null;

    return (
        <Card title="Увага!" className="catalogue-upload">
            <p>
                Завантаження нової структури каталогу може призвести до недоступності наявних обʼяв у новій структурі
                каталогу та в пошуку. Уважно перевірте новий файл каталогу перед тим, як завантажувати.
            </p>
            <div className="catalogue-upload__controls">
                <Button type={ButtonType.LINK} icon={<CatalogIcon/>} onClick={handleDownloadCategoriesCommand}>
                    {catalog.getFileName()}
                </Button>
                <Upload
                    action="/" customRequest={dummyRequest}
                    beforeUpload={handleUpload}
                    accept="application/json"
                    showUploadList={false}
                >
                    <Button type={ButtonType.DANGER} icon={<UploadIcon/>}>Завантажити Нову Структуру Каталогу</Button>
                </Upload>
            </div>
        </Card>
    );
};