import {Message} from "../integration/Conversation";
import moment from "moment";

const DATE_FORMAT_WITH_TIME: string = "DD.MM.yyyy H:mm";

export class MessageDecorator {
    src: Message;

    constructor(src: Message) {
        this.src = src;
    }

    get message(): string {
        return this.src.message;
    }

    get userId() {
        return this.src.userId;
    }

    get createdOn() {
        return moment(this.src.createdOn).format(DATE_FORMAT_WITH_TIME);
    }
}