import React from "react";
import {Link} from "react-router-dom";
import {Pages} from "../../../routing/Pages";
import {StaticContent} from "../../../common/components/staticContentPage/StaticContent";

export const LegalAspects = () => {
    return (
        <StaticContent title="Приватність та правова інформація">
            <ul>
                <li><Link to={Pages.PUBLIC_CONTRACT} key={Pages.PUBLIC_CONTRACT}>Публічний договір ОФЕРТА</Link></li>
                <li><Link to={Pages.PRIVACY} key={Pages.PRIVACY}>Політика конфіденційності</Link></li>
                <li><Link to={Pages.COOKIES} key={Pages.COOKIES}>Політика використання cookies</Link>
                </li>
                <li><Link to={Pages.DELETE_PERSONAL_DATA} key={Pages.DELETE_PERSONAL_DATA}>Як видалити персональні дані</Link>
                </li>
                <li><Link to={Pages.PAYMENT_INFO} key={Pages.PAYMENT_INFO}>Оплата послуг</Link></li>
            </ul>
        </StaticContent>
    );
}