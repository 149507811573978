import {Component, ReactNode} from "react";
import {ErrorScreen} from "../errorScreen/ErrorScreen";

interface State {
    hasError: boolean;
}

interface Props {
    children: ReactNode;
}

export default class ErrorBoundary extends Component<Props, State> {
    state: State = {
        hasError: false
    }

    componentDidCatch() {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return (<ErrorScreen/>);
        }
        return this.props.children;
    }
}