import {Card} from "antd";
import {FC} from "react";
import {UserDisplayName} from "./userDisplayName/UserDisplayName";
import {UserEmail} from "./userEmail/UserEmail";
import {PhoneNumber} from "./phoneNumber/PhoneNumber";
import "./styles/coreAttributes.scss";
import {CurrentUserInfoDecorator} from "../../../../../common/commands/user/types/CurrentUserInfoDecorator";

interface Props {
    user: CurrentUserInfoDecorator;
}

export const CoreAttributes: FC<Props> = (props: Props) => {
    const {user} = props;
    if (!user) {
        return null;
    }
    return (
        <Card bordered={false} className="user-core-attributes">
            <UserDisplayName user={user}/>
            <UserEmail user={user}/>
            <PhoneNumber user={user}/>
        </Card>
    );
};