export enum UserStatus {
    DISABLED = "DISABLED",
    DELETED = "DELETED"
}

export interface CurrentUserInfo {
    id: string;
    email: string;
    emailVerified: boolean;
    phoneNumber: string;
    disabled: boolean;
    customClaims: Record<string, any>;
    lastSignInTime: number;
    favoriteAds?: string[];
    displayName?: string;
    status?: UserStatus;
    location?: string;
    creationTime?: string;
    deletedOn?: string;
}