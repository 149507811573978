import {Catalog} from "../../common/commands/catalog/GetCatalogCommand";
import {isEmpty, isObject} from "lodash-es";
import {CascaderOption} from "./types";

export function getCategories(catalog: Catalog | null): CascaderOption[] {
    return catalog ? getMenu(catalog) : [];
}

function getMenu(catalog: Catalog): CascaderOption[] {
    const result: CascaderOption[] = [];
    for (const key in catalog) {
        let children: CascaderOption[] = [];
        if (!Array.isArray(catalog[key]) && isObject(catalog[key])) {
            children = getMenu(catalog[key] as Catalog);
            result.push(getMenuItem(key, children, catalog[key].id));
        }
    }
    return result;
}

function getMenuItem(item: string, children: CascaderOption[], id?: string): CascaderOption {
    return {
        label: item,
        value: id || item,
        children: isEmpty(children) ? undefined : children,
    }
}