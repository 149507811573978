import {FC} from "react";
import {DeleteOutlined} from "@ant-design/icons";
import "./removableImage.scss";

interface Props {
    url: string;
    onClick: (url: string) => void;
    disabled?: boolean;
}

export const RemovableImage: FC<Props> = (props: Props) => {
    const {url, onClick, disabled} = props;
    const handleClick = () => {
        onClick(url);
    }
    return (
        <div className="removableImage">
            <div className="removableImage-container">
                <img className="removableImage-container__img" src={url}/>
                {!disabled && (<DeleteOutlined className="removableImage-container__delete" title="Видалити файл"
                                               onClick={handleClick}/>)}
            </div>

        </div>
    )
}