import {FC} from "react";
import {ReactComponent as BackIcon} from "./resources/Arrow.svg";
import {useNavigate} from "react-router-dom";
import {Button, ButtonType} from "../button/Button";
import "./style/backButton.scss";

interface Props {
    onClick?: () => void;
    className?: string;
}

export const BackButton: FC<Props> = (props: Props) => {
    const {onClick, className} = props;
    const navigate = useNavigate();
    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            navigate(-1);
        }
    };

    return <Button className={`backButton ${className ?? ""}`} type={ButtonType.LINK} icon={<BackIcon/>} onClick={handleClick}>Назад</Button>
};