import {FC} from "react";
import {ReactComponent as Twitter} from "./resources/Twitter.svg";
import {ReactComponent as Facebook} from "./resources/fb.svg";
import "./styles/socialNetworks.scss";

export const SocialNetworks: FC = () => {
    return (
        <ul className="social-networks">
            <li>
                <a href="https://twitter.com/" target="_blank">
                    <Twitter/>
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/" target="_blank">
                    <Facebook/>
                </a>
            </li>
        </ul>
    )
};