import {FC} from "react";
import {ReactComponent as PublishIcon} from "./resources/PublishIcon.svg";
import UnArchiveAdCommand from "../../../../../../common/commands/ad/UnArchiveAdCommand";
import {Button, ButtonType} from "../../../../../../common/components/button/Button";

interface Props {
    adId: string;
    onRefresh?: () => void;
}

export const PublishButton: FC<Props> = (props: Props) => {
    const {adId, onRefresh} = props;
    const handleRefresh = async () => {
        new UnArchiveAdCommand().execute([adId]).then(() => {
            onRefresh?.();
        });
    }
    if (!onRefresh) {
        return null;
    }
    return (<Button type={ButtonType.LINK} icon={<PublishIcon/>} onClick={handleRefresh}>Опублікувати</Button>);
};