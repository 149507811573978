import {FC} from "react";
import {Badge, Card} from "antd";
import {ConversationDecorator} from "../../../../../types/decorators/ConversationDecorator";
import {ConversationInfoRenderer} from "../conversationInfoRenderer/ConversationInfoRenderer";
import cn from "classnames";
import {DeleteConversationButton} from "../deleteConversationButton/DeleteConversationButton";
import {useCurrentUser} from "../../../../../common/commands/user/useCurrentUser";
import "./styles/conversationItem.scss";

interface Props {
    conversation: ConversationDecorator;
    onSelect: (conversation: ConversationDecorator) => void;
    onDelete: (conversationId: string) => void;
    selected: boolean;
    authorDisplayName?: string;
}

export const ConversationItem: FC<Props> = (props: Props) => {
    const [user] = useCurrentUser();
    const {conversation, onSelect, selected, authorDisplayName, onDelete} = props;
    const handleClick = () => {
        onSelect(conversation);
    };
    const hasNewMessages: boolean = conversation.hasUnreadMessages(user?.id ?? "");
    const className: string = cn("conversation", {
        "conversation--selected": selected,
    });

    const content = () => {
        return (
            <Card onClick={handleClick} className={className}>
                <ConversationInfoRenderer conversation={conversation} authorDisplayName={authorDisplayName}/>
                <DeleteConversationButton conversation={conversation} onDelete={onDelete}/>
            </Card>
        );
    }
    if (hasNewMessages) {
        return (
            <Badge.Ribbon text="Нове" color="#FF5722">
                {content()}
            </Badge.Ribbon>
        );
    }

    return content();
}