import React, {FC, ReactNode, SyntheticEvent, useEffect, useState} from "react";
import AdvertisementDecorator, {DATE_FORMAT} from "../../../types/decorators/AdvertisementDecorator";
import {Card, Col, Row, Skeleton, Space} from "antd";
import {AdDetailsCarousel} from "./adDetailsCarousel/AdDetailsCarousel";
import {ReactComponent as LikesIcon} from "./resources/Likes.svg";
import {ReactComponent as AvatarIcon} from "./resources/Avatar.svg";
import {PhoneNumberCollapse} from "./phoneNumberCollapse/PhoneNumberCollapse";
import {SendMessage} from "./sendMessage/SendMessage";
import {isEmpty} from "lodash-es";
import {ListItem} from "./listItem/ListItem";
import {PublicUserInfo} from "../../commands/user/types/PublicUserInfo";
import {GetPublicUserInfoCommand} from "../../commands/user/GetPublicUserInfoCommand";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import moment from "moment";
import {Button, ButtonType} from "../button/Button";
import {AdToFavouritesButton} from "../adToFavouritesButton/AdToFavouritesButton";
import "./styles/adDetailsView.scss";
import {useScreenWidth} from "../../hooks/useScreenWidth";
import {isMobile} from "../screen/Screen";

interface Props {
    advertisement: AdvertisementDecorator;
    isPreview?: boolean;
    uploadedImages?: string[];
}

const AddToFavourites = (props: { onClick: (event: SyntheticEvent) => void, disabled?: boolean }) => {
    return (<Button
        type={ButtonType.LINK}
        onClick={props.onClick}
        disabled={props.disabled ?? false}
        icon={<div className="ad-details-view__likes-icon"><LikesIcon/></div>}>
        Додати в улюблене
    </Button>);
};

export const AdDetailsView: FC<Props> = (props: Props) => {
    const {advertisement, isPreview = false, uploadedImages} = props;
    const [userInfo, setUserInfo] = useState<PublicUserInfo | null>(null);
    const [user] = useCurrentUser();
    const width = useScreenWidth();

    useEffect(() => {
        const loadUserInfo = async () => {
            const authorId: string | undefined = isPreview ? user?.id : advertisement.src.authorId;
            if (authorId) {
                const info: PublicUserInfo[] = await new GetPublicUserInfoCommand().execute([authorId]);
                setUserInfo(info[0]);
            }
        }
        loadUserInfo();
    }, []);

    const isFavorite = user ? user.hasFavorite(advertisement.id) : false;
    const title: ReactNode = (<span className="ad-details-view__title">{advertisement.src.title}</span>);
    const carousel: ReactNode = (
        <Card className="ad-details-view__carousel">
            {!isEmpty(uploadedImages) && (
                <AdDetailsCarousel images={uploadedImages ?? []}/>
            )}
            {isEmpty(uploadedImages) && (
                <Space>
                    <Skeleton.Image/>
                    <Skeleton.Image/>
                </Space>
            )}

        </Card>
    );

    const characteristics: ReactNode = (
        <Card title="Характеристики товару">
            {!isEmpty(advertisement.characteristics) && (
                <ul className="ad-details-view__characteristics">
                    {Object.keys(advertisement.characteristics).map((key: string) => {
                        return (<ListItem key={key} label={key}
                                          value={advertisement.characteristics[key]}/>)
                    })}
                </ul>
            )}

            {isEmpty(advertisement.characteristics) && (
                <Skeleton paragraph={{rows: 10}}/>
            )}
        </Card>
    );

    const description: ReactNode = (
        <Card title="Опис">
            {advertisement.src.description && advertisement.src.description}
            {!advertisement.src.description && <Skeleton/>}
        </Card>
    );

    const price: ReactNode = (
        <Card>
            <div className="ad-details-view__details">
                <div className="ad-details-view__price">{advertisement.price}</div>
                <div className="ad-details-view__date">Опубліковано {advertisement.createdOn}</div>

                <div className="ad-details-view__ad-to-favourites">
                    <AdToFavouritesButton isFavorite={isFavorite} advertisement={advertisement}
                                          addButtonComponent={AddToFavourites}
                                          disabled={isPreview}
                    />
                </div>
            </div>
        </Card>
    );

    const sellerInfo: ReactNode = userInfo ?
        (
            <Card title="Продавець">
                <div className="ad-details-view__contacts">
                    <div className="ad-details-view__contact-info">
                        <div className="ad-details-view__contact-avatar">
                            {userInfo.photoURL && (
                                <img width="35px" src={userInfo.photoURL}/>
                            )}
                            {!userInfo.photoURL && (
                                <AvatarIcon/>
                            )}
                        </div>
                        <div className="ad-details-view__user-info">
                            <p className="ad-details-view__user-name">{userInfo.displayName}</p>
                            <p className="ad-details-view__user-date">{`на сайті з ${moment(userInfo.creationTime).format(DATE_FORMAT)}`}</p>
                            <div className="ad-details-view__user-location">
                                <p>{`місцезнаходження:`}</p>
                                <b>{advertisement.location}</b>
                            </div>
                            <PhoneNumberCollapse phoneNumber={advertisement.phoneNumber}/>
                        </div>
                    </div>
                    {user?.id !== advertisement.src.authorId && (
                        <SendMessage disabled={isPreview} adId={advertisement.id}/>
                    )}
                </div>
            </Card>
        )
        : null;

    return (
        <div className="ad-details-view">
            {isMobile(width) ?
                (
                    <>
                        {carousel}
                        {title}
                        {price}
                        {characteristics}
                        {description}
                        {sellerInfo}
                    </>
                )
                :
                (
                    <>
                        {title}
                        <Row>
                            <Col span={15}>
                                {carousel}
                                {characteristics}
                                {description}
                            </Col>
                            <Col span={9}>
                                {price}
                                {sellerInfo}
                            </Col>
                        </Row>
                    </>
                )
            }
        </div>

    );
};