import {CurrentUserInfoDecorator} from "../commands/user/types/CurrentUserInfoDecorator";
import {Pages} from "../../routing/Pages";
import {isMobile} from "../components/screen/Screen";


export const isMessageButtonVisible = (pathname: string, user: CurrentUserInfoDecorator | null) => {
    return pathname !== Pages.REGISTER_BUSINESS_ACCOUNT && user && user.isClient;
};

export const isProfileButtonVisible = (pathname: string) => {
    return ![Pages.REGISTER_BUSINESS_ACCOUNT, Pages.LOGIN].includes(pathname as Pages)
};

export const isCreateNewAdButtonVisible = (pathname: string, user: CurrentUserInfoDecorator | null) => {
    return ![
        Pages.LOGIN,
        Pages.NEW_AD,
        Pages.REGISTER_BUSINESS_ACCOUNT
    ].includes(location.pathname as Pages) && (!user || user.isClient);
};

export const isSearchVisible = (pathname: string, user: CurrentUserInfoDecorator | null, width: number) => {
    return !isMobile(width)
        ? (pathname === Pages.LANDING && !user?.isModerator && !user?.isAdmin)
        : (!user?.isModerator && !user?.isAdmin);
};