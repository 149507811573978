import FetchCommand from "../../../../../../common/fetch/FetchCommand";
import {api} from "../../../../../../common/fetch/api";
import {CurrentUserInfo, UserStatus} from "../../../../../../common/commands/user/types/CurrentUserInfo";
import {CurrentUserInfoDecorator, UserRole} from "../../../../../../common/commands/user/types/CurrentUserInfoDecorator";
import {isEmpty} from "lodash-es";


export default class FilterUsersCommand extends FetchCommand<CurrentUserInfoDecorator[], undefined> {
    status?: UserStatus;
    personOnly: boolean = false;
    roles?: UserRole[];

    withStatus(status: UserStatus) {
        this.status = status;
        return this;
    }

    withRoles(roles: UserRole[]) {
        this.roles = roles;
        return this;
    }

    withPersonOnly(personOnly: boolean) {
        this.personOnly = personOnly;
        return this;
    }

    async execute() {
        return api<CurrentUserInfo[]>(`/api/users/filter${this.queryString}`)
            .then((result: CurrentUserInfo[]) => result.map((item: CurrentUserInfo) => new CurrentUserInfoDecorator(undefined, item)));
    }

    get queryString() {
        let query: string = `?personOnly=${this.personOnly}`;

        if (!isEmpty(this.roles)) {
            query += `&role=${this.roles}`;
        }

        if (this.status) {
            query += `&status=${this.status}`;
        }

        return query;
    }
}