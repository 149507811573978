import {FC} from "react";
import {IconButton} from "../../../iconButton/IconButton";
import {signOut} from "firebase/auth";
import {auth} from "../../../../../firebase/config";
import {ReactComponent as LogoutIcon} from "./resources/LogOut.svg";
import {useCurrentUser} from "../../../../commands/user/useCurrentUser";
import {useNavigate} from "react-router-dom";

export const LogoutButton: FC = () => {
    const [, setCurrentUserInfo] = useCurrentUser();
    const navigate = useNavigate();
    const handleClick = async () => {
        try {
            await signOut(auth);
            setCurrentUserInfo(null);
            navigate("/");

        } catch (e) {
            console.log(e);
        }
    };
    return (
        <IconButton onClick={handleClick}>
            <LogoutIcon/>
            Вихід
        </IconButton>
    );
};