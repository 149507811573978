import {EventHandler, FC, MouseEvent, PropsWithChildren, ReactNode} from "react";
import "./styles/button.scss";

interface Props extends PropsWithChildren {
    onClick?: EventHandler<MouseEvent>;
    type?: ButtonType;
    icon?: ReactNode;
    disabled?: boolean;
    className?: string;
    iconRight?: ReactNode;
    buttonType?: "submit" | "reset" | "button";
}

export enum ButtonType {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
    OUTLINE = "OUTLINE",
    LINK_OUTLINE = "LINK_OUTLINE",
    DANGER = "DANGER",
    DANGER_OUTLINE = "DANGER_OUTLINE",
    SUCCESS = "SUCCESS",
    LINK = "LINK"
}

export const Button: FC<Props> = (props: Props) => {
    const {icon, children, onClick, type = ButtonType.PRIMARY, disabled = false, className = "", iconRight, buttonType = "button"} = props;

    return (
        <button disabled={disabled} className={`button button--${type.toLowerCase()} ${className}`} onClick={onClick} type={buttonType}>
            {icon && (<span className="button__icon">{icon}</span>)}
            {children}
            {iconRight && (<span className="button__icon-right">{iconRight}</span>)}
        </button>
    )
};