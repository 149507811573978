import {FC, useEffect, useState} from "react";
import {Button, ButtonType} from "../button/Button";
import {ReactComponent as Top} from "./resources/Top.svg";
import {useLocation} from "react-router-dom";
import "./styles/scrollToTop.scss";

export const ScrollToTop: FC = () => {
    const [isVisible, setVisible] = useState<boolean>(false);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        window.onscroll = () => {
            setVisible(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20);
        };
        return () => {
            window.onscroll = null;
        }
    }, [])

    const handleClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    if (isVisible) {
        return <Button onClick={handleClick} className="scrollToTop" type={ButtonType.PRIMARY} icon={<Top/>}/>
    }

    return null;
}