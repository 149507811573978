import FetchCommand from "../../../../common/fetch/FetchCommand";
import {PartnerPage} from "../../../../types/integration/Partners";
import {api} from "../../../../common/fetch/api";

export default class CreatePartnerCommand extends FetchCommand<PartnerPage, PartnerPage> {
    async execute(payload: PartnerPage) {
        console.log(payload);
        return api<PartnerPage>("/api/partners/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })
    }

}