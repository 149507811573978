import FetchCommand from "../../../common/fetch/FetchCommand";
import {api} from "../../../common/fetch/api";
import {Advertisement} from "../../adDetailsPage/commands/types/Advertisement";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";

export default class CreateNewAdCommand extends FetchCommand<AdvertisementDecorator, Advertisement> {
    async execute(advertisement: Record<string, any>) {
        const payload = {
            ...advertisement,
            category: advertisement.category[advertisement.category.length - 1],
            price: Number(advertisement.price)
        }
        return await api<Advertisement>(
            "/api/a",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload)
            },
        ).then((ad: Advertisement) => new AdvertisementDecorator(ad));
    }
}