import React, {FC, useEffect, useState} from "react";
import AdvertisementDecorator from "../../../types/decorators/AdvertisementDecorator";
import {ListItem} from "../../../common/components/adDetailsView/listItem/ListItem";
import "./styles/selectedAdRenderer.scss";
import {isEmpty} from "lodash-es";
import {Image, Skeleton, Space} from "antd";
import {PublicUserInfo} from "../../../common/commands/user/types/PublicUserInfo";
import {GetPublicUserInfoCommand} from "../../../common/commands/user/GetPublicUserInfoCommand";

interface Props {
    advertisement: AdvertisementDecorator;
}

export const SelectedAdRenderer: FC<Props> = (props: Props) => {
    const {advertisement} = props;
    const [userInfo, setUserInfo] = useState<PublicUserInfo | null>(null);
    useEffect(() => {
        const loadUserInfo = async () => {
            if (advertisement.src.authorId) {
                const info: PublicUserInfo[] = await new GetPublicUserInfoCommand().execute([advertisement.src.authorId]);
                setUserInfo(info[0]);
            }
        }
        loadUserInfo();
    }, []);
    return (
        <div className="selected-advertisement-renderer">
            <div className="selected-advertisement-renderer__section">
                <div className="selected-advertisement-renderer__header">
                    <span className="selected-advertisement-renderer__id">{`# ${advertisement.id}`}</span>
                    <span className="selected-advertisement-renderer__date">{advertisement.createdOn}</span>
                </div>
                <div className="selected-advertisement-renderer__title">{advertisement.title}</div>
                <ul className="selected-advertisement-renderer__characteristics">
                    {Object.keys(advertisement.characteristics).map((key: string) => {
                        return (<ListItem key={key} value={advertisement.characteristics[key]} label={key}/>);
                    })}
                    <ListItem label="Ціна" value={advertisement.price}/>
                </ul>
                <div className="selected-advertisement-renderer__description">
                    <h4>Опис</h4>
                    <p>{advertisement.src.description}</p>
                </div>
            </div>
            <div className="selected-advertisement-renderer__section">
                <div className="selected-advertisement-renderer__contacts">
                    <h3>{userInfo?.displayName}</h3>
                    <ul>
                        <ListItem label="Email" value={advertisement.email}/>
                        <ListItem label="Телефон" value={advertisement.phoneNumber}/>
                        <ListItem label="Місцезнаходження" value={advertisement.location}/>
                    </ul>
                </div>
            </div>
            <div className="selected-advertisement-renderer__section">
                {!isEmpty(advertisement.images)
                    ? (
                        <ul className="selected-advertisement-renderer__image-list">
                            {advertisement.images.map((url: string, index: number) => {
                                return (
                                    <li className="selected-advertisement-renderer__image-item" key={index}>
                                        <Image src={url} alt="image"/>
                                    </li>
                                )
                            })}
                        </ul>
                    )
                    : (
                        <Space>
                            <Skeleton.Image/>
                            <Skeleton.Image/>
                        </Space>
                    )
                }
            </div>
        </div>
    );
};